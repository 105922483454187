define("ember-template-compiler/lib/plugins/assert-against-named-blocks", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assertAgainstNamedBlocks;

  /**
   @module ember
  */

  /**
    Prevents usage of named blocks
  
    @private
    @class AssertAgainstNamedBlocks
  */
  function assertAgainstNamedBlocks(env) {
    var moduleName = env.meta.moduleName;
    return {
      name: 'assert-against-named-blocks',
      visitor: {
        ElementNode: function ElementNode(node) {
          if (node.tag[0] === ':') {
            var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
            (true && !(false) && (0, _debug.assert)("Named blocks are not currently available, attempted to use the <" + node.tag + "> named block. " + sourceInformation));
          }
        },
        MustacheStatement: function MustacheStatement(node) {
          if (node.path.type === 'PathExpression' && node.path.original === 'yield') {
            var to = node.hash.pairs.filter(function (pair) {
              return pair.key === 'to';
            })[0]; // Glimmer template compiler ensures yield must receive a string literal,
            // so we only need to check if it is not "default" or "inverse"

            if (to && to.value.type === 'StringLiteral' && to.value.original !== 'default' && to.value.original !== 'inverse') {
              var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
              (true && !(false) && (0, _debug.assert)("Named blocks are not currently available, attempted to yield to a named block other than \"default\" or \"inverse\": {{yield to=\"" + to.value.original + "\"}}. " + sourceInformation));
            }
          }
        }
      }
    };
  }
});