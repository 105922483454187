define("shared/components/modal-confirm-yaml-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rPBRz7o9",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[10,\"div\"],[14,0,\"container-header-text\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"modalConfirmYaml2Form.header\"],null]],[2,\":\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"protip\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"modalConfirmYaml2Form.helpText\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"display-name\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"mb-5\"],[12],[2,\"\\n        \"],[10,\"code\"],[12],[2,\"\\n          \"],[1,[32,1,[\"lostKey\"]]],[2,\": \"],[1,[32,1,[\"lostValue\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-error\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"confirm\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"modalConfirmYaml2Form.confirm\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-transparent\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"cancel\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"generic.cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"-track-array\",\"each\",\"action\"]}",
    "meta": {
      "moduleName": "shared/components/modal-confirm-yaml-switch/template.hbs"
    }
  });

  _exports.default = _default;
});