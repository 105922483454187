define("shared/components/top-errors/component", ["exports", "jquery", "shared/components/top-errors/template"], function (_exports, _jquery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    errors: null,
    classNames: ['banner', 'bg-error'],
    classNameBindings: ['errors.length::hide'],
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'errors', []);
    },
    errorsDidChange: Ember.on('init', Ember.observer('errors.[]', function () {
      var _this = this;

      if (Ember.get(this, 'errors.length')) {
        Ember.run.later(function () {
          try {
            (0, _jquery.default)(_this.element)[0].scrollIntoView();
          } catch (error) {}
        }, 100);
      }
    }))
  });

  _exports.default = _default;
});