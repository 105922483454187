define("shared/mixins/console", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    application: Ember.inject.controller(),
    queryParams: ['podId', 'kubernetes', 'windows', 'containerName'],
    instanceId: null,
    model: null,
    bootstrap: Ember.on('init', function () {
      if (this.get('application.isPopup')) {
        (0, _jquery.default)('body').css('overflow', 'hidden');
      }
    }),
    actions: {
      cancel: function cancel() {
        window.close();
      }
    }
  });

  _exports.default = _default;
});