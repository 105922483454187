define("shared/components/namespace-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WWSxxeSQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"tr\"],[14,0,\"group-row\"],[12],[2,\"\\n  \"],[10,\"td\"],[15,\"colspan\",[30,[36,6],[[35,0],1],null]],[14,0,\"pl-10\"],[12],[2,\"\\n\"],[6,[37,7],[[35,1,[\"displayName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],[\"namespaceGroup.label\"],[[\"name\"],[[35,1,[\"displayName\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,5],[[35,4]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"td\"],[15,\"data-title\",[31,[[34,8,[\"actions\"]]]]],[14,0,\"actions\"],[12],[2,\"\\n\"],[6,[37,7],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],null,[[\"model\"],[[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[35,1,[\"showTransitioningMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"tr\"],[14,0,\"group-row group-row-error\"],[12],[2,\"\\n    \"],[10,\"td\"],[15,\"colspan\",[31,[[34,0]]]],[15,0,[31,[\"text-small \",[34,1,[\"stateColor\"]]]]],[12],[2,\"\\n      \"],[1,[30,[36,2],[[35,1,[\"transitioningMessage\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fullColspan\",\"model\",\"uc-first\",\"action-menu\",\"noGroup\",\"t\",\"sub\",\"if\",\"dt\"]}",
    "meta": {
      "moduleName": "shared/components/namespace-group/template.hbs"
    }
  });

  _exports.default = _default;
});