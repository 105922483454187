define("shared/mixins/intl-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    attributeBindings: ['i18nPlaceholder:placeholder'],
    i18nPlaceholder: Ember.computed('placeholder', 'intl.locale', function () {
      return this.get('intl').t(this.get('placeholder'));
    })
  });

  _exports.default = _default;
});