define("global-admin/components/cluster-row/component", ["exports", "global-admin/components/cluster-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    model: null,
    tagName: ''
  });

  _exports.default = _default;
});