define("shared/components/modal-resize-pvc/component", ["exports", "shared/components/modal-resize-pvc/template", "shared/mixins/modal-base", "shared/utils/parse-unit"], function (_exports, _template, _modalBase, _parseUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['medium-modal'],
    model: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var model = Ember.get(this, 'modalOpts.model').clone();
      Ember.set(this, 'model', model);
    },
    actions: {
      save: function save(cb) {
        var _this = this;

        Ember.get(this, 'model').save().then(function () {
          _this.send('cancel');
        }).finally(function () {
          cb();
        });
      }
    },
    storage: Ember.computed('model.resources.requests.storage', {
      get: function get() {
        var capacity = Ember.get(this, 'model.resources.requests.storage');
        var bytes = (0, _parseUnit.parseSi)(capacity);
        var gib = bytes / Math.pow(1024, 3);
        return gib;
      },
      set: function set(key, value) {
        var resources = this.model.resources;
        Ember.set(resources, 'requests.storage', "".concat(value, "Gi"));
        return value;
      }
    })
  });

  _exports.default = _default;
});