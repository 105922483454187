define("ember-template-compiler/lib/plugins/transform-each-in-into-each", ["exports", "ember-template-compiler/lib/plugins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformEachInIntoEach;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
    {{#each-in iterableThing as |key value|}}
    ```
  
    with
  
    ```handlebars
    {{#each (-each-in iterableThing) as |value key|}}
    ```
  
    @private
    @class TransformHasBlockSyntax
  */
  function transformEachInIntoEach(env) {
    var b = env.syntax.builders;
    return {
      name: 'transform-each-in-into-each',
      visitor: {
        BlockStatement: function BlockStatement(node) {
          if ((0, _utils.isPath)(node.path) && node.path.original === 'each-in') {
            node.params[0] = b.sexpr(b.path('-each-in'), [node.params[0]]);
            var blockParams = node.program.blockParams;

            if (!blockParams || blockParams.length === 0) {// who uses {{#each-in}} without block params?!
            } else if (blockParams.length === 1) {
              // insert a dummy variable for the first slot
              // pick a name that won't parse so it won't shadow any real variables
              blockParams = ['( unused value )', blockParams[0]];
            } else {
              var key = blockParams.shift();
              var value = blockParams.shift();
              blockParams = [value, key].concat(blockParams);
            }

            node.program.blockParams = blockParams;
            return b.block(b.path('each'), node.params, node.hash, node.program, node.inverse, node.loc);
          }
        }
      }
    };
  }
});