define("shared/components/cluster-driver/driver-amazoneks/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-amazoneks/template", "shared/utils/amazon", "jquery", "semver"], function (_exports, _clusterDriver, _template, _amazon, _jquery, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RANCHER_GROUP = 'rancher-nodes';

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    layout: _template.default,
    configField: 'amazonElasticContainerServiceConfig',
    instanceTypes: _amazon.INSTANCE_TYPES,
    regionChoices: _amazon.EKS_REGIONS,
    step: 1,
    serviceRoles: null,
    securityGroups: null,
    whichSecurityGroup: 'default',
    defaultSecurityGroupName: RANCHER_GROUP,
    errors: null,
    serviceRoleMode: 'default',
    vpcSubnetMode: 'default',
    allSecurityGroups: null,
    allKeyPairs: null,
    selectedServiceRole: null,
    selectedGroupedDetails: null,
    kubernetesVersionContent: _amazon.EKS_VERSIONS,
    isCustomSecurityGroup: Ember.computed.equal('whichSecurityGroup', 'custom'),
    editing: Ember.computed.equal('mode', 'edit'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        clients: {},
        allSubnets: []
      });
      var config = Ember.get(this, 'cluster.amazonElasticContainerServiceConfig');

      if (!config) {
        config = Ember.get(this, 'globalStore').createRecord({
          type: 'amazonElasticContainerServiceConfig',
          accessKey: null,
          secretKey: null,
          region: 'us-west-2',
          instanceType: 'm5.large',
          desiredNodes: 1,
          minimumNodes: 1,
          maximumNodes: 1,
          kubernetesVersion: this.kubernetesVersionContent.firstObject
        });
        Ember.set(this, 'cluster.amazonElasticContainerServiceConfig', config);
      } else {
        if (this.mode === 'edit') {
          Ember.setProperties(this, {
            vpcSubnetMode: 'custom',
            serviceRoleMode: 'custom'
          });

          if (Ember.get(this, 'cluster.hasSessionToken')) {
            Ember.set(this, 'cluster.amazonElasticContainerServiceConfig.accessKey', null);
          }
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.setProperties(this, {
        step: 1,
        clients: null,
        allSubnets: null
      });
    },
    actions: {
      multiSecurityGroupSelect: function multiSecurityGroupSelect() {
        var options = Array.prototype.slice.call((0, _jquery.default)('.existing-security-groups')[0], 0);
        var selectedOptions = [];
        options.filterBy('selected', true).forEach(function (cap) {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'config.securityGroups', selectedOptions);
      },
      multiSubnetGroupSelect: function multiSubnetGroupSelect() {
        var options = Array.prototype.slice.call((0, _jquery.default)('.existing-subnet-groups')[0], 0);
        var selectedOptions = [];
        options.filterBy('selected', true).forEach(function (cap) {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'config.subnets', selectedOptions);
      },
      awsLogin: function awsLogin(cb) {
        var _this = this;

        this.listRoles(this.authCreds()).then(function (roles) {
          var eksRoles = [];
          eksRoles = roles.filter(function (role) {
            //
            var policy = JSON.parse(decodeURIComponent(Ember.get(role, 'AssumeRolePolicyDocument')));
            var statement = Ember.get(policy, 'Statement');
            var isEksRole = false;
            statement.forEach(function (doc) {
              var principal = Ember.get(doc, 'Principal');

              if (principal) {
                var _service = Ember.get(principal, 'Service');

                if (_service && (_service.includes('eks.amazonaws') || _service.includes('EKS')) && !eksRoles.findBy('RoleId', Ember.get(role, 'RoleId'))) {
                  // console.log(service.includes('eks'), service.includes('EKS'), eksRoles.findBy('RoleId', get(role, 'RoleId')), role)
                  isEksRole = true;
                } else if (Ember.get(principal, 'EKS')) {
                  // console.log(get(principal, 'EKS'), role);
                  isEksRole = true;
                } else {
                  isEksRole = false;
                }
              }
            });

            if (isEksRole) {
              return role;
            }
          });
          Ember.set(_this, 'serviceRoles', eksRoles);
          return _this.loadKeyPairs(_this.authCreds()).then(function ()
          /* keyPairs */
          {
            if (_this.mode === 'edit') {
              Ember.set(_this, 'step', 6);
            } else {
              Ember.set(_this, 'step', 2);
            }

            cb();
          }).catch(function (err) {
            Ember.get(_this, 'errors').pushObject(err);
            cb(false, err);
          });
        }).catch(function (err) {
          Ember.get(_this, 'errors').pushObject(err);
          cb(false, err);
        });
      },
      loadVPS: function loadVPS(cb) {
        var _this2 = this;

        if (Ember.get(this, 'selectedServiceRole')) {
          Ember.set(this, 'config.serviceRole', Ember.get(this, 'selectedServiceRole'));
        }

        this.loadVpcs(this.authCreds()).then(function () {
          Ember.set(_this2, 'step', 3);
          cb();
        }).catch(function (err) {
          Ember.get(_this2, 'errors').pushObject(err);
          cb(false, err);
        });
      },
      setVPCS: function setVPCS(cb) {
        var _this3 = this;

        if (Ember.get(this, 'vpcSubnetMode') === 'custom') {
          this.loadSubnets(this.authCreds()).then(function () {
            Ember.set(_this3, 'step', 4);
            cb();
          }).catch(function (err) {
            Ember.get(_this3, 'errors').pushObject(err);
            cb(false, err);
          });
        } else {
          Ember.set(this, 'step', 6);
        }
      },
      setSubnets: function setSubnets(cb) {
        var _this4 = this;

        this.loadSecurityGroups(this.authCreds()).then(function () {
          Ember.set(_this4, 'step', 5);
          cb();
        }).catch(function (err) {
          Ember.get(_this4, 'errors').pushObject(err);
          cb(false, err);
        });
      }
    },
    publicIpChanged: Ember.observer('step', 'config.associateWorkerNodePublicIp', function () {
      if (!Ember.get(this, 'config.associateWorkerNodePublicIp')) {
        Ember.set(this, 'vpcSubnetMode', 'custom');
      }
    }),
    desiredNodesChanged: Ember.observer('config.desiredNodes', function () {
      var desiredNodes = Ember.get(this, 'config.desiredNodes');
      var config = Ember.get(this, 'config');
      Ember.setProperties(config, {
        minimumNodes: desiredNodes,
        maximumNodes: desiredNodes
      });
    }),
    vpcSubnetModeDidChange: Ember.observer('step', 'vpcSubnetMode', function () {
      var mode = this.vpcSubnetMode,
          editing = this.editing,
          step = this.step;

      if (!editing && mode === 'custom') {
        var vpc = Ember.get(this, 'filteredVpcs.firstObject.id');

        if (step <= 3) {
          if (vpc) {
            Ember.set(this, 'config.virtualNetwork', vpc);
          }
        }
      }
    }),
    serviceRoleModeDidChange: Ember.observer('serviceRoleMode', function () {
      var mode = Ember.get(this, 'serviceRoleMode');

      if (mode === 'custom') {
        var role = Ember.get(this, 'serviceRoles.firstObject.RoleName');

        if (role) {
          Ember.set(this, 'selectedServiceRole', role);
        }
      } else {
        Ember.set(this, 'selectedServiceRole', null);
      }
    }),
    vpcsChanged: Ember.observer('config.virtualNetwork', 'vpcSubnetMode', function () {
      var _this5 = this;

      if (!this.primaryResource.isTransitioning) {
        var vnet = Ember.get(this, 'config.virtualNetwork');
        var subnets = Ember.get(this, 'config.subnets');
        var mode = Ember.get(this, 'vpcSubnetMode');
        var hasInitializedValues = vnet || subnets;

        if (vnet && mode === 'custom') {
          this.loadSubnets(this.authCreds()).catch(function (err) {
            Ember.get(_this5, 'errors').pushObject(err);
          }); // We check for initialized values here because as part of
          // the saving process this observer gets triggered with
          // uninitialized values. This was causing a save to switch
          // the step to step 3 rather than remaining on the last
          // page until the saving was complete.
        } else if (mode === 'default' && hasInitializedValues) {
          Ember.setProperties(Ember.get(this, 'config'), {
            virtualNetwork: null,
            subnets: []
          });
          Ember.set(this, 'step', 3);
        }
      }
    }),
    versionChoices: Ember.computed('editing', 'versions', function () {
      var initialVersion = this.config.kubernetesVersion,
          intl = this.intl,
          kubernetesVersionContent = this.kubernetesVersionContent,
          mode = this.mode;
      var versionChoices = this.versionChoiceService.parseCloudProviderVersionChoices(kubernetesVersionContent.slice(), initialVersion, mode); // only EKS and edit - user can only upgrade a single minor version at a time

      if (this.editing) {
        var initalMinorVersion = parseInt((0, _semver.minor)((0, _semver.coerce)(initialVersion)), 10);
        versionChoices.forEach(function (vc) {
          var vcMinorV = parseInt((0, _semver.minor)((0, _semver.coerce)(vc.value)), 10);
          var diff = vcMinorV - initalMinorVersion;

          if (diff > 1) {
            Ember.setProperties(vc, {
              disabled: true,
              label: "".concat(vc.label, " ").concat(intl.t('formVersions.eks.label'))
            });
          }
        });
      }

      return versionChoices;
    }),
    filteredKeyPairs: Ember.computed('allKeyPairs', function () {
      return Ember.get(this, 'allKeyPairs').sortBy('KeyName');
    }),
    filteredSubnets: Ember.computed('allSubnets', 'config.virtualNetwork', function () {
      return Ember.get(this, 'allSubnets').filterBy('VpcId', Ember.get(this, 'config.virtualNetwork')).map(function (subnet) {
        return {
          subnetName: (0, _amazon.nameFromResource)(subnet, 'SubnetId'),
          subnetId: subnet.SubnetId
        };
      }).sortBy('subnetName');
    }),
    filteredVpcs: Ember.computed('allVpcs', function () {
      return (Ember.get(this, 'allVpcs') || []).filterBy('State', 'available').map(function (vpc) {
        return {
          id: Ember.get(vpc, 'VpcId'),
          label: "".concat(Ember.get(vpc, 'VpcId'), " (").concat(Ember.get(vpc, 'CidrBlock'), ")")
        };
      }).sortBy('label');
    }),
    filteredSecurityGroups: Ember.computed('allSecurityGroups', 'config.virtualNetwork', function () {
      return Ember.get(this, 'allSecurityGroups').filterBy('VpcId', Ember.get(this, 'config.virtualNetwork')).sortBy('GroupName');
    }),
    readableServiceRole: Ember.computed('config.serviceRole', 'serviceRoles', function () {
      var roles = Ember.get(this, 'serviceRoles');
      var selectedRole = Ember.get(this, 'config.serviceRole');
      var match = roles.findBy('RoleName', selectedRole);
      return match && match.RoleName ? Ember.get(match, 'RoleName') : this.intl.t('nodeDriver.amazoneks.role.noneSelected');
    }),
    canSaveVPC: Ember.computed('vpcSubnetMode', 'selectedGroupedDetails', 'config.virtualNetwork', 'config.subnets.[]', function () {
      var mode = Ember.get(this, 'vpcSubnetMode');
      var config = Ember.get(this, 'config');
      var disabled = true;

      if (mode === 'default' || Ember.get(config, 'virtualNetwork')) {
        disabled = false;
      }

      return disabled;
    }),
    canSaveSG: Ember.computed('config.securityGroups.[]', function () {
      var sg = Ember.get(this, 'config.securityGroups');
      var disabled = true;

      if (sg && sg.length > 0) {
        disabled = false;
      }

      return disabled;
    }),
    authCreds: function authCreds() {
      var config = Ember.get(this, 'config');
      var accessKey = config.accessKey,
          secretKey = config.secretKey,
          region = config.region,
          sessionToken = config.sessionToken;
      accessKey = (accessKey || '').trim();
      secretKey = (secretKey || '').trim();
      Ember.setProperties(this, {
        'errors': [],
        'config.accessKey': accessKey,
        'config.secretKey': secretKey
      });
      var auth = {
        region: region,
        accessKeyId: accessKey,
        secretAccessKey: secretKey
      };

      if (sessionToken) {
        var token = sessionToken.trim();
        Ember.set(auth, 'sessionToken', token);
        Ember.set(this, 'config.sessionToken', token);
      }

      return auth;
    },
    loadVpcs: function loadVpcs(auth) {
      var _this6 = this;

      return this.listVPCs(auth).then(function (resp) {
        var vpcs = resp.vpcs;
        var def = vpcs.findBy('IsDefault');

        if (def && def.VpcId) {
          Ember.set(_this6, 'config.virtualNetwork', Ember.get(def, 'VpcId'));
        }

        return Ember.RSVP.resolve(Ember.set(_this6, 'allVpcs', vpcs));
      });
    },
    loadSubnets: function loadSubnets(auth) {
      var _this7 = this;

      return this.listSubnets(auth).then(function (resp) {
        return Ember.RSVP.resolve(Ember.set(_this7, 'allSubnets', resp));
      });
    },
    loadSecurityGroups: function loadSecurityGroups(auth) {
      var _this8 = this;

      return this.listSecurityGroups(auth).then(function (resp) {
        return Ember.RSVP.resolve(Ember.set(_this8, 'allSecurityGroups', resp));
      });
    },
    loadKeyPairs: function loadKeyPairs(auth) {
      var _this9 = this;

      return this.listKeyPairs(auth).then(function (resp) {
        return Ember.RSVP.resolve(Ember.set(_this9, 'allKeyPairs', resp));
      });
    },
    listKeyPairs: function listKeyPairs(auth) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var ec2 = new AWS.EC2(auth);
        ec2.describeKeyPairs({}, function (err, data) {
          if (err) {
            console.log(err, err.stack);
            reject(err);
          }

          resolve(data.KeyPairs);
        });
      });
    },
    listRoles: function listRoles(auth) {
      // TODO There is no IAM endpoint in cn-northwest-1 region. We need to use cn-north-1 for now. So users chould be able to create EKS cluster in cn-northwest-1.
      var _ref = auth || {},
          region = _ref.region;

      if (region === 'cn-northwest-1') {
        auth.region = 'cn-north-1';
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var IAM = new AWS.IAM(auth);
        IAM.listRoles({}, function (err, data) {
          if (err) {
            console.log(err, err.stack);
            reject(err);
          }

          resolve(data.Roles);
        });
      });
    },
    listVPCs: function listVPCs(auth) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var ec2 = new AWS.EC2(auth);
        var vpcNames = {};
        var vpcTags = {};
        ec2.describeVpcs({}, function (err, vpcs) {
          if (err) {
            return reject(err);
          }

          vpcs.Vpcs.forEach(function (vpc) {
            vpcNames[vpc.VpcId] = (0, _amazon.nameFromResource)(vpc, 'VpcId');
            vpcTags[vpc.VpcId] = (0, _amazon.tagsFromResource)(vpc);
          });
          return resolve({
            vpcNames: vpcNames,
            vpcTags: vpcTags,
            vpcs: vpcs.Vpcs
          });
        });
      });
    },
    listSubnets: function listSubnets(auth) {
      var _this10 = this;

      var ec2 = new AWS.EC2(auth);
      var rName = Ember.get(this, 'config.region');
      var subnets = [];
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ec2.describeSubnets({}, function (err, data) {
          if (err) {
            reject(err);
          }

          Ember.set(_this10, "clients.".concat(rName), ec2);
          subnets = data.Subnets;
          resolve(subnets);
        });
      });
    },
    listSecurityGroups: function listSecurityGroups(auth) {
      var ec2 = new AWS.EC2(auth);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        ec2.describeSecurityGroups({}, function (err, data) {
          if (err) {
            reject(err);
          }

          resolve(data.SecurityGroups);
        });
      });
    },
    willSave: function willSave() {
      // temporary measure put in place for rancher/rancher#24652
      var subnets = this.config.subnets;

      if (Ember.isEmpty(subnets)) {
        Ember.set(this, 'config.subnets', []);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});