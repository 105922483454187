define("@rancher/ember-api-store/mixins/serializable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Serializable = Ember.Mixin.create({
    serialize: function serialize(depth) {
      depth = depth || 0;
      var output;

      if (depth > 20) {
        return null;
      }

      if (Ember.isArray(this)) {
        output = this.map(function (item) {
          return recurse(item, depth + 1);
        });
      } else {
        output = {};
        this.eachKeys(function (v, k) {
          output[k] = recurse(v, depth + 1);
        });
      }

      return output;

      function recurse(obj, depth) {
        depth = depth || 0;

        if (depth > 20) {
          return null;
        }

        if (Ember.isArray(obj)) {
          return obj.map(function (item) {
            return recurse(item, depth + 1);
          });
        } else if (Serializable.detect(obj)) {
          return obj.serialize(depth);
        } else if (obj && _typeof(obj) === 'object') {
          var out = {};
          var keys = Object.keys(obj);
          keys.forEach(function (k) {
            out[k] = recurse(obj[k], depth + 1);
          });
          return out;
        } else {
          return obj;
        }
      }
    },
    // Properties to ignore because they're built-in to ember, ember-debug, or the store
    concatenatedProperties: ['reservedKeys'],
    reservedKeys: ['reservedKeys', 'constructor', 'container', 'store', 'isInstance', 'isDestroyed', 'isDestroying', 'concatenatedProperties', 'cache', 'factoryCache', 'validationCache', 'store'],
    allKeys: function allKeys() {
      var _this = this;

      var reserved = this.get('reservedKeys');
      var out = Object.keys(this).filter(function (k) {
        return k.charAt(0) !== '_' && reserved.indexOf(k) === -1 && Ember.typeOf(Ember.get(_this, k)) !== 'function';
      });
      return out;
    },
    eachKeys: function eachKeys(fn) {
      var self = this;
      this.allKeys().forEach(function (k) {
        fn.call(self, self.get(k), k);
      });
    }
  });
  var _default = Serializable;
  _exports.default = _default;
});