define("shared/mixins/safe-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    safeStyle: null,
    _safeStyle: Ember.computed('safeStyle', function () {
      if (this.get('safeStyle')) {
        return this.get('safeStyle').htmlSafe();
      } else {
        return ''.htmlSafe();
      }
    }),
    attributeBindings: ['_safeStyle:style']
  });

  _exports.default = _default;
});