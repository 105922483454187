define("shared/components/schema/input-dns-record/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3C0utcuZ",
    "block": "{\"symbols\":[\"list\",\"group\",\"svc\"],\"statements\":[[6,[37,14],[[35,13]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"select\"],[15,0,[31,[[34,6]]]],[15,\"onchange\",[30,[36,8],[[32,0],[30,[36,7],[[35,1]],null]],[[\"value\"],[\"target.value\"]]]],[15,\"disabled\",[34,9]],[12],[2,\"\\n    \"],[10,\"option\"],[15,\"selected\",[30,[36,3],[[35,1],null],null]],[12],[1,[30,[36,10],[\"schema.inputDnsRecord.prompt\"],null]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,12],[[35,11]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"optgroup\"],[15,\"label\",[32,2]],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"option\"],[15,\"selected\",[30,[36,3],[[35,1],[32,3,[\"id\"]]],null]],[15,2,[32,3,[\"id\"]]],[12],[1,[32,3,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[6,[37,2],null,[[\"route\",\"model\"],[\"authenticated.project.dns.detail\",[35,1]]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"readableService\",\"selected\",\"link-to\",\"eq\",\"-track-array\",\"each\",\"selectClass\",\"mut\",\"action\",\"disabled\",\"t\",\"grouped\",\"-each-in\",\"editing\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/schema/input-dns-record/template.hbs"
    }
  });

  _exports.default = _default;
});