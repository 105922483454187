define("ember-template-compiler/lib/plugins/transform-each-track-array", ["exports", "ember-template-compiler/lib/plugins/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformEachTrackArray;

  /**
   @module ember
  */

  /**
    A Glimmer2 AST transformation that replaces all instances of
  
    ```handlebars
    {{#each iterableThing as |key value|}}
    ```
  
    with
  
    ```handlebars
    {{#each (-track-array iterableThing) as |key value|}}
    ```
  
    @private
    @class TransformHasBlockSyntax
  */
  function transformEachTrackArray(env) {
    var b = env.syntax.builders;
    return {
      name: 'transform-each-track-array',
      visitor: {
        BlockStatement: function BlockStatement(node) {
          if ((0, _utils.isPath)(node.path) && node.path.original === 'each') {
            var firstParam = node.params[0];

            if (firstParam.type === 'SubExpression' && firstParam.path.type === 'PathExpression' && firstParam.path.original === '-each-in') {
              return;
            }

            node.params[0] = b.sexpr(b.path('-track-array'), [node.params[0]]);
            return b.block(b.path('each'), node.params, node.hash, node.program, node.inverse, node.loc);
          }
        }
      }
    };
  }
});