define("global-admin/settings/registration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ctO7KsIr",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"initialValue\"],[[35,1,[\"host\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"settings/settings-header\",\"model\",\"settings/host-registration\"]}",
    "meta": {
      "moduleName": "global-admin/settings/registration/template.hbs"
    }
  });

  _exports.default = _default;
});