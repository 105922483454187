define("shared/components/select-value-check/component", ["exports", "shared/components/select-value-check/template", "shared/utils/stateful-promise"], function (_exports, _template, _statefulPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    optionValues: Ember.computed.mapBy('asyncOptions.value', 'value'),
    asyncOptions: Ember.computed('content', 'options', function () {
      return _statefulPromise.default.wrap(Ember.get(this, 'options'), []);
    }),
    valueArray: Ember.computed('values', function () {
      var values = Ember.get(this, 'values');
      return Array.isArray(values) ? values : [values];
    }),
    missingValues: Ember.computed('valueArray', 'optionValues', function () {
      var optionValues = Ember.get(this, 'optionValues');

      if (optionValues.length === 0) {
        return [];
      }

      return Ember.get(this, 'valueArray').filter(function (value) {
        return optionValues.indexOf(value) === -1;
      });
    }),
    showMessaging: Ember.computed('values', 'missingValues.length', function () {
      return Ember.get(this, 'values') && Ember.get(this, 'missingValues.length') > 0;
    })
  });

  _exports.default = _default;
});