define("shared/components/form-contextual-select-array/component", ["exports", "shared/components/form-contextual-select-array/template", "shared/utils/stateful-promise"], function (_exports, _template, _statefulPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    content: [],
    values: [],
    addButtonClass: 'btn bg-link icon-btn mt-10',
    contentFilter: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, 'content')) {
        Ember.set(this, 'content', []);
      }

      if (!Ember.get(this, 'values')) {
        Ember.set(this, 'values', []);
      }
    },
    actions: {
      onAdd: function onAdd() {
        var values = Ember.get(this, 'values'); // We push a null values and replace it so that we can get the filteredContent
        // with the newly selected valu visible to the provider of the contetFilter method.

        values.pushObject(null);
        values.replace(-1, 1, [Ember.get(this, 'filteredContent.firstObject.value')]);
      },
      onRemove: function onRemove(index) {
        Ember.get(this, 'values').removeAt(index);
      }
    },
    lastValue: Ember.computed('values', 'values.[]', {
      get: function get() {
        return Ember.get(this, 'values').objectAt(Ember.get(this, 'values.length') - 1);
      },
      set: function set(key, value) {
        Ember.get(this, 'values').set(Ember.get(this, 'values.length') - 1, value);
        return value;
      }
    }),
    canAddMore: Ember.computed('filteredContent.length', 'values.length', function () {
      return Ember.get(this, 'filteredContent.length') > 1 || Ember.get(this, 'filteredContent.length') > 0 && Ember.get(this, 'values.length') === 0;
    }),
    lastIndex: Ember.computed('values.[]', function () {
      return Ember.get(this, 'values.length') - 1;
    }),
    asyncContent: Ember.computed('content', function () {
      return _statefulPromise.default.wrap(this.content, []);
    }),
    selections: Ember.computed('values.[]', 'asyncContent.value', function () {
      var _this = this;

      return this.values.slice(0, -1).map(function (value) {
        var option = Ember.get(_this, 'asyncContent.value').find(function (v) {
          return v.value === value;
        });
        return option ? option.label : '';
      });
    }),
    filteredContent: Ember.computed('asyncContent.value', 'contentFilter', 'values.[]', function () {
      if (!Ember.get(this, 'contentFilter')) {
        return Ember.get(this, 'asyncContent.value') || [];
      }

      return this.contentFilter(Ember.get(this, 'asyncContent.value'), Ember.get(this, 'values').slice(0, -1)) || [];
    })
  });

  _exports.default = _default;
});