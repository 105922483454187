define("global-admin/security/roles/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['id', 'context'],
    id: null,
    context: null
  });

  _exports.default = _default;
});