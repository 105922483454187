define("shared/components/project-member-row/component", ["exports", "ui/utils/errors", "shared/components/project-member-row/template", "ui/utils/constants", "identicon.js"], function (_exports, _errors, _template, _constants, _identicon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var BASIC_ROLES = [{
    label: 'Owner',
    value: 'owner',
    typePrefix: true
  }, {
    label: 'Member',
    value: 'member',
    typePrefix: true
  }, {
    label: 'Read Only',
    value: 'read-only',
    scope: 'project'
  }, {
    label: 'Custom',
    value: 'custom',
    virtual: true
  }];

  var _default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    tagName: 'TR',
    classNames: 'main-row',
    member: null,
    roles: null,
    type: null,
    pageType: null,
    editing: false,
    // customRoles:       null,
    _customRole: null,
    errors: null,
    principal: null,
    external: null,
    noUpdate: false,
    isCreatorMember: false,
    principalId: null,
    principalGravatarSrc: null,
    role: Ember.computed.alias('member.role'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var member = Ember.get(this, 'member');

      if (Ember.get(this, 'member.bindings.length') === 1) {
        Ember.set(this, 'roleTemplateId', Ember.get(this, 'member.bindings.firstObject'));
      }

      if (member) {
        if (Ember.get(member, 'bindings.length') !== 0) {
          // new
          Ember.set(this, 'noUpdate', true);
        }

        if (Ember.get(member, 'principalId')) {
          Ember.get(this, 'globalStore').rawRequest({
            url: "principals/".concat(encodeURIComponent(Ember.get(member, 'principalId'))),
            method: 'GET'
          }).then(function (xhr) {
            if (xhr.status === 204) {
              return;
            }

            if (xhr.body && _typeof(xhr.body) === 'object') {
              var nuePrincipal = _this.globalStore.createRecord(xhr.body);

              Ember.set(_this, 'principal', nuePrincipal);

              _this.principalChanged();
            }

            return xhr;
          }).catch(function (xhr) {
            if (Ember.get(member, 'principalId')) {
              Ember.set(_this, 'principalId', Ember.get(member, 'principalId'));
              Ember.set(_this, 'principalGravatarSrc', "data:image/png;base64,".concat(new _identicon.default(AWS.util.crypto.md5(Ember.get(member, 'principalId') || 'Unknown', 'hex'), 80, 0.01).toString()));
            }

            return xhr;
          });
        }

        this.principalChanged();
      }
    },
    actions: {
      gotError: function gotError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      },
      addAuthorized: function addAuthorized(principal) {
        Ember.set(this, 'principal', principal);
      },
      onSelect: function onSelect(selected) {
        var _this2 = this;

        if (selected.value === 'custom') {
          Ember.run.next(function () {
            _this2.openModal(false);
          });
        }
      },
      remove: function remove() {
        this.remove(this.member);
      }
    },
    principalChanged: Ember.observer('principal', 'member', function () {
      var principal = Ember.get(this, 'principal');

      if (principal) {
        Ember.set(this, 'member.principalId', Ember.get(principal, 'id'));
        Ember.set(this, 'member.memberType', Ember.get(principal, 'principalType'));
      }
    }),
    roleTemplate: Ember.computed('roleTemplateId', 'roles.[]', function () {
      return (Ember.get(this, 'roles') || []).findBy('id', Ember.get(this, 'roleTemplateId'));
    }),
    roleTemplateId: Ember.computed('member.bindings.firstObject', 'member.isCustom', {
      get: function get() {
        return Ember.get(this, 'member.isCustom') ? 'custom' : Ember.get(this, 'member.bindings.firstObject');
      },
      set: function set(key, value) {
        if (value === 'custom') {
          Ember.set(this, '_roleTemplateId', Ember.get(this, 'roleTemplateId'));
        } else {
          Ember.set(this, 'member.bindings', [value]);
        }

        return value;
      }
    }),
    builtInRoles: Ember.computed('roles.[]', function () {
      return Ember.get(this, 'roles').filter(function (r) {
        return (r.builtin || r.external) && r.id !== 'read-only';
      });
    }),
    customRoles: Ember.computed('roles.[]', function () {
      return Ember.get(this, 'roles').filter(function (r) {
        return !r.builtin && !r.external && !r.hidden;
      });
    }),
    choices: Ember.computed('customRoles', 'pageType', 'roleTemplateId', 'roles.[]', function () {
      var _this3 = this;

      var pt = Ember.get(this, 'pageType');
      var allRoles = Ember.get(this, 'globalStore').all('roleTemplate');
      var neuRoles = BASIC_ROLES.map(function (r) {
        var id = r.typePrefix ? "".concat(pt, "-").concat(r.value) : r.value;
        var rt = allRoles.findBy('id', id);

        if (r.scope && r.scope !== pt) {
          return;
        } // If it's a real entry (i.e. not "custom")


        if (!r.virtual) {
          // And there's no corresponding role, or there is one but it's locked
          if (!rt || Ember.get(rt, 'locked') === true) {
            // And it's not the currently selected role
            if (id !== Ember.get(_this3, 'roleTemplateId')) {
              // Hide this entry (return nothing instead of a row)
              return;
            }
          }
        }

        return {
          label: r.label,
          value: id
        };
      }).filter(function (x) {
        return !!x;
      });

      if (pt) {
        var customRoles = Ember.get(this, 'customRoles').map(function (r) {
          if (r.id === 'read-only') {
            return;
          } else {
            return {
              label: r.name,
              value: r.id
            };
          }
        });
        neuRoles = neuRoles.concat(customRoles);
        return neuRoles.filter(function (x) {
          return !!x;
        });
      }

      return neuRoles;
    }),
    modalCanceled: function modalCanceled() {},
    remove: function remove()
    /* member */
    {// remove is not required as the noUpdate case will not allow you to remove a member
    },
    doneAdding: function doneAdding(customs) {
      if (customs.length === 1) {
        // more then one? of course its custom
        var match = customs[0];

        if (_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(match)) {
          Ember.setProperties(this, {
            'member.isCustom': false,
            roleTemplateId: match
          });
        } else {
          Ember.setProperties(this, {
            'member.isCustom': true,
            roleTemplateId: 'custom'
          });
        }
      } else {
        Ember.setProperties(this, {
          'member.isCustom': true,
          roleTemplateId: 'custom'
        });
      }

      Ember.set(this, 'member.bindings', customs);
    },
    openModal: function openModal() {
      var isCustom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      Ember.get(this, 'modalService').toggleModal('modal-add-custom-roles', {
        current: Ember.get(this, 'member.bindings'),
        done: this.doneAdding.bind(this),
        editng: Ember.get(this, 'editing'),
        modalCanceled: this.modalCanceled.bind(this),
        model: Ember.get(this, 'member'),
        roles: Ember.get(this, 'builtInRoles'),
        type: Ember.get(this, 'pageType'),
        isCustom: isCustom
      });
    }
  });

  _exports.default = _default;
});