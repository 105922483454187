define("shared/components/schema/input-enum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zQPlehyo",
    "block": "{\"symbols\":[\"choice\"],\"statements\":[[10,\"select\"],[14,3,\"\"],[14,0,\"form-control mb-10\"],[15,\"onChange\",[30,[36,5],[[32,0],[30,[36,4],[[35,1]],null]],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n  \"],[10,\"option\"],[14,2,\"\"],[12],[1,[30,[36,0],[\"schema.inputEnum.option\"],null]],[13],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,2],[[32,1],[35,1]],null]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1],\"LoadBalancer\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"model.service.displayKind.loadBalancer\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"value\",\"eq\",\"if\",\"mut\",\"action\",\"field\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "shared/components/schema/input-enum/template.hbs"
    }
  });

  _exports.default = _default;
});