define("ember-asset-loader/services/asset-loader", ["exports", "ember-asset-loader/errors/asset-load", "ember-asset-loader/errors/bundle-load", "ember-asset-loader/loaders/js", "ember-asset-loader/loaders/css"], function (_exports, _assetLoad, _bundleLoad, _js, _css) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RETRY_LOAD_SECRET = RETRY_LOAD_SECRET;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function RETRY_LOAD_SECRET() {}
  /**
   * Merges two manifests' bundles together and returns a new manifest.
   *
   * @param {AssetManifest} input
   * @param {AssetManifest} manifest
   * @return {AssetManifest} output
   */


  function reduceManifestBundles(input, manifest) {
    // If manifest doesn't have any bundles, then no reducing to do
    if (!manifest.bundles) {
      return input;
    } // Merge the bundles together, checking for collisions


    return Object.keys(manifest.bundles).reduce(function (output, bundle) {
      Ember.assert("The bundle \"".concat(bundle, "\" already exists."), !output.bundles[bundle]);
      output.bundles[bundle] = manifest.bundles[bundle];
      return output;
    }, input);
  }
  /**
   * A Service class to load additional assets into the Ember application.
   *
   * @class AssetLoader
   */


  var _default = Ember.Service.extend({
    /**
     * Setup the caches for the service to use when loading assets.
     *
     * @override
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.__manifests = [];

      this._setupCache();

      this._initAssetLoaders();
    },

    /**
     * Adds a manifest to the service by merging its bundles with any previously
     * added manifests. Bundle collisions result in an error being thrown.
     *
     * @public
     * @method pushManifest
     * @param {AssetManifest} manifest
     * @return {Void}
     */
    pushManifest: function pushManifest(manifest) {
      this.__manifests.push(manifest);

      this.__manifest = this.__manifests.reduce(reduceManifestBundles, {
        bundles: {}
      });
    },

    /**
     * Loads a bundle by fetching all of its assets and its dependencies.
     *
     * Returns a Promise that resolve when all assets have been loaded or rejects
     * when one of the assets fails to load. Subsequent calls will return the same
     * Promise.
     *
     * @public
     * @method loadBundle
     * @param {String} name
     * @param {Boolean} retryLoad Warning: only used internally to re-initiate loads, NOT public API
     * @return {Promise} bundlePromise
     */
    loadBundle: function loadBundle(name, retryLoad) {
      var _this = this;

      var cachedPromise = this._getFromCache('bundle', name, retryLoad === RETRY_LOAD_SECRET);

      if (cachedPromise) {
        return cachedPromise;
      }

      var bundle = this._getBundle(name);

      var dependencies = bundle.dependencies || [];
      var dependencyPromises = dependencies.map(function (dependency) {
        return _this.loadBundle(dependency, retryLoad);
      });
      var assets = bundle.assets || [];
      var assetPromises = assets.map(function (asset) {
        return _this.loadAsset(asset, retryLoad);
      });
      var bundlePromise = Ember.RSVP.allSettled([].concat(_toConsumableArray(dependencyPromises), _toConsumableArray(assetPromises)));
      var bundleWithFail = bundlePromise.then(function (promises) {
        var rejects = promises.filter(function (promise) {
          return promise.state === 'rejected';
        });
        var errors = rejects.map(function (reject) {
          return reject.reason;
        });

        if (errors.length) {
          // Evict rejected promise.
          _this._getFromCache('bundle', name, true);

          throw new _bundleLoad.default(_this, name, errors);
        }

        return name;
      });
      return this._setInCache('bundle', name, bundleWithFail);
    },

    /**
     * Loads a single asset into the application. Expects a given asset to specify
     * a URI and type.
     *
     * @public
     * @method loadAsset
     * @param {Object} asset
     * @param {String} asset.uri
     * @param {String} asset.type
     * @return {Promise} assetPromise
     */
    loadAsset: function loadAsset(_ref, retryLoad) {
      var _this2 = this;

      var uri = _ref.uri,
          type = _ref.type;
      var cacheKey = "".concat(type, ":").concat(uri);

      var cachedPromise = this._getFromCache('asset', cacheKey, retryLoad === RETRY_LOAD_SECRET);

      if (cachedPromise) {
        return cachedPromise;
      }

      var loader = this._getAssetLoader(type);

      var assetPromise = loader(uri);
      var assetWithFail = assetPromise.then(function () {
        return {
          uri: uri,
          type: type
        };
      }, function (error) {
        // Evict rejected promise.
        _this2._getFromCache('asset', cacheKey, true);

        throw new _assetLoad.default(_this2, {
          uri: uri,
          type: type
        }, error);
      });
      return this._setInCache('asset', cacheKey, assetWithFail);
    },

    /**
     * Define a loader function for assets of a specified type. Any previously
     * defined loaders for that type will be overriden.
     *
     * @public
     * @param {String} type
     * @param {Funciton} loader
     * @return {Void}
     */
    defineLoader: function defineLoader(type, loader) {
      this.__assetLoaders[type] = loader;
    },

    /**
     * Gets the current, reduced manifest.
     *
     * @private
     * @method getManifest
     * @return {AssetManifest} manifest
     */
    getManifest: function getManifest() {
      var manifest = this.__manifest;
      Ember.assert('No asset manifest found. Ensure you call pushManifest before attempting to use the AssetLoader.', manifest);
      return manifest;
    },

    /**
     * Sets up the cache used to store Promise values for asset/bundle requests.
     *
     * @private
     * @return {Void}
     */
    _setupCache: function _setupCache() {
      this.__cache = {};
      this.__cache.asset = {};
      this.__cache.bundle = {};
    },

    /**
     * Gets a value from the cache according to the type and key it was stored
     * under. Optionally, evicts the cached value and returns undefined.
     *
     * @private
     * @param {String} type
     * @param {String} key
     * @param {Boolean} evict
     * @return {Any}
     */
    _getFromCache: function _getFromCache(type, key, evict) {
      if (evict) {
        this.__cache[type][key] = undefined;
        return;
      }

      return this.__cache[type][key];
    },

    /**
     * Sets a value in the cache under a type and key.
     *
     * @private
     * @param {String} type
     * @param {String} key
     * @param {Any} value
     * @return {Any}
     */
    _setInCache: function _setInCache(type, key, value) {
      return this.__cache[type][key] = value;
    },

    /**
     * Gets the info for a bundle from the reduced manifest.
     *
     * @private
     * @method _getBundle
     * @param {String} name
     * @return {Bundle} bundle
     */
    _getBundle: function _getBundle(name) {
      var manifest = this.getManifest();
      var bundles = manifest.bundles;
      Ember.assert('Asset manifest does not list any available bundles.', Object.keys(bundles).length);
      var bundle = bundles[name];
      Ember.assert("No bundle with name \"".concat(name, "\" exists in the asset manifest."), bundle);
      return bundle;
    },

    /**
     * Gets the asset loader method for a specified type.
     *
     * @private
     * @method _getAssetLoader
     * @param {String} type
     * @return {Function} loader
     */
    _getAssetLoader: function _getAssetLoader(type) {
      var loader = this.__assetLoaders[type];
      Ember.assert("No loader for assets of type \"".concat(type, "\" defined."), loader);
      return loader;
    },

    /**
     * Initializes the __assetLoaders object and defines our default loaders.
     */
    _initAssetLoaders: function _initAssetLoaders() {
      this.__assetLoaders = {};
      this.defineLoader('js', _js.default);
      this.defineLoader('css', _css.default);
    },

    /**
     * Defines loader methods for various types of assets. Each loader is stored
     * under a key corresponding to the type of asset it loads.
     *
     * @private
     * @property __assetLoaders
     * @type {Object}
     */
    __assetLoaders: undefined
  });

  _exports.default = _default;
});