define("shared/components/schema/input-storageclass/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "esWt2p55",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"content\",\"class\",\"value\",\"prompt\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\"],[[35,2],\"form-control\",[35,1],[30,[36,0],[\"schema.inputStorageClass.prompt\"],null],\"id\",\"displayName\",[30,[36,0],[\"schema.inputStorageClass.prompt\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"t\",\"selected\",\"storageClassesOptions\",\"searchable-select\"]}",
    "meta": {
      "moduleName": "shared/components/schema/input-storageclass/template.hbs"
    }
  });

  _exports.default = _default;
});