define("shared/components/input-or-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VcwZmubf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n        \"],[1,[34,3]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n        \"],[1,[34,2]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[34,0],\" text-muted\"]]],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"generic.na\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"classesForDisplay\",\"t\",\"value\",\"obfuscatedValue\",\"obfuscate\",\"if\",\"hasValue\",\"editable\"]}",
    "meta": {
      "moduleName": "shared/components/input-or-display/template.hbs"
    }
  });

  _exports.default = _default;
});