define("global-admin/settings/advanced/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3K42s49k",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"settings/danger-zone\"]}",
    "meta": {
      "moduleName": "global-admin/settings/advanced/template.hbs"
    }
  });

  _exports.default = _default;
});