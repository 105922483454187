define("global-admin/components/form-global-roles/component", ["exports", "global-admin/components/form-global-roles/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var USER = 'user';
  var ADMIN = 'admin';
  var RESTRICTED_ADMIN = 'restricted-admin';
  var BASE = 'user-base';
  var LOGIN_ACCESS_ROLES = [{
    name: USER,
    translationKey: 'formGlobalRoles.mode.user.label',
    descriptionTranslationKey: 'formGlobalRoles.mode.user.detail'
  }, {
    name: ADMIN,
    translationKey: 'formGlobalRoles.mode.admin.label',
    descriptionTranslationKey: 'formGlobalRoles.mode.admin.detail'
  }, {
    name: RESTRICTED_ADMIN,
    translationKey: 'formGlobalRoles.mode.restrictedAdmin.label',
    descriptionTranslationKey: 'formGlobalRoles.mode.restrictedAdmin.detail'
  }, {
    name: BASE,
    translationKey: 'formGlobalRoles.mode.userBase.label',
    descriptionTranslationKey: 'formGlobalRoles.mode.userBase.detail'
  }];

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    user: null,
    groupPrincipalId: null,
    mode: null,
    allRoles: null,
    _boundSave: null,
    _boundValidator: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'allRoles', this.globalStore.all('globalRole'));
      this.initRoles();
      this.initBindings();
    },
    actions: {
      toggle: function toggle(e) {
        var $target = (0, _jquery.default)(e.target);
        var $row = $target.closest('.input-group');
        var check = (0, _jquery.default)('input[type =checkbox]', $row)[0];

        if (check && e.target !== check && e.target.tagName !== 'LABEL') {
          check.click();
        }
      }
    },
    baseRoles: Ember.computed('allRoles.[]', function () {
      var allRoles = this.allRoles;
      return [ADMIN, RESTRICTED_ADMIN, USER, BASE].map(function (r) {
        return allRoles.findBy('id', r);
      }).compact();
    }),
    make: function make(role) {
      var out = this.globalStore.createRecord({
        type: 'globalRoleBinding',
        globalRoleId: Ember.get(role, 'id')
      });

      if (!Ember.isEmpty(this.user)) {
        Ember.set(out, 'userId', this.user.id);
      }

      if (!Ember.isEmpty(this.groupPrincipalId)) {
        Ember.set(out, 'groupPrincipalId', this.groupPrincipalId);
      }

      return out;
    },
    save: function save() {
      var _this = this;

      Ember.set(this, 'errors', null);
      var allRoles = [].concat(_toConsumableArray(this.baseRoles), _toConsumableArray(this.additionalRoles), _toConsumableArray(this.userRoles)); // all active non-existant roles remapped to an array of the role resources for the save

      var add = allRoles.filterBy('active').filterBy('existing', false).map(function (r) {
        return _this.make(r.role);
      }); // all inactive existing roles remapped to an array of the role resources for the remove save

      var remove = allRoles.filterBy('active', false).filterBy('existing').map(function (r) {
        return r.existing;
      });
      return Ember.RSVP.all(add.map(function (x) {
        return x.save();
      })).then(function () {
        return Ember.RSVP.all(remove.map(function (x) {
          return x.delete();
        })).then(function () {
          return true;
        }).catch(function (err) {
          if (_this.setGRError) {
            _this.setGRError(err);
          }

          return Ember.RSVP.reject(err);
        });
      }).catch(function (err) {
        if (_this.setGRError) {
          _this.setGRError(err);
        }

        return Ember.RSVP.reject(err);
      });
    },
    initBindings: function initBindings() {
      if (!Ember.isEmpty(this.setSave)) {
        Ember.set(this, '_boundSave', this.save.bind(this));
        this.setSave(this._boundSave);
      }

      if (!Ember.isEmpty(this.setGRValidator)) {
        Ember.set(this, '_boundValidator', this.confirmUserCanLogIn.bind(this));
        this.setGRValidator(this._boundValidator);
      }
    },
    initBaseRoles: function initBaseRoles() {
      Ember.set(this, 'baseRoles', this.baseRoles.map(this.roleMapper.bind(this)));
    },
    initRoles: function initRoles() {
      Ember.setProperties(this, {
        baseRoles: this.baseRoles.map(this.roleMapper.bind(this)),
        additionalRoles: this.allRoles.filterBy('isHidden', false).filterBy('builtin').filter(function (role) {
          return role.id !== 'restricted-admin';
        }).map(this.roleMapper.bind(this)),
        userRoles: this.allRoles.filterBy('isHidden', false).filterBy('builtin', false).map(this.roleMapper.bind(this))
      });

      if (this.mode === 'new') {
        this.populateDefaultRoles();
      }
    },
    roleMapper: function roleMapper(role) {
      var user = this.user,
          _this$groupGlobalRole = this.groupGlobalRoleBindings,
          groupGlobalRoleBindings = _this$groupGlobalRole === void 0 ? [] : _this$groupGlobalRole;
      var usersGlobalRoleBindings = [];
      var hasTranslation = true;

      if (!Ember.isEmpty(user)) {
        usersGlobalRoleBindings.pushObjects(user.globalRoleBindings.filterBy('groupPrincipalId', null));
      } else {
        usersGlobalRoleBindings.pushObjects(groupGlobalRoleBindings.slice());
      }

      var binding = usersGlobalRoleBindings.findBy('globalRole', role) || false;
      var translationKey = null;
      var descriptionTranslationKey = null;

      if (this.baseRoles.findBy('id', role.id)) {
        var roleMatch = LOGIN_ACCESS_ROLES.findBy('name', role.id);
        translationKey = roleMatch.translationKey;
        descriptionTranslationKey = roleMatch.descriptionTranslationKey;
      } else {
        hasTranslation = false;
      }

      return {
        role: role,
        translationKey: translationKey,
        descriptionTranslationKey: descriptionTranslationKey,
        hasTranslation: hasTranslation,
        active: !!binding,
        existing: binding
      };
    },
    confirmUserCanLogIn: function confirmUserCanLogIn() {
      var _this2 = this;

      var allRoles = [].concat(_toConsumableArray(this.baseRoles), _toConsumableArray(this.additionalRoles), _toConsumableArray(this.userRoles));
      var roles = allRoles.filterBy('active').map(function (r) {
        return r.role;
      });
      var allRolesRules = [];
      roles.forEach(function (r) {
        if (!Ember.isEmpty(r.rules)) {
          allRolesRules.pushObjects(r.rules);
        }
      });
      var matchingRules = allRolesRules.filter(function (rule) {
        if (_this2.isRuleValid(rule)) {
          return rule;
        }

        return false;
      });

      if (Ember.isEmpty(matchingRules)) {
        return false;
      }

      return true;
    },
    isRuleValid: function isRuleValid(rule) {
      if ((rule.resources || []).any(resourceValidator) && (rule.apiGroups || []).any(apiGroupValidator) && verbsValidator(rule.verbs || [])) {
        return true;
      }

      return false;

      function resourceValidator(resource) {
        var resourcesRequiredForLogin = ['*', 'preferences', 'settings', 'features']; // console.log(`resourceValidator status: `, resourcesRequiredForLogin.includes(resource), resource);

        return resourcesRequiredForLogin.includes(resource);
      }

      function apiGroupValidator(apiGroup) {
        var apiGroupsRequiredForLogin = ['*', 'management.cattle.io']; // console.log(`apiGroupsRequiredForLogin status: `, apiGroupsRequiredForLogin.includes(apiGroup), apiGroup);

        return apiGroupsRequiredForLogin.includes(apiGroup);
      }

      function verbsValidator(verbs) {
        var restrictedTarget = ['get', 'list', 'watch'];
        var verbsRequiredForLogin = ['*'].concat(restrictedTarget);

        if (Ember.isArray(verbs) && verbs.length > 1) {
          // console.log(`verbsRequiredForLogin status 1: `, restrictedTarget.every((rt) => verbs.includes(rt)), verbs);
          return restrictedTarget.every(function (rt) {
            return verbs.includes(rt);
          });
        } // console.log(`verbsRequiredForLogin status 2: `, verbsRequiredForLogin.includes(verbs), verbs);


        return verbsRequiredForLogin.includes(verbs.firstObject);
      }
    },
    populateDefaultRoles: function populateDefaultRoles() {
      [].concat(_toConsumableArray(this.baseRoles), _toConsumableArray(this.additionalRoles), _toConsumableArray(this.userRoles)).forEach(function (r) {
        if (!Ember.isEmpty(r.role)) {
          if (r.role.id === 'user' || r.role.newUserDefault) {
            Ember.set(r, 'active', true);
          }
        }
      });
    }
  });

  _exports.default = _default;
});