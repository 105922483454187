define("shared/components/check-computed-override/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wywUJQRU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[30,[36,6],[[35,4],[35,5]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"generic.enabled\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"generic.disabled\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"computedState\",\"if\",\"computedOverrideAllowed\",\"applyClusterTemplate\",\"clusterTemplateCreate\",\"or\"]}",
    "meta": {
      "moduleName": "shared/components/check-computed-override/template.hbs"
    }
  });

  _exports.default = _default;
});