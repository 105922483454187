define("ember-template-compiler/lib/plugins/assert-local-variable-shadowing-helper-invocation", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _debug, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assertLocalVariableShadowingHelperInvocation;

  function assertLocalVariableShadowingHelperInvocation(env) {
    var moduleName = env.meta.moduleName;

    var _trackLocals = (0, _utils.trackLocals)(),
        hasLocal = _trackLocals.hasLocal,
        node = _trackLocals.node;

    return {
      name: 'assert-local-variable-shadowing-helper-invocation',
      visitor: {
        Program: node,
        ElementNode: {
          keys: {
            children: node
          }
        },
        MustacheStatement: function MustacheStatement(node) {
          if ((0, _utils.isPath)(node.path) && hasArguments(node)) {
            var name = node.path.parts[0];
            var type = 'helper';
            (true && !(!isLocalVariable(node.path, hasLocal)) && (0, _debug.assert)(messageFor(name, type) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), !isLocalVariable(node.path, hasLocal)));
          }
        },
        SubExpression: function SubExpression(node) {
          if ((0, _utils.isPath)(node.path)) {
            var name = node.path.parts[0];
            var type = 'helper';
            (true && !(!isLocalVariable(node.path, hasLocal)) && (0, _debug.assert)(messageFor(name, type) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), !isLocalVariable(node.path, hasLocal)));
          }
        },
        ElementModifierStatement: function ElementModifierStatement(node) {
          if ((0, _utils.isPath)(node.path)) {
            var name = node.path.parts[0];
            var type = 'modifier';
            (true && !(!isLocalVariable(node.path, hasLocal)) && (0, _debug.assert)(messageFor(name, type) + " " + (0, _calculateLocationDisplay.default)(moduleName, node.loc), !isLocalVariable(node.path, hasLocal)));
          }
        }
      }
    };
  }

  function isLocalVariable(node, hasLocal) {
    return !node.this && node.parts.length === 1 && hasLocal(node.parts[0]);
  }

  function messageFor(name, type) {
    return "Cannot invoke the `" + name + "` " + type + " because it was shadowed by a local variable (i.e. a block param) with the same name. Please rename the local variable to resolve the conflict.";
  }

  function hasArguments(node) {
    return node.params.length > 0 || node.hash.pairs.length > 0;
  }
});