define("shared/components/searchable-select/component", ["exports", "ui/utils/constants", "shared/components/searchable-select/template", "ui/utils/util", "jquery", "shared/utils/stateful-promise"], function (_exports, _constants, _template, _util, _jquery, _statefulPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_HEIGHT = 285;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['searchable-select'],
    classNameBindings: ['class', 'showDropdownArrow', 'asyncContent.loading:loading', 'asyncContent.loaded:loaded', 'asyncContent.error:error'],
    // input
    class: null,
    value: null,
    prefix: null,
    suffix: null,
    prompt: null,
    placeholder: null,
    // If need to catch the group changes, you can pass a group prop in.
    group: null,
    content: null,
    interContent: null,
    optionLabelPath: 'label',
    optionValuePath: 'value',
    optionGroupPath: 'group',
    localizedPrompt: false,
    localizedLabel: false,
    localizedHtmlLabel: false,
    customLabel: false,
    readOnly: null,
    showOptions: false,
    allowCustom: false,
    filter: null,
    clientSideFiltering: true,
    // the current highlighted option.
    $activeTarget: null,
    maxHeight: MAX_HEIGHT,
    showDropdownArrow: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.observeContent();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var search = (0, _jquery.default)(this.element).find('.input-search');
      search.attr('autocomplete', 'off');
      search.on('click', function () {
        _this.send('show');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.off();
    },
    actions: {
      search: function search()
      /* term*/
      {// placeholder is over written by extenders if you want
      },
      selectUnGroupedItem: function selectUnGroupedItem(idx) {
        var found = Ember.get(this, 'unGroupedContent').objectAt(idx);
        this.setSelect(found);
      },
      selectGroupedItem: function selectGroupedItem(items, idx) {
        var found = items.objectAt(idx);
        this.setSelect(found);
      },
      selectPrompt: function selectPrompt() {
        Ember.set(this, 'value', null);
        this.send('hide');
      },
      mouseEnter: function mouseEnter(event) {
        (0, _jquery.default)(this.element).find('.searchable-option').removeClass('searchable-option-active');
        var $target = (0, _jquery.default)(event.target);
        $target.addClass('searchable-option-active');
        Ember.set(this, '$activeTarget', $target);
      },
      mouseLeave: function mouseLeave(event) {
        (0, _jquery.default)(event.target).removeClass('searchable-option-active');
        Ember.set(this, '$activeTarget', null);
      },
      show: function show() {
        var _this2 = this;

        if (Ember.get(this, 'showOptions') === true) {
          return;
        }

        var toBottom = (0, _jquery.default)('body').height() - (0, _jquery.default)(this.element).offset().top - 60; // eslint-disable-line

        Ember.set(this, 'maxHeight', toBottom < MAX_HEIGHT ? toBottom : MAX_HEIGHT);
        Ember.set(this, 'filter', null);
        Ember.run.next(function () {
          var checked = (0, _jquery.default)(_this2.element).find('.searchable-option .icon-check');
          var options = (0, _jquery.default)(_this2.element).find('.searchable-options');

          if (options.length && checked.length) {
            options.animate({
              scrollTop: "".concat(checked.parent().offset().top - options.offset().top, "px")
            });
          }
        });
        Ember.set(this, 'showOptions', true);
      },
      hide: function hide() {
        Ember.set(this, 'filter', Ember.get(this, 'displayLabel'));
        Ember.set(this, 'showOptions', false);
        Ember.set(this, '$activeTarget', null);
      }
    },
    observeContent: Ember.observer('asyncContent.value.[]', 'value', 'displayLabel', function () {
      var asyncContentValue = Ember.get(this, 'asyncContent.value');

      if (asyncContentValue) {
        Ember.set(this, 'interContent', asyncContentValue.slice(0));
      }

      if (Ember.get(this, 'allowCustom')) {
        Ember.set(this, 'searchLabel', 'generic.searchOrCustomInput');
        var value = Ember.get(this, 'value');
        this.insertCustomValue(value, false);
      }

      Ember.set(this, 'filter', Ember.get(this, 'displayLabel'));
    }),
    asyncContent: Ember.computed('content', function () {
      return _statefulPromise.default.wrap(Ember.get(this, 'content'), []);
    }),
    optionsMaxHeightCss: Ember.computed('maxHeight', function () {
      return Ember.String.htmlSafe("max-height: ".concat(Ember.get(this, 'maxHeight'), "px"));
    }),
    // Show option image --> unGroupedContent only
    showOptionIcon: Ember.computed('unGroupedContent.@each.imgUrl', function () {
      return Ember.get(this, 'unGroupedContent').some(function (item) {
        return !!item.imgUrl;
      });
    }),
    displayLabel: Ember.computed('interContent.[]', 'intl.locale.[]', 'localizedHtmlLabel', 'localizedLabel', 'optionLabelPath', 'optionValuePath', 'prompt', 'value', function () {
      var value = Ember.get(this, 'value');
      var vp = Ember.get(this, 'optionValuePath');
      var lp = Ember.get(this, 'optionLabelPath');
      var selectedItem = Ember.get(this, 'interContent').filterBy(vp, value).get('firstObject');

      if (selectedItem) {
        var label = Ember.get(selectedItem, lp);

        if (Ember.get(this, 'localizedLabel')) {
          label = Ember.get(this, 'intl').t(label);
        } else if (Ember.get(this, 'localizedHtmlLabel')) {
          label = value;
        }

        return label;
      }

      return null;
    }),
    filtered: Ember.computed('allowCustom', 'clientSideFiltering', 'filter', 'interContent.[]', 'optionGroupPath', 'optionLabelPath', function () {
      var _this3 = this;

      var filter = (Ember.get(this, 'filter') || '').trim();
      var options = Ember.get(this, 'interContent');

      if (Ember.get(this, 'allowCustom')) {
        this.insertCustomValue(filter, true);
      }

      if (Ember.get(this, 'clientSideFiltering')) {
        var filteredOptionsA = [];
        var filteredOptionsB = [];
        options.forEach(function (option) {
          var filterTerms = filter.split(/\s+/);
          var gp = Ember.get(_this3, 'optionGroupPath');
          var lp = Ember.get(_this3, 'optionLabelPath');
          var group = Ember.get(option, gp);
          var label = Ember.get(option, lp);
          var startsWithOneOfFilterTerm = false;
          var containsEveryFilterTerm = true;
          filterTerms.forEach(function (s) {
            s = s.toLowerCase();
            var startsWith = label.toLowerCase().startsWith(s) || group && group.toLowerCase().startsWith(s);

            if (startsWith) {
              startsWithOneOfFilterTerm = true;
            }

            var exp = (0, _util.escapeRegex)(s);
            var pattern = new RegExp(exp, 'i');
            var contains = pattern.test(label) || group && pattern.test(group);

            if (!contains) {
              containsEveryFilterTerm = false;
            }
          });

          if (startsWithOneOfFilterTerm && containsEveryFilterTerm) {
            filteredOptionsA.push(option);
            return;
          }

          if (containsEveryFilterTerm) {
            filteredOptionsB.push(option);
            return;
          }
        });
        return filteredOptionsA.concat(filteredOptionsB);
      } else {
        return options;
      }
    }),
    unGroupedContent: Ember.computed('filtered.[]', 'optionGroupPath', function () {
      var groupPath = Ember.get(this, 'optionGroupPath');
      var out = [];
      Ember.get(this, 'filtered').forEach(function (opt) {
        var key = Ember.get(opt, groupPath);

        if (!key) {
          out.push(opt);
        }
      });
      return out;
    }),
    groupedContent: Ember.computed('filtered.[]', 'optionGroupPath', function () {
      var _this4 = this;

      var groupPath = Ember.get(this, 'optionGroupPath');
      var out = [];
      Ember.get(this, 'filtered').forEach(function (opt) {
        var key = Ember.get(opt, groupPath);

        if (key) {
          var group = out.filterBy('group', key)[0];
          var groupLabel = _this4.intl.exists(key) ? _this4.intl.t(key) : key;

          if (!group) {
            group = {
              group: groupLabel,
              options: []
            };
            out.push(group);
          }

          group.options.push(opt);
        }
      });
      return out.sortBy(groupPath);
    }),
    showMessage: Ember.computed('filtered.[]', function () {
      return Ember.get(this, 'filtered.length') === 0;
    }),
    missingMessage: Ember.computed('asyncContent.value.[]', function () {
      var len = Ember.get(this, 'asyncContent.value.length');
      var out = 'searchableSelect.noOptions';

      if (len) {
        out = 'searchableSelect.noMatch';
      }

      return out;
    }),
    showOptionsChanged: Ember.on('init', Ember.observer('showOptions', function () {
      var show = Ember.get(this, 'showOptions');

      if (show) {
        this.on();
      } else {
        this.off();
      }
    })),
    allContent: function allContent() {
      var out = [];
      var grouped = Ember.get(this, 'groupedContent');
      var unGrouped = Ember.get(this, 'unGroupedContent');
      out.pushObjects(unGrouped);
      grouped.forEach(function (g) {
        return out.pushObjects(g.options);
      });
      return out;
    },
    on: function on() {
      var _this5 = this;

      (0, _jquery.default)(this.element).on('keydown.searchable-option', function (event) {
        var kc = event.keyCode; // Note: keyup event can't be prevented.

        if (!Ember.get(_this5, 'showOptions')) {
          return;
        }

        if (kc === _constants.default.KEY.UP) {
          _this5.stepThroughOptions(-1);
        }

        if (kc === _constants.default.KEY.DOWN) {
          _this5.stepThroughOptions(1);
        } // support using return key to select the current active option


        if (kc === _constants.default.KEY.CR || kc === _constants.default.KEY.LF) {
          event.preventDefault();
          var $activeTarget = Ember.get(_this5, '$activeTarget');

          if (!$activeTarget) {
            $activeTarget = _this5.$(_this5.element).find('.searchable-options > .searchable-option:first-child');
          }

          if ($activeTarget) {
            // activeTarget is prompt
            if ($activeTarget.hasClass('searchable-prompt')) {
              _this5.send('selectPrompt');
            } else {
              var idx = (0, _jquery.default)(_this5.element).find('.searchable-option').index($activeTarget);
              idx = !!Ember.get(_this5, 'prompt') ? idx - 1 : idx; // set value

              var activeOption = _this5.allContent().objectAt(idx);

              _this5.setSelect(activeOption);
            } // hide options after value has been set


            _this5.send('hide');
          }
        } // esc to hide


        if (kc === _constants.default.KEY.ESCAPE) {
          _this5.send('hide');
        }
      });
    },
    off: function off() {
      if ((0, _jquery.default)(this.element)) {
        (0, _jquery.default)(this.element).off('keydown.searchable-option');
      }
    },
    setSelect: function setSelect(item) {
      var _this6 = this;

      var gp = Ember.get(this, 'optionGroupPath');
      var vp = Ember.get(this, 'optionValuePath');
      Ember.set(this, 'value', Ember.get(item, vp));

      if (gp && Ember.get(item, gp)) {
        Ember.set(this, 'group', Ember.get(item, gp));
      }

      Ember.set(this, 'filter', Ember.get(this, 'displayLabel')); // https://stackoverflow.com/questions/39624902/new-input-placeholder-behavior-in-safari-10-no-longer-hides-on-change-via-java

      Ember.run.next(function () {
        var input = (0, _jquery.default)(_this6.element).find('.input-search');

        if (input) {
          input.focus();
          input.blur();
        }
      });

      if (this.change) {
        this.change(item);
      }

      this.send('hide');
    },
    stepThroughOptions: function stepThroughOptions(step) {
      var $activeTarget = Ember.get(this, '$activeTarget');
      var $options = (0, _jquery.default)(this.element).find('.searchable-option');
      var len = $options.length;
      var currentIdx = -1;
      var nextIdx = 0;

      if (len === 0) {
        return;
      }

      if (!$activeTarget) {
        $options.removeClass('searchable-option-active');
        $options.eq(0).addClass('searchable-option-active');
        Ember.set(this, '$activeTarget', $options.eq(0));
        return;
      }

      currentIdx = $options.index($activeTarget);

      if (currentIdx !== -1) {
        nextIdx = currentIdx + step;
      }

      if (nextIdx !== 0) {
        nextIdx = nextIdx < 0 ? len - 1 : nextIdx % len;
      }

      var $nextActiveTarget = $options.eq(nextIdx);
      Ember.set(this, '$activeTarget', $nextActiveTarget);
      $activeTarget.removeClass('searchable-option-active');
      $nextActiveTarget.addClass('searchable-option-active');
    },
    insertCustomValue: function insertCustomValue(value, isFilter) {
      var vp = Ember.get(this, 'optionValuePath');
      var lp = Ember.get(this, 'optionLabelPath');
      value = value || '';

      if (!isFilter) {
        var custom = {
          custom: true
        };
        custom[lp] = "".concat(value, " (Custom)");
        custom[vp] = value;
        Ember.get(this, 'interContent').pushObject(custom);
      } else {
        var found = Ember.get(this, 'interContent').filterBy('custom', true).get('firstObject');

        if (found) {
          Ember.set(found, lp, "".concat(value, " (Custom)"));
          Ember.set(found, vp, value);
        }
      }
    }
  });

  _exports.default = _default;
});