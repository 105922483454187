define("ember-model-validator/messages/fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    presenceMessage: 'doit être rempli(e)',
    absenceMessage: 'doit être vide',
    inclusionMessage: "n'est pas inclus(e) dans la liste",
    exclusionMessage: "n'est pas disponible",
    // Numericality
    numericalityMessage: "n'est pas un nombre",
    numericalityOnlyIntegerMessage: 'doit être un nombre entier',
    numericalityGreaterThanMessage: 'doit être supérieur à {count}',
    numericalityGreaterThanOrEqualToMessage: 'doit être supérieur ou égal à {count}',
    numericalityEqualToMessage: 'doit être égal à {count}',
    numericalityLessThanMessage: 'doit être inférieur à {count}',
    numericalityLessThanOrEqualToMessage: 'doit être inférieur ou égal à {count}',
    numericalityOddMessage: 'doit être impair',
    numericalityEvenMessage: 'doit être pair',
    mailMessage: "n'est pas une adresse email valide",
    formatMessage: "n'est pas valide",
    colorMessage: 'doit être un nombre hexadécimal valide',
    subdomainMessage: 'doit être un sous-domaine valide',
    acceptanceMessage: 'doit être accepté(e)',
    zipCodeMessage: "n'est pas un code postal valide",
    URLMessage: 'doit être une URL valide',
    wrongLengthMessage: 'ne fait pas la bonne longueur (doit comporter {count} caractères)',
    tooShortMessage: 'est trop court (au moins {count} caractères)',
    tooLongMessage: 'est trop long (pas plus de {count} caractères)',
    mustContainNumberMessage: 'doit inclure un nombre',
    mustContainSpecialMessage: "doit inclure l'un de ces caractères spéciaux: {characters}",
    mustContainLowerMessage: 'doit comporter un caractère minuscule',
    mustContainCapitalMessage: 'doit comporter un caractère majuscule',
    customValidationMessage: "n'est pas valide",
    matchMessage: 'doit correspondre avec {match}',
    dateMessage: 'is not a valid date',
    dateBeforeMessage: 'must be before {date}',
    dateAfterMessage: 'must be after {date}'
  };
  _exports.default = _default;
});