define("global-admin/security/accounts/edit-group/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        globalRoleBindings: this.globalStore.find('globalrolebinding', null, {
          filter: {
            groupPrincipalId: params.role_id
          }
        }),
        // no find all cause its a live array
        globalRoles: this.globalStore.findAll('globalrole'),
        principal: this.globalStore.find('principal', params.role_id)
      });
    }
  });

  _exports.default = _default;
});