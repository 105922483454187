define("pipeline/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('settings', {
      path: '/'
    });
    this.route('pipelines', function () {
      this.route('index', {
        path: '/'
      });
      this.route('detail', {
        path: '/:pipeline_id'
      });
      this.route('edit', {
        path: '/:pipeline_id/edit'
      });
      this.route('run', {
        path: '/:pipeline_id/run/:run_id'
      });
    });
    this.route('repositories');
  });

  _exports.default = _default;
});