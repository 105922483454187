define("global-admin/components/feature-flags/component", ["exports", "global-admin/components/feature-flags/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FEATURE_HEADERS = [{
    translationKey: 'featureFlags.table.state',
    name: 'state',
    sort: ['state'],
    width: '100px'
  }, {
    translationKey: 'featureFlags.table.restart',
    name: 'restart',
    sort: ['status.dynamic'],
    width: '90px'
  }, {
    translationKey: 'featureFlags.table.name',
    name: 'name',
    sort: ['name'],
    searchField: 'name',
    width: '300px'
  }, {
    translationKey: 'featureFlags.table.description',
    name: 'description',
    sort: ['description'],
    searchField: 'description'
  }];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    bulkActions: false,
    descending: false,
    featuresHeaders: FEATURE_HEADERS,
    model: null,
    searchText: '',
    sortBy: 'name',
    stickyHeader: false,
    filteredFeatures: Ember.computed('model.[]', function () {
      return Ember.get(this, 'model').filter(function (feature) {
        return feature.name !== 'dashboard';
      });
    })
  });

  _exports.default = _default;
});