define("shared/components/form-key-value/component", ["exports", "shared/mixins/upload", "shared/components/form-key-value/template", "jquery", "shared/utils/util"], function (_exports, _upload, _template, _jquery, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function applyLinesIntoArray(lines, ary) {
    lines.forEach(function (line) {
      line = line.trim();

      if (!line) {
        return;
      }

      var idx = line.indexOf('=');

      if (idx === -1) {
        idx = line.indexOf(': ');
      }

      var key = '';
      var val = '';

      if (idx > 0) {
        key = line.substr(0, idx).trim();
        val = line.substr(idx + 1).trim();
      } else {
        key = line.trim();
        val = '';
      }

      var existing = ary.filterBy('key', key)[0];

      if (existing) {
        Ember.set(existing, 'value', val);
      } else {
        ary.pushObject({
          key: key,
          value: val
        });
      }
    });
  }

  function removeEmptyEntries(ary) {
    var allowEmptyValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    // Clean up empty user entries
    var toRemove = [];
    ary.forEach(function (item) {
      if (!Ember.get(item, 'key') && (!Ember.get(item, 'value') || !allowEmptyValue)) {
        toRemove.push(item);
      }
    });
    ary.removeObjects(toRemove);
  }

  function isMultiline(val) {
    var lines = val.split(/\r?\n/);
    return lines.length > 1;
  }

  var _default = Ember.Component.extend(_upload.default, {
    layout: _template.default,
    // Inputs
    initialStr: null,
    initialMap: null,
    initialArray: null,
    requiredIfAny: null,
    readonlyArray: null,
    keyContent: null,
    valueContent: null,
    ary: null,
    allowEmptyValue: false,
    allowAdd: true,
    allowUpload: false,
    allowRemove: true,
    allowEditKey: true,
    addInitialEmptyRow: false,
    allowMultilineValue: true,
    base64Value: false,
    showNoneLabel: true,
    concealValue: false,
    editing: true,
    trimWhenMultiLines: true,
    kvSeparator: '=',
    separators: ['=', ': '],
    addActionLabel: 'formKeyValue.addAction',
    keyLabel: 'formKeyValue.key.label',
    valueLabel: 'formKeyValue.value.label',
    keyPlaceholder: 'formKeyValue.key.placeholder',
    valuePlaceholder: 'formKeyValue.value.placeholder',
    uploadAction: 'pastedValues',
    init: function init() {
      this._super.apply(this, arguments);

      var ary = [];
      var map = Ember.get(this, 'initialMap');
      var readonlyArray = Ember.get(this, 'readonlyArray') || [];

      if (map) {
        Object.keys(map).forEach(function (key) {
          ary.push({
            key: key,
            value: map[key],
            editable: readonlyArray.indexOf(key) === -1
          });
        });
      } else if (Ember.get(this, 'initialStr')) {
        var lines = Ember.get(this, 'initialStr').split(',');
        var required = Ember.get(this, 'requiredIfAny');
        applyLinesIntoArray(lines, ary, Ember.get(this, 'kvSeparator'));
        removeEmptyEntries(ary, Ember.get(this, 'allowEmptyValue'));

        if (required) {
          Object.keys(required).forEach(function (key) {
            var line = ary.findBy('key', key);
            line.editable = false;
          });
        }
      } else if (Ember.get(this, 'initialArray')) {
        Ember.get(this, 'initialArray').forEach(function (line) {
          ary.push({
            key: line.key,
            value: line.value
          });
        });
      }

      if (Ember.get(this, 'base64Value')) {
        ary.forEach(function (entry) {
          var decoded = AWS.util.base64.decode(entry.value).toString();

          if (typeof decoded === 'string' && !(0, _util.asciiLike)(decoded)) {
            Ember.set(entry, 'binary', true);
          } else {
            entry.value = decoded;
          }
        });
      }

      Ember.set(this, 'ary', ary);

      if (!ary.length && Ember.get(this, 'addInitialEmptyRow')) {
        this.send('add');
      }
    },
    // groupMap: null,
    actions: {
      add: function add() {
        var _this = this;

        var ary = Ember.get(this, 'ary');
        var required = Ember.get(this, 'requiredIfAny');

        if (required && !Ember.get(ary, 'length')) {
          Object.keys(required).forEach(function (k) {
            ary.pushObject({
              key: k,
              value: required[k],
              editable: false
            });
          });
        }

        ary.pushObject({
          key: '',
          value: ''
        });
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          var elem = (0, _jquery.default)(_this.element).find('INPUT.key').last();

          if (elem && !Ember.get(_this, 'keyContent')) {
            elem.focus();
          }
        });
      },
      remove: function remove(obj) {
        Ember.get(this, 'ary').removeObject(obj);
      },
      pastedValues: function pastedValues(str) {
        var ary = Ember.get(this, 'ary');
        str = str.trim();
        var lines = str.split(/\r?\n/);
        applyLinesIntoArray(lines, ary);
        removeEmptyEntries(ary, Ember.get(this, 'allowEmptyValue'));
      }
    },
    aryObserver: Ember.on('init', Ember.observer('ary.@each.{key,value}', function () {
      Ember.run.debounce(this, 'fireChanged', 100);
    })),
    fireChanged: function fireChanged() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var map = {};
      var str = '';
      var arr = [];
      Ember.get(this, 'ary').forEach(function (row) {
        var k = Ember.get(row, 'key').trim();
        var v;

        if (Ember.get(row, 'value') !== undefined && Ember.get(row, 'value') !== null) {
          v = "".concat(Ember.get(row, 'value'));

          if (isMultiline(v)) {
            if (_this2.trimWhenMultiLines) {
              v = v.trim();
            }
          } else {
            v = v.trim();
          }
        }

        if (Ember.get(_this2, 'base64Value') && !Ember.get(row, 'binary')) {
          v = AWS.util.base64.encode(v);
        }

        if (k && (v || Ember.get(_this2, 'allowEmptyValue'))) {
          map[k] = v;
          str += (str ? ', ' : '') + k + (v ? Ember.get(_this2, 'kvSeparator') + v : '');
          arr.push({
            key: k,
            value: v
          });
        }
      });
      Ember.run.next(function () {
        if (_this2.changed) {
          _this2.changed(map);
        }

        if (_this2.changedStr) {
          _this2.changedStr(str);
        }

        if (_this2.changedArray) {
          _this2.changedArray(arr);
        }
      });
    }
  });

  _exports.default = _default;
});