define("shared/utils/parse-externalid", ["exports", "shared/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseExternalId = parseExternalId;
  _exports.parseHelmExternalId = parseHelmExternalId;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  // Parses externalIds on services into
  // {
  //  kind: what kind of id this is supposed to be
  //  group: for catalog, what group it's in
  //  id: the actual external id
  function parseExternalId(externalId) {
    var CE = _constants.default.EXTERNAL_ID;
    var nameVersion;
    var out = {
      kind: null,
      group: null,
      base: null,
      id: null,
      name: null,
      version: null
    };

    if (!externalId) {
      return out;
    }

    var idx = externalId.indexOf(CE.KIND_SEPARATOR);

    if (idx >= 0) {
      // New style kind://[group:]id
      out.kind = externalId.substr(0, idx);
      var rest = externalId.substr(idx + CE.KIND_SEPARATOR.length);
      idx = rest.indexOf(CE.GROUP_SEPARATOR);
      out.id = rest;

      if (idx >= 0) {
        // With group kind://group/id
        out.group = rest.substr(0, idx);
        nameVersion = rest.substr(idx + 1);
      } else {
        // Without group kind://id
        if (out.kind === CE.KIND_CATALOG) {
          // For catalog kinds, we have a default group
          out.group = CE.CATALOG_DEFAULT_GROUP;
        }

        nameVersion = rest;
      }
    } else {
      var dashedIdx = externalId.lastIndexOf('-'); // Old style just an ID

      out.kind = CE.KIND_CATALOG;
      var group = CE.CATALOG_DEFAULT_GROUP;
      var name = externalId.substr(0, dashedIdx);
      var version = externalId.substr(dashedIdx + 1);
      nameVersion = "".concat(name).concat(CE.ID_SEPARATOR).concat(version); // defaultgroup:extid:version

      out.id = "".concat(group).concat(CE.GROUP_SEPARATOR).concat(nameVersion);
      out.group = group;
    }

    if (nameVersion) {
      idx = nameVersion.lastIndexOf(CE.ID_SEPARATOR);
      var nameBase;

      if (idx > 0) {
        out.version = nameVersion.substr(idx + 1);
        nameBase = nameVersion.substr(0, idx);
      } else {
        nameBase = nameVersion;
      }

      out.templateId = "".concat(out.group).concat(CE.GROUP_SEPARATOR).concat(nameBase);
      idx = nameBase.lastIndexOf(CE.BASE_SEPARATOR);

      if (idx > 0) {
        out.base = nameBase.substr(0, idx);
        out.name = nameBase.substr(idx + 1);
      } else {
        out.name = nameBase;
      }
    }

    return out;
  }

  function parseHelmExternalId(externalId) {
    var CE = _constants.default.EXTERNAL_ID;
    var out = {
      kind: null,
      group: null,
      base: null,
      id: null,
      name: null,
      version: null
    };

    if (!externalId) {
      return out;
    }

    var idx = externalId.indexOf(CE.KIND_SEPARATOR); // not very smart but maybe doesn't need to be?

    if (idx >= 0) {
      out.kind = externalId.substr(0, idx);
      var rest = externalId.substr(idx + CE.KIND_SEPARATOR.length + 1);
      out.id = externalId;
      rest = rest.split('&');
      rest.forEach(function (it) {
        var _it$split = it.split('='),
            _it$split2 = _slicedToArray(_it$split, 2),
            nm = _it$split2[0],
            vl = _it$split2[1];

        out[nm] = vl;
      });
    }

    var catalog = out.catalog;

    if (catalog.indexOf('/') !== -1) {
      catalog = catalog.replace('/', ':');
    } else {
      catalog = "cattle-global-data:".concat(catalog);
    }

    out.templateId = "".concat(catalog, "-").concat(out.template);
    out.templateVersionId = "".concat(catalog, "-").concat(out.template, "-").concat(out.version);
    return out;
  }
});