define("shared/components/form-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y6n4/ES2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"pb-5 acc-label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"formCount.label\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row gutless\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-2 m-0\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"value\",\"min\",\"max\"],[[35,3],[35,2],[35,1]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col offset-1 span-9 hidden-md\"],[12],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"value\",\"valueMin\",\"scaleMin\",\"valueMax\"],[[35,3],1,0,[35,5]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"max\",\"min\",\"userInput\",\"input-integer\",\"sliderMax\",\"input-slider\"]}",
    "meta": {
      "moduleName": "shared/components/form-count/template.hbs"
    }
  });

  _exports.default = _default;
});