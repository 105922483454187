define("shared/components/banner-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Njv61nni",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[30,[36,7],[[35,5],[35,3]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"banner-icon\"],[12],[2,\"\\n      \"],[10,\"span\"],[15,0,[31,[\"icon \",[34,4]]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"banner-title\"],[15,5,[34,2]],[12],[2,\"\\n      \"],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"banner-message\"],[12],[2,\"\\n\"],[6,[37,6],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,6],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"banner-close\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon icon-sm icon-close\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"message\",\"titleStyle\",\"titleStr\",\"icon\",\"showIcon\",\"if\",\"or\",\"showClose\"]}",
    "meta": {
      "moduleName": "shared/components/banner-message/template.hbs"
    }
  });

  _exports.default = _default;
});