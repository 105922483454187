define("shared/components/form-auth-cloud-credential/component", ["exports", "shared/components/form-auth-cloud-credential/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    showAddCloudCredential: null,
    region: null,
    // only used a passthrough for the amazon region so the region selection can happen in cloud creds rather than have the markup on both pages
    hideSave: false,
    cloudCredentials: null,
    driverName: null,
    primaryResource: null,
    finishAndSelectCloudCredential: null,
    progressStep: null,
    cancel: null,
    changeCloudCredential: null,
    createLabel: 'saveCancel.create',
    savingLabel: 'generic.loading',
    mode: 'new',
    cloudCredentialKey: 'cloudCredentialId',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.mode === 'new') {
        this.initSingleOrAddCredential();
      }
    },
    actions: {
      setCloudCredential: function setCloudCredential(cred) {
        if (this.changeCloudCredential) {
          // send the entire credential to match the signature of doneSaving...
          this.changeCloudCredential((this.cloudCredentials || []).find(function (cc) {
            return cc.id === cred;
          }));
        } else {
          var primaryResource = this.primaryResource,
              cloudCredentialKey = this.cloudCredentialKey;
          Ember.set(primaryResource, cloudCredentialKey, cred);
        }
      },
      doneSavingCloudCredential: function doneSavingCloudCredential(cred) {
        if (cred) {
          if (this.finishAndSelectCloudCredential) {
            this.finishAndSelectCloudCredential(cred);
          }

          Ember.set(this, 'showAddCloudCredential', false);
        }
      },
      addCloudCredential: function addCloudCredential() {
        var _this$primaryResource = this.primaryResource,
            primaryResource = _this$primaryResource === void 0 ? {} : _this$primaryResource,
            cloudCredentialKey = this.cloudCredentialKey;

        if (Ember.get(primaryResource, cloudCredentialKey)) {
          this.send('setCloudCredential', null);
        }

        Ember.set(this, 'showAddCloudCredential', true);
      },
      cancleNewCloudCredential: function cancleNewCloudCredential() {
        Ember.set(this, 'showAddCloudCredential', false);
      }
    },
    initSingleOrAddCredential: function initSingleOrAddCredential() {
      var _this = this;

      var _this$cloudCredential = this.cloudCredentials,
          cloudCredentials = _this$cloudCredential === void 0 ? [] : _this$cloudCredential,
          primaryResource = this.primaryResource,
          cloudCredentialKey = this.cloudCredentialKey;
      var singleCloudCredentialId = Ember.get(cloudCredentials || [], 'firstObject.id') || null;
      var cloudCredentialValue = Ember.get(primaryResource || {}, cloudCredentialKey);

      if (Ember.isEmpty(Ember.get(this, 'cloudCredentials'))) {
        Ember.run.next(function () {
          Ember.set(_this, 'showAddCloudCredential', true);
        });
      }

      Ember.run.next(function () {
        if (primaryResource && (!cloudCredentialValue || !cloudCredentials.find(function (c) {
          return c.id === cloudCredentialValue;
        }))) {
          _this.send('setCloudCredential', singleCloudCredentialId);
        }
      });
    }
  });

  _exports.default = _default;
});