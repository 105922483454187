define("ember-template-compiler/lib/plugins/transform-component-invocation", ["exports", "ember-template-compiler/lib/system/calculate-location-display", "ember-template-compiler/lib/plugins/utils"], function (_exports, _calculateLocationDisplay, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformComponentInvocation;

  /**
    Transforms unambigious invocations of closure components to be wrapped with
    the component helper. Once these syntaxes are fully supported by Glimmer VM
    natively, this transform can be removed.
  
    ```handlebars
    {{!-- this.foo is not a legal helper/component name --}}
    {{this.foo "with" some="args"}}
    ```
  
    with
  
    ```handlebars
    {{component this.foo "with" some="args"}}
    ```
  
    and
  
    ```handlebars
    {{!-- this.foo is not a legal helper/component name --}}
    {{#this.foo}}...{{/this.foo}}
    ```
  
    with
  
    ```handlebars
    {{#component this.foo}}...{{/component}}
    ```
  
    and
  
    ```handlebars
    {{!-- foo.bar is not a legal helper/component name --}}
    {{foo.bar "with" some="args"}}
    ```
  
    with
  
    ```handlebars
    {{component foo.bar "with" some="args"}}
    ```
  
    and
  
    ```handlebars
    {{!-- foo.bar is not a legal helper/component name --}}
    {{#foo.bar}}...{{/foo.bar}}
    ```
  
    with
  
    ```handlebars
    {{#component foo.bar}}...{{/component}}
    ```
  
    and
  
    ```handlebars
    {{!-- @foo is not a legal helper/component name --}}
    {{@foo "with" some="args"}}
    ```
  
    with
  
    ```handlebars
    {{component @foo "with" some="args"}}
    ```
  
    and
  
    ```handlebars
    {{!-- @foo is not a legal helper/component name --}}
    {{#@foo}}...{{/@foo}}
    ```
  
    with
  
    ```handlebars
    {{#component @foo}}...{{/component}}
    ```
  
    and
  
    ```handlebars
    {{#let ... as |foo|}}
      {{!-- foo is a local variable --}}
      {{foo "with" some="args"}}
    {{/let}}
    ```
  
    with
  
    ```handlebars
    {{#let ... as |foo|}}
      {{component foo "with" some="args"}}
    {{/let}}
    ```
  
    and
  
    ```handlebars
    {{#let ... as |foo|}}
      {{!-- foo is a local variable --}}
      {{#foo}}...{{/foo}}
    {{/let}}
    ```
  
    with
  
    ```handlebars
    {{#let ... as |foo|}}
      {{#component foo}}...{{/component}}
    {{/let}}
    ```
  
    @private
    @class TransFormComponentInvocation
  */
  function transformComponentInvocation(env) {
    var _trackLocals = (0, _utils.trackLocals)(),
        hasLocal = _trackLocals.hasLocal,
        node = _trackLocals.node;

    var isAttrs = false;
    return {
      name: 'transform-component-invocation',
      visitor: {
        Program: node,
        ElementNode: {
          keys: {
            attributes: {
              enter: function enter() {
                isAttrs = true;
              },
              exit: function exit() {
                isAttrs = false;
              }
            },
            children: node
          }
        },
        BlockStatement: function BlockStatement(node) {
          if (isBlockInvocation(node, hasLocal)) {
            wrapInComponent(env, node);
          }
        },
        MustacheStatement: function MustacheStatement(node) {
          if (!isAttrs && isInlineInvocation(node, hasLocal)) {
            wrapInComponent(env, node);
          }
        }
      }
    };
  }

  function isInlineInvocation(node, hasLocal) {
    var path = node.path;
    return (0, _utils.isPath)(path) && isIllegalName(path, hasLocal) && hasArguments(node);
  }

  function isIllegalName(node, hasLocal) {
    return isThisPath(node) || isDotPath(node) || isNamedArg(node) || isLocalVariable(node, hasLocal);
  }

  function isThisPath(node) {
    return node.this === true;
  }

  function isDotPath(node) {
    return node.parts.length > 1;
  }

  function isNamedArg(node) {
    return node.data === true;
  }

  function isLocalVariable(node, hasLocal) {
    return !node.this && hasLocal(node.parts[0]);
  }

  function hasArguments(node) {
    return node.params.length > 0 || node.hash.pairs.length > 0;
  }

  function isBlockInvocation(node, hasLocal) {
    return (0, _utils.isPath)(node.path) && isIllegalName(node.path, hasLocal);
  }

  function wrapInAssertion(moduleName, node, b) {
    var error = b.string("expected `" + node.original + "` to be a contextual component but found a string. Did you mean `(component " + node.original + ")`? " + (0, _calculateLocationDisplay.default)(moduleName, node.loc));
    return b.sexpr(b.path('-assert-implicit-component-helper-argument'), [node, error], b.hash(), node.loc);
  }

  function wrapInComponent(env, node) {
    var moduleName = env.meta.moduleName;
    var b = env.syntax.builders;
    var component = env.isProduction ? node.path : wrapInAssertion(moduleName, node.path, b);
    node.path = b.path('component');
    node.params.unshift(component);
  }
});