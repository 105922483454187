define("global-admin/clusters/new/route", ["exports", "shared/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var gs = this.globalStore;
      var reqs = {
        kontainerDrivers: gs.findAll('kontainerdriver'),
        nodeDrivers: gs.findAll('nodeDriver')
      };

      if (gs.getById('schema', 'clustertemplaterevision')) {
        reqs.clusterTemplates = gs.findAll('clustertemplate');
        reqs.clusterTemplateRevisions = gs.findAll('clustertemplaterevision');
      } else {
        reqs.clusterTemplates = [];
        reqs.clusterTemplateRevisions = [];
      }

      return Ember.RSVP.hash(reqs);
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // load the css/js url here, if the url loads fail we should error the driver out
      // show the driver in the ui, greyed out, and possibly add error text "can not load comonent from url [put url here]"
      var kontainerDrivers = model.kontainerDrivers;
      var externalDrivers = kontainerDrivers.filter(function (d) {
        return d.uiUrl !== '' && d.state === 'active';
      });
      var promises = {};
      externalDrivers.forEach(function (d) {
        if (Ember.get(d, 'hasUi')) {
          var jsUrl = (0, _loadScript.proxifyUrl)(d.uiUrl, _this.get('app.proxyEndpoint'));
          var cssUrl = (0, _loadScript.proxifyUrl)(d.uiUrl.replace(/\.js$/, '.css'), Ember.get(_this, 'app.proxyEndpoint')); // skip setProperties cause of weird names

          Ember.set(promises, "".concat(d.name, "Js"), (0, _loadScript.loadScript)(jsUrl, "driver-ui-js-".concat(d.name)));
          Ember.set(promises, "".concat(d.name, "Css"), (0, _loadScript.loadStylesheet)(cssUrl, "driver-ui-css-".concat(d.name)));
        }
      });

      if (Object.keys(promises).length > 0) {
        return Ember.RSVP.hashSettled(promises).then(function (settled) {
          var allkeys = Object.keys(settled);
          allkeys.forEach(function (key) {
            if (Ember.get(settled, "".concat(key, ".state")) === 'rejected') {
              var tmp = key.indexOf('Js') > -1 ? key.replace(/\Js$/, '') : key.replace(/\Css$/, '');
              var match = kontainerDrivers.findBy('id', tmp);
              console.log('Error Loading External Component for: ', match);

              if (match && Ember.get(match, 'scriptError') !== true) {
                Ember.set(match, 'scriptError', Ember.get(_this, 'intl').t('clusterNew.externalError'));
              }
            }
          });
        }).finally(function () {
          return model;
        });
      } else {
        return model;
      }
    }
  });

  _exports.default = _default;
});