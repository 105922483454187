define("ember-credit-card/templates/components/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g7p+vj5K",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"card-wrapper\"],[12],[13],[2,\"\\n\\t\"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"form\"],[14,1,\"ember-credit-card-form\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"number\",[35,0],\"Credit Card Number\"]]]],[2,\"\\n\\t\\t\"],[1,[30,[36,1],null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"name\",[35,2],\"Name on Credit Card\"]]]],[2,\"\\n\\t\\t\"],[1,[30,[36,1],null,[[\"type\",\"name\",\"value\"],[\"text\",\"expiry\",[35,3]]]]],[2,\"\\n\\t\\t\"],[1,[30,[36,1],null,[[\"type\",\"name\",\"value\"],[\"text\",\"cvc\",[35,4]]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"number\",\"input\",\"name\",\"expiry\",\"cvc\",\"showCard\",\"if\"]}",
    "meta": {
      "moduleName": "ember-credit-card/templates/components/credit-card.hbs"
    }
  });

  _exports.default = _default;
});