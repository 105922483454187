define("shared/components/cluster-driver/driver-googlegke/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-googlegke/template", "shared/utils/util", "jquery"], function (_exports, _clusterDriver, _template, _util, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var times = [{
    value: null,
    label: 'Any Time'
  }, {
    value: '00:00',
    label: '12:00AM'
  }, {
    value: '03:00',
    label: '3:00AM'
  }, {
    value: '06:00',
    label: '6:00AM'
  }, {
    value: '09:00',
    label: '9:00AM'
  }, {
    value: '12:00',
    label: '12:00PM'
  }, {
    value: '15:00',
    label: '3:00PM'
  }, {
    value: '19:00',
    label: '7:00PM'
  }, {
    value: '21:00',
    label: '9:00PM'
  }];
  var imageType = [{
    label: 'clusterNew.googlegke.imageType.UBUNTU',
    value: 'UBUNTU'
  }, {
    label: 'clusterNew.googlegke.imageType.COS',
    value: 'COS'
  }];
  var diskType = [{
    label: 'clusterNew.googlegke.diskType.pd-standard',
    value: 'pd-standard'
  }, {
    label: 'clusterNew.googlegke.diskType.pd-ssd',
    value: 'pd-ssd'
  }];
  var REGIONS = ['asia-east1', 'asia-east2', 'asia-northeast1', 'asia-northeast2', 'asia-south1', 'asia-southeast1', 'australia-southeast1', 'europe-north1', 'europe-west1', 'europe-west2', 'europe-west3', 'europe-west4', 'europe-west6', 'northamerica-northeast1', 'southamerica-east1', 'us-central1', 'us-east1', 'us-east4', 'us-west1', 'us-west2'];
  var DEFAULT_AUTH_SCOPES = ['devstorage.read_only', 'logging.write', 'monitoring', 'servicecontrol', 'service.management.readonly', 'trace.append'];
  var ZONE_TYPE = 'zonal';
  var REGION_TYPE = 'regional';
  var OAUTH_SCOPE_OPTIONS = {
    DEFAULT: 'default',
    FULL: 'full',
    CUSTOM: 'custom'
  };
  var GOOGLE_AUTH_URL_PREFIX = 'https://www.googleapis.com/auth/';
  var GOOGLE_AUTH_DEFAULT_URLS = DEFAULT_AUTH_SCOPES.map(function (a) {
    return "".concat(GOOGLE_AUTH_URL_PREFIX).concat(a);
  });
  var GOOGLE_FULL_AUTH_URL = 'https://www.googleapis.com/auth/cloud-platform';

  function getValueFromOauthScopes(oauthScopes, key, defaultValue) {
    var filteredValues = oauthScopes.filter(function (scope) {
      return scope.indexOf(key) !== -1;
    }).map(function (scope) {
      return scope.replace(GOOGLE_AUTH_URL_PREFIX, '').replace(key, '').split('.');
    }).filter(function (splitScopes) {
      return splitScopes.length <= 2;
    });

    if (filteredValues.length !== 1) {
      return defaultValue || 'none';
    }

    return filteredValues[0].length === 1 ? key : "".concat(key, ".").concat(filteredValues[0][1]);
  }
  /**
   * This oauthScopesMapper is responsible for both the mapping to oauthScopes
   * and unmapping from oauthscopes to form values. If you modify either
   * method ensure that the other reflects your changes.
   */


  var oauthScopesMapper = {
    mapOauthScopes: function mapOauthScopes(oauthScopesSelection, scopeConfig) {
      if (oauthScopesSelection === OAUTH_SCOPE_OPTIONS.DEFAULT) {
        return GOOGLE_AUTH_DEFAULT_URLS;
      } else if (oauthScopesSelection === OAUTH_SCOPE_OPTIONS.FULL) {
        return [GOOGLE_FULL_AUTH_URL];
      } else if (oauthScopesSelection === OAUTH_SCOPE_OPTIONS.CUSTOM) {
        scopeConfig = scopeConfig || {};
        var arr = [];
        Object.keys(scopeConfig).map(function (key) {
          if (scopeConfig[key] !== 'none') {
            arr.pushObject("https://www.googleapis.com/auth/".concat(scopeConfig[key]));
          }
        });
        return arr;
      }
    },
    unmapOauthScopes: function unmapOauthScopes(oauthScopes) {
      var containsUrls = oauthScopes && oauthScopes.length > 0;

      if (!containsUrls) {
        return {
          oauthScopesSelection: OAUTH_SCOPE_OPTIONS.DEFAULT
        };
      }

      var isAllAndOnlyDefaultUrls = GOOGLE_AUTH_DEFAULT_URLS.length === oauthScopes.length && GOOGLE_AUTH_DEFAULT_URLS.every(function (url) {
        return oauthScopes.indexOf(url) !== -1;
      });

      if (isAllAndOnlyDefaultUrls) {
        return {
          oauthScopesSelection: OAUTH_SCOPE_OPTIONS.DEFAULT
        };
      }

      var isOnlyTheFullUrl = oauthScopes.length === 1 && oauthScopes[0] === GOOGLE_FULL_AUTH_URL;

      if (isOnlyTheFullUrl) {
        return {
          oauthScopesSelection: OAUTH_SCOPE_OPTIONS.FULL
        };
      }

      return {
        oauthScopesSelection: OAUTH_SCOPE_OPTIONS.CUSTOM,
        scopeConfig: {
          userInfo: getValueFromOauthScopes(oauthScopes, 'userinfo', 'none'),
          computeEngine: getValueFromOauthScopes(oauthScopes, 'compute', 'none'),
          storage: getValueFromOauthScopes(oauthScopes, 'devstorage', 'devstorage.read_only'),
          taskQueue: getValueFromOauthScopes(oauthScopes, 'taskqueue', 'none'),
          bigQuery: getValueFromOauthScopes(oauthScopes, 'bigquery', 'none'),
          cloudSQL: getValueFromOauthScopes(oauthScopes, 'sqlservice', 'none'),
          cloudDatastore: getValueFromOauthScopes(oauthScopes, 'clouddatastore', 'none'),
          stackdriverLoggingAPI: getValueFromOauthScopes(oauthScopes, 'logging', 'logging.write'),
          stackdriverMonitoringAPI: getValueFromOauthScopes(oauthScopes, 'monitoring', 'monitoring'),
          cloudPlatform: getValueFromOauthScopes(oauthScopes, 'cloud-platform', 'none'),
          bigtableData: getValueFromOauthScopes(oauthScopes, 'bigtable.data', 'none'),
          bigtableAdmin: getValueFromOauthScopes(oauthScopes, 'bigtable.admin', 'none'),
          cloudPub: getValueFromOauthScopes(oauthScopes, 'pubsub', 'none'),
          serviceControl: getValueFromOauthScopes(oauthScopes, 'servicecontrol', 'none'),
          serviceManagement: getValueFromOauthScopes(oauthScopes, 'service.management', 'service.management.readonly'),
          stackdriverTrace: getValueFromOauthScopes(oauthScopes, 'trace', 'trace.append'),
          cloudSourceRepositories: getValueFromOauthScopes(oauthScopes, 'source', 'none'),
          cloudDebugger: getValueFromOauthScopes(oauthScopes, 'cloud_debugger', 'none')
        }
      };
    }
  };

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    layout: _template.default,
    configField: 'googleKubernetesEngineConfig',
    step: 1,
    zones: null,
    versions: null,
    machineTypes: null,
    initialMasterVersion: null,
    maintenanceWindowTimes: times,
    eipIdContent: [],
    imageTypeContent: imageType,
    clusterAdvanced: false,
    nodeAdvanced: false,
    diskTypeContent: diskType,
    scopeConfig: {},
    hideNewField: false,
    locationType: ZONE_TYPE,
    isNew: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),
    init: function init() {
      this._super.apply(this, arguments);

      var config = Ember.get(this, 'cluster.googleKubernetesEngineConfig');

      if (!config) {
        config = Ember.get(this, 'globalStore').createRecord({
          type: 'googleKubernetesEngineConfig',
          diskSizeGb: 100,
          enableAlphaFeature: false,
          nodeCount: 3,
          machineType: 'n1-standard-2',
          zone: 'us-central1-f',
          clusterIpv4Cidr: '',
          minNodeCount: 1,
          maxNodeCount: 5,
          imageType: 'UBUNTU',
          diskType: 'pd-standard',
          region: 'us-west2',
          taints: [],
          useIpAliases: true,
          ipPolicyCreateSubnetwork: true
        });
        Ember.setProperties(this, {
          'cluster.googleKubernetesEngineConfig': config,
          oauthScopesSelection: OAUTH_SCOPE_OPTIONS.DEFAULT,
          scopeConfig: {
            userInfo: 'none',
            computeEngine: 'none',
            storage: 'devstorage.read_only',
            taskQueue: 'none',
            bigQuery: 'none',
            cloudSQL: 'none',
            cloudDatastore: 'none',
            stackdriverLoggingAPI: 'logging.write',
            stackdriverMonitoringAPI: 'monitoring',
            cloudPlatform: 'none',
            bigtableData: 'none',
            bigtableAdmin: 'none',
            cloudPub: 'none',
            serviceControl: 'none',
            serviceManagement: 'service.management.readonly',
            stackdriverTrace: 'trace.append',
            cloudSourceRepositories: 'none',
            cloudDebugger: 'none'
          },
          resourceLabels: [],
          labels: [],
          taints: []
        });
      } else {
        var _config = config,
            _config$resourceLabel = _config.resourceLabels,
            resourceLabels = _config$resourceLabel === void 0 ? [] : _config$resourceLabel,
            _config$labels = _config.labels,
            labels = _config$labels === void 0 ? [] : _config$labels,
            _config$taints = _config.taints,
            taints = _config$taints === void 0 ? [] : _config$taints,
            _imageType = _config.imageType;

        if (!_imageType) {
          Ember.set(this, 'hideNewField', true);
        }

        var map = {};

        if (resourceLabels) {
          resourceLabels.map(function () {
            var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
            var split = t.split('=');
            Ember.set(map, split[0], split[1]);
          });
          Ember.set(this, 'resourceLabels', map);
        }

        if (labels) {
          labels.map(function () {
            var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
            var split = t.split('=');
            Ember.set(map, split[0], split[1]);
          });
          Ember.set(this, 'labels', map);
        }

        if (taints) {
          var _taints = taints.map(function () {
            var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
            var splitEffect = t.split(':');
            var splitLabel = (splitEffect[1] || '').split('=');
            return {
              effect: splitEffect[0],
              key: splitLabel[0],
              value: splitLabel[1]
            };
          });

          Ember.set(this, 'taints', _taints);
        } else {
          Ember.set(this, 'taints', []);
        }

        if (!Ember.get(this, 'oauthScopesSelection')) {
          var oauthScopes = Ember.get(config, 'oauthScopes');

          var _oauthScopesMapper$un = oauthScopesMapper.unmapOauthScopes(oauthScopes),
              oauthScopesSelection = _oauthScopesMapper$un.oauthScopesSelection,
              scopeConfig = _oauthScopesMapper$un.scopeConfig;

          Ember.set(this, 'oauthScopesSelection', oauthScopesSelection);

          if (scopeConfig) {
            Ember.set(this, 'scopeConfig', scopeConfig);
          }
        }
      }

      Ember.setProperties(this, {
        initialMasterVersion: Ember.get(this, 'config.masterVersion'),
        regionChoices: REGIONS.map(function (region) {
          return {
            name: region
          };
        }),
        locationType: Ember.get(this, 'config.zone') ? ZONE_TYPE : REGION_TYPE
      });
    },
    actions: {
      clickNext: function clickNext() {
        if (Ember.isEmpty(Ember.get(this, 'config.projectId'))) {
          this.parseProjectId();
        }

        (0, _jquery.default)('BUTTON[type="submit"]').click();
      },
      checkServiceAccount: function checkServiceAccount(cb) {
        var _this = this;

        Ember.set(this, 'errors', []);
        return Ember.RSVP.all([this.fetchZones(), this.fetchVersions(), this.fetchMachineTypes(), this.fetchNetworks(), this.fetchSubnetworks(), this.fetchServiceAccounts()]).then(function () {
          Ember.set(_this, 'step', 2);
          cb(true);
        }).catch(function () {
          cb(false);
        });
      },
      setLabels: function setLabels(section) {
        var out = [];

        for (var key in section) {
          out.pushObject("".concat(key, "=").concat(section[key]));
        }

        Ember.set(this, 'config.resourceLabels', out);
      },
      setNodeLabels: function setNodeLabels(section) {
        var out = [];

        for (var key in section) {
          out.pushObject("".concat(key, "=").concat(section[key]));
        }

        Ember.set(this, 'config.labels', out);
      },
      updateNameservers: function updateNameservers(nameservers) {
        Ember.set(this, 'config.masterAuthorizedNetworkCidrBlocks', nameservers);
      },
      setTaints: function setTaints(value) {
        Ember.set(this, 'config.taints', value);
      }
    },
    credentialChanged: Ember.observer('config.credential', function () {
      if (this.saving) {
        return;
      }

      this.parseProjectId();
    }),
    zoneChanged: Ember.observer('config.zone', 'zones.[]', function () {
      if (this.saving) {
        return;
      }

      var zones = Ember.get(this, 'zones') || [];
      var currentZone = zones.findBy('name', Ember.get(this, 'config.zone'));

      if (!currentZone || currentZone.status.toLowerCase() !== 'up') {
        var newZone = zones.filter(function (x) {
          return x.name.startsWith('us-');
        }).find(function (x) {
          return x.status.toLowerCase() === 'up';
        });

        if (newZone) {
          Ember.set(this, 'config.zone', newZone.name);
        }
      }

      this.fetchVersions();
      this.fetchMachineTypes();
      this.fetchNetworks();
      this.fetchSubnetworks();
      this.fetchServiceAccounts();
    }),
    machineTypeChanged: Ember.observer('config.machineTypes', 'machineTypes.[]', function () {
      if (this.saving) {
        return;
      }

      var types = Ember.get(this, 'machineTypes') || [];
      var current = types.findBy('name', Ember.get(this, 'config.machineType'));

      if (!current) {
        Ember.set(this, 'config.machineType', Ember.get(types, 'firstObject.name'));
      }
    }),
    versionChanged: Ember.observer('config.masterVersion', 'versionChoices.[]', function () {
      var current = Ember.get(this, 'config.masterVersion');

      if (this.saving && current) {
        return;
      }

      var versions = Ember.get(this, 'versionChoices') || [];
      var exists = versions.findBy('value', current);

      if (!exists) {
        Ember.set(this, 'config.masterVersion', Ember.get(versions, 'firstObject.value'));
      }
    }),
    networkChange: Ember.observer('config.network', 'subNetworkContent.[]', function () {
      if (this.saving) {
        return;
      }

      var subNetworkContent = Ember.get(this, 'subNetworkContent') || [];

      if (subNetworkContent.length > 1) {
        var firstNonNullSubnetMatch = subNetworkContent.find(function (sn) {
          return !Ember.isEmpty(sn.value);
        });
        Ember.setProperties(this, {
          'config.subNetwork': firstNonNullSubnetMatch.value,
          'config.ipPolicyCreateSubnetwork': false
        });
        var secondaryIpRangeContent = Ember.get(this, 'secondaryIpRangeContent') || [];

        if (secondaryIpRangeContent.length > 0) {
          var value = secondaryIpRangeContent[0] && secondaryIpRangeContent[0].value;
          Ember.setProperties(this, {
            'config.ipPolicyClusterSecondaryRangeName': value,
            'config.ipPolicyServicesSecondaryRangeName': value
          });
        }
      }
    }),
    subnetworkChange: Ember.observer('config.subNetwork', function () {
      if (this.saving) {
        return;
      }

      var subNetwork = this.config.subNetwork;

      if (Ember.isEmpty(subNetwork)) {
        Ember.set(this, 'config.ipPolicyCreateSubnetwork', true);
      } else {
        Ember.set(this, 'config.ipPolicyCreateSubnetwork', false);
      }
    }),
    secondaryIpRangeContentChange: Ember.observer('secondaryIpRangeContent.[]', 'config.useIpAliases', function () {
      if (this.saving) {
        return;
      }

      var secondaryIpRangeContent = Ember.get(this, 'secondaryIpRangeContent') || [];

      if (secondaryIpRangeContent.length === 0) {
        Ember.set(this, 'config.ipPolicyCreateSubnetwork', true);
      }
    }),
    useIpAliasesChange: Ember.observer('config.useIpAliases', function () {
      if (this.saving) {
        return;
      }

      if (Ember.get(this, 'config.useIpAliases')) {
        if (!Ember.isEmpty(this.config.subNetwork)) {
          Ember.set(this, 'config.ipPolicyCreateSubnetwork', false);
        }
      } else {
        Ember.setProperties(this, {
          'config.enablePrivateNodes': false,
          'config.ipPolicyCreateSubnetwork': false
        });
      }
    }),
    enablePrivateNodesChange: Ember.observer('config.enablePrivateNodes', function () {
      if (this.saving) {
        return;
      }

      var config = Ember.get(this, 'config');

      if (!Ember.get(config, 'enablePrivateNodes')) {
        Ember.setProperties(config, {
          enablePrivateEndpoint: false,
          masterIpv4CidrBlock: ''
        });
      }
    }),
    zoneChoices: Ember.computed('zones.[]', function () {
      var out = (Ember.get(this, 'zones') || []).slice();
      out.forEach(function (obj) {
        Ember.set(obj, 'sortName', (0, _util.sortableNumericSuffix)(obj.name));
        Ember.set(obj, 'displayName', "".concat(obj.name, " (").concat(obj.description, ")"));
        Ember.set(obj, 'disabled', obj.status.toLowerCase() !== 'up');
      });
      return out.sortBy('sortName');
    }),
    machineChoices: Ember.computed('machineTypes.[]', function () {
      var out = (Ember.get(this, 'machineTypes') || []).slice();
      out.forEach(function (obj) {
        Ember.set(obj, 'sortName', (0, _util.sortableNumericSuffix)(obj.name));
        Ember.set(obj, 'displayName', "".concat(obj.name, " (").concat(obj.description, ")"));
      });
      return out.sortBy('sortName');
    }),
    editedMachineChoice: Ember.computed('config.machineType', 'machineChoices', function () {
      return Ember.get(this, 'machineChoices').findBy('name', Ember.get(this, 'config.machineType'));
    }),
    versionChoices: Ember.computed('versions.validMasterVersions.[]', 'config.masterVersion', function () {
      var _this$versions$validM = this.versions.validMasterVersions,
          validMasterVersions = _this$versions$validM === void 0 ? [] : _this$versions$validM,
          masterVersion = this.config.masterVersion,
          mode = this.mode;
      return this.versionChoiceService.parseCloudProviderVersionChoices(validMasterVersions, masterVersion, mode);
    }),
    locationContent: Ember.computed('config.zone', 'zoneChoices', function () {
      var zone = Ember.get(this, 'config.zone');

      if (!zone) {
        return [];
      }

      var arr = zone.split('-');
      var locationName = "".concat(arr[0], "-").concat(arr[1]);
      var zoneChoices = Ember.get(this, 'zoneChoices');
      return zoneChoices.filter(function (z) {
        return (z.name || '').startsWith(locationName) && z.name !== zone;
      });
    }),
    networkContent: Ember.computed('networks', 'config.zone', function () {
      return Ember.get(this, 'networks');
    }),
    subNetworkContent: Ember.computed('subNetworks.[]', 'config.network', 'config.zone', function () {
      var networkName = this.config.network,
          networkContent = this.networkContent,
          _this$subNetworks = this.subNetworks,
          subNetworks = _this$subNetworks === void 0 ? [] : _this$subNetworks;
      var filteredSubnets = (subNetworks || []).filter(function (s) {
        var network = networkContent.findBy('selfLink', s.network);
        var networkDisplayName = network.name;

        if (networkDisplayName === networkName) {
          return true;
        }
      });
      var mappedSubnets = filteredSubnets.map(function (o) {
        var network = networkContent.findBy('selfLink', o.network);
        var networkDisplayName = network.name;
        return {
          label: "".concat(o.name, "(").concat(o.ipCidrRange, ")"),
          value: o.name,
          secondaryIpRanges: o.secondaryIpRanges,
          networkDisplayName: networkDisplayName
        };
      });
      var defaultSubnetAry = [{
        label: this.intl.t('clusterNew.googlegke.ipPolicyCreateSubnetwork.autoLabel'),
        value: ''
      }];
      return [].concat(defaultSubnetAry, _toConsumableArray(mappedSubnets));
    }),
    secondaryIpRangeContent: Ember.computed('subNetworkContent.[]', 'config.network', function () {
      var _this$subNetworkConte = this.subNetworkContent,
          subNetworkContent = _this$subNetworkConte === void 0 ? [] : _this$subNetworkConte,
          network = this.config.network;
      var subNetwork = subNetworkContent.findBy('networkDisplayName', network);

      if (subNetwork) {
        var _subNetwork$secondary = subNetwork.secondaryIpRanges,
            secondaryIpRanges = _subNetwork$secondary === void 0 ? [] : _subNetwork$secondary;
        return secondaryIpRanges.map(function (s) {
          return {
            label: "".concat(s.rangeName, "(").concat(s.ipCidrRange, ")"),
            value: s.rangeName
          };
        });
      }

      return [];
    }),
    serviceAccountContent: Ember.computed('serviceAccounts', function () {
      var serviceAccounts = Ember.get(this, 'serviceAccounts');
      return serviceAccounts;
    }),
    maintenanceWindowChoice: Ember.computed('maintenanceWindowTimes.[]', 'config.maintenanceWindow', function () {
      return Ember.get(this, 'maintenanceWindowTimes').findBy('value', Ember.get(this, 'config.maintenanceWindow')) || {
        label: 'Any Time'
      };
    }),
    shouldAllowDisableCreateSubNetwork: Ember.computed('config.subNetwork', function () {
      var subNetwork = this.config.subNetwork,
          secondaryIpRangeContent = this.secondaryIpRangeContent,
          editing = this.editing;

      if (Ember.isEmpty(subNetwork)) {
        return true;
      }

      if (editing && Ember.isEmpty(secondaryIpRangeContent)) {
        return true;
      }

      return false;
    }),
    fetchZones: function fetchZones() {
      var _this2 = this;

      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeZones',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId')
        }
      }).then(function (xhr) {
        var out = xhr.body.items;
        var locations = Ember.get(_this2, 'config.locations') || [];

        if (locations.length > 0) {
          out.map(function (o) {
            if (locations.includes(o.name)) {
              Ember.set(o, 'checked', true);
            }
          });
        }

        Ember.set(_this2, 'zones', out);
        return out;
      }).catch(function (xhr) {
        Ember.set(_this2, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },
    fetchVersions: function fetchVersions() {
      var _this3 = this;

      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeVersions',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone') || "".concat(Ember.get(this, 'config.region'), "-b")
        }
      }).then(function (xhr) {
        var out = xhr.body;
        Ember.set(_this3, 'versions', out);

        _this3.versionChanged();

        return out;
      }).catch(function (xhr) {
        Ember.set(_this3, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },
    fetchMachineTypes: function fetchMachineTypes() {
      var _this4 = this;

      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeMachineTypes',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone') || "".concat(Ember.get(this, 'config.region'), "-b")
        }
      }).then(function (xhr) {
        var out = xhr.body.items;
        Ember.set(_this4, 'machineTypes', out);
        return out;
      }).catch(function (xhr) {
        Ember.set(_this4, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },
    fetchNetworks: function fetchNetworks() {
      var _this5 = this;

      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeNetworks',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone')
        }
      }).then(function (xhr) {
        var out = xhr.body.items || [];
        Ember.set(_this5, 'networks', out);

        if (Ember.get(_this5, 'mode') === 'new') {
          Ember.set(_this5, 'config.network', out[0] && out[0].name);
        }

        return out;
      }).catch(function (xhr) {
        Ember.set(_this5, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },
    fetchSubnetworks: function fetchSubnetworks() {
      var _this6 = this;

      if (this.saved) {
        return;
      }

      var zone = Ember.get(this, 'config.zone');
      var locationType = Ember.get(this, 'locationType');
      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeSubnetworks',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          region: locationType === ZONE_TYPE ? "".concat(zone.split('-')[0], "-").concat(zone.split('-')[1]) : Ember.get(this, 'config.region')
        }
      }).then(function (xhr) {
        var out = xhr.body.items || [];
        Ember.set(_this6, 'subNetworks', out);
        return out;
      }).catch(function (xhr) {
        Ember.set(_this6, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },
    fetchServiceAccounts: function fetchServiceAccounts() {
      var _this7 = this;

      if (this.saved) {
        return;
      }

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/gkeServiceAccounts',
        method: 'POST',
        data: {
          credentials: Ember.get(this, 'config.credential'),
          projectId: Ember.get(this, 'config.projectId'),
          zone: Ember.get(this, 'config.zone')
        }
      }).then(function (xhr) {
        var out = xhr.body.accounts || [];
        Ember.set(_this7, 'serviceAccounts', out);
        var filter = out.filter(function (o) {
          return o.displayName === 'Compute Engine default service account';
        });

        if (Ember.get(_this7, 'mode') === 'new') {
          Ember.set(_this7, 'config.serviceAccount', filter[0] && filter[0].uniqueId);
        }

        return out;
      }).catch(function (xhr) {
        Ember.set(_this7, 'errors', [xhr.body.error]);
        return Ember.RSVP.reject();
      });
    },
    validate: function validate() {
      var model = Ember.get(this, 'cluster');
      var errors = model.validationErrors();
      var intl = this.intl,
          _this$config = this.config,
          config = _this$config === void 0 ? {} : _this$config;
      var minNodeCount = config.minNodeCount,
          maxNodeCount = config.maxNodeCount,
          enableNodepoolAutoscaling = config.enableNodepoolAutoscaling,
          nodeCount = config.nodeCount;

      if (enableNodepoolAutoscaling) {
        if (nodeCount && maxNodeCount && minNodeCount) {
          nodeCount = parseInt(nodeCount, 10);
          maxNodeCount = parseInt(maxNodeCount, 10);
          minNodeCount = parseInt(minNodeCount, 10);

          if (maxNodeCount < minNodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.maxNodeCount.minError'));
          }

          if (enableNodepoolAutoscaling && maxNodeCount < nodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.nodeCount.outsideError'));
          }

          if (enableNodepoolAutoscaling && minNodeCount > nodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.nodeCount.outsideError'));
          }
        } else {
          if (!nodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.nodeCount.required'));
          }

          if (!maxNodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.maxNodeCount.required'));
          }

          if (!minNodeCount) {
            errors.pushObject(intl.t('clusterNew.googlegke.minNodeCount.required'));
          }
        }
      }

      if (!Ember.get(this, 'cluster.name')) {
        errors.pushObject(intl.t('clusterNew.name.required'));
      }

      var taints = Ember.get(this, 'taints') || [];

      if (taints.length > 0) {
        var filter = taints.filter(function (t) {
          return !t.key || !t.value;
        });

        if (filter.length > 0) {
          errors.pushObject(intl.t('clusterNew.googlegke.taints.required'));
        }
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },
    willSave: function willSave() {
      var config = Ember.get(this, 'config') || {};
      var locationType = Ember.get(this, 'locationType');

      if (locationType === ZONE_TYPE) {
        Ember.set(config, 'region', null);
      } else {
        Ember.set(config, 'zone', null);
      }

      if (!Ember.get(config, 'enableNodepoolAutoscaling')) {
        Ember.setProperties(config, {
          minNodeCount: 0,
          maxNodeCount: 0
        });
      }

      if (Ember.get(this, 'config.useIpAliases') && Ember.get(config, 'ipPolicyCreateSubnetwork') && Ember.get(config, 'ipPolicyClusterIpv4CidrBlock')) {
        Ember.set(config, 'clusterIpv4Cidr', '');
      }

      if (!Ember.get(config, 'enableMasterAuthorizedNetwork')) {
        delete config.masterAuthorizedNetworkCidrBlocks;
      }

      if (!Ember.get(config, 'resourceLabels')) {
        delete config.resourceLabels;
      }

      var locationContent = Ember.get(this, 'locationContent');
      var locations = locationContent.filter(function (l) {
        return l.checked;
      }).map(function (l) {
        return l.name;
      });

      if (locations.length > 0) {
        locations.push(Ember.get(config, 'zone'));
        Ember.set(config, 'locations', locations);
      } else {
        delete config.locations;
      }

      var oauthScopesSelection = Ember.get(this, 'oauthScopesSelection');
      var scopeConfig = Ember.get(this, 'scopeConfig');
      Ember.set(config, 'oauthScopes', oauthScopesMapper.mapOauthScopes(oauthScopesSelection, scopeConfig));
      var taints = Ember.get(this, 'taints') || [];

      if (taints.length > 0) {
        Ember.set(config, 'taints', taints.map(function (t) {
          return "".concat(t.effect, ":").concat(t.key, "=").concat(t.value);
        }));
      } else {
        Ember.set(config, 'taints', []);
      }

      Ember.set(config, 'issueClientCertificate', true);
      return this._super.apply(this, arguments);
    },
    parseProjectId: function parseProjectId() {
      var str = Ember.get(this, 'config.credential');

      if (str) {
        try {
          var obj = JSON.parse(str); // Note: this is a Google project id, not ours.

          var projectId = obj.project_id;
          Ember.set(this, 'config.projectId', projectId);
        } catch (e) {}
      }
    }
  });

  _exports.default = _default;
});