define("shared/components/custom-catalog/component", ["exports", "shared/components/custom-catalog/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'scope',
    sort: ['clusterId', 'projectId'],
    searchField: ['clusterId', 'projectId'],
    translationKey: 'generic.scope',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name',
    width: 250
  }, {
    name: 'url',
    sort: ['url', 'displayName'],
    translationKey: 'catalogSettings.more.url.label'
  }, {
    name: 'branch',
    sort: ['branch', 'displayName'],
    translationKey: 'catalogSettings.more.branch.label',
    width: 120
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    headers: headers,
    tagName: null,
    catalogs: null,
    mode: 'global',
    sortBy: 'name',
    descending: false,
    paging: true,
    rightActions: true,
    library: Ember.computed('catalogs.@each.name', function () {
      return Ember.get(this, 'catalogs').findBy('name', _constants.default.CATALOG.LIBRARY_KEY);
    }),
    helm3Stable: Ember.computed('catalogs.@each.name', function () {
      return Ember.get(this, 'catalogs').findBy('name', _constants.default.CATALOG.HELM_3_LIBRARY_KEY);
    }),
    helmStable: Ember.computed('catalogs.@each.name', function () {
      return Ember.get(this, 'catalogs').findBy('name', _constants.default.CATALOG.HELM_STABLE_KEY);
    }),
    helmIncubator: Ember.computed('catalogs.@each.name', function () {
      return Ember.get(this, 'catalogs').findBy('name', _constants.default.CATALOG.HELM_INCUBATOR_KEY);
    }),
    alibabaAppHub: Ember.computed('catalogs.@each.name', function () {
      return Ember.get(this, 'catalogs').findBy('name', _constants.default.CATALOG.ALIBABA_APP_HUB_KEY);
    }),
    rows: Ember.computed('alibabaAppHub', 'catalogs.@each.{id,name,url}', 'helm3Stable', 'helmIncubator', 'helmStable', 'library', 'mode', function () {
      var out = Ember.get(this, 'catalogs').slice();

      if (Ember.get(this, 'mode') === 'global') {
        if (!this.library) {
          out.push(Ember.get(this, 'globalStore').createRecord({
            type: 'catalog',
            name: _constants.default.CATALOG.LIBRARY_KEY,
            url: _constants.default.CATALOG.LIBRARY_VALUE,
            branch: _constants.default.CATALOG.DEFAULT_BRANCH,
            kind: 'helm'
          }));
        }

        if (!this.helmStable) {
          out.push(Ember.get(this, 'globalStore').createRecord({
            type: 'catalog',
            name: _constants.default.CATALOG.HELM_STABLE_KEY,
            url: _constants.default.CATALOG.HELM_STABLE_VALUE,
            branch: _constants.default.CATALOG.DEFAULT_BRANCH,
            kind: 'helm'
          }));
        }

        if (!this.helmIncubator) {
          out.push(Ember.get(this, 'globalStore').createRecord({
            type: 'catalog',
            name: _constants.default.CATALOG.HELM_INCUBATOR_KEY,
            url: _constants.default.CATALOG.HELM_INCUBATOR_VALUE,
            branch: _constants.default.CATALOG.DEFAULT_BRANCH,
            kind: 'helm'
          }));
        }

        if (!this.alibabaAppHub) {
          out.push(Ember.get(this, 'globalStore').createRecord({
            type: 'catalog',
            name: _constants.default.CATALOG.ALIBABA_APP_HUB_KEY,
            url: _constants.default.CATALOG.ALIBABA_APP_HUB_VALUE,
            branch: _constants.default.CATALOG.DEFAULT_BRANCH,
            kind: 'helm'
          }));
        }

        if (!this.helm3Stable) {
          out.push(Ember.get(this, 'globalStore').createRecord({
            type: 'catalog',
            name: _constants.default.CATALOG.HELM_3_LIBRARY_KEY,
            url: _constants.default.CATALOG.HELM_3_LIBRARY_VALUE,
            branch: _constants.default.CATALOG.DEFAULT_BRANCH,
            kind: 'helm'
          }));
        }
      }

      return out;
    })
  });

  _exports.default = _default;
});