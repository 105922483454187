define("liquid-fire/components/liquid-child", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['liquid-child'],
    init: function init() {
      this._super.apply(this, arguments);

      this._waitingFor = [];
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.element) {
        this.element.style.visibility = 'hidden';
      }

      this._waitForAll().then(function () {
        if (!_this.isDestroying) {
          _this._waitingFor = null;
          var didRenderAction = Ember.get(_this, 'liquidChildDidRender');

          if (typeof didRenderAction === 'function') {
            didRenderAction(_this);
          }
        }
      });
    },
    _isLiquidChild: true,
    _waitForMe: function _waitForMe(promise) {
      if (!this._waitingFor) {
        return;
      }

      this._waitingFor.push(promise);

      var ancestor = this.nearestWithProperty('_isLiquidChild');

      if (ancestor) {
        ancestor._waitForMe(promise);
      }
    },
    _waitForAll: function _waitForAll() {
      var _this2 = this;

      var promises = this._waitingFor;
      this._waitingFor = [];
      return Ember.RSVP.Promise.all(promises).then(function () {
        if (_this2._waitingFor.length > 0) {
          return _this2._waitForAll();
        }
      });
    }
  });

  _exports.default = _default;
});