define("ember-intl/services/intl", ["exports", "@ember-intl/intl-relativeformat", "@ember-intl/intl-messageformat", "ember-intl/-private/formatters", "ember-intl/-private/is-array-equal", "ember-intl/-private/normalize-locale", "ember-intl/utils/links", "ember-intl/hydrate", "ember-intl/utils/get-dom"], function (_exports, _intlRelativeformat, _intlMessageformat, _formatters, _isArrayEqual, _normalizeLocale, _links, _hydrate, _getDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend(Ember.Evented, {
    /** @private **/
    _locale: null,

    /** @private **/
    _adapter: null,

    /** @public **/
    formats: null,

    /** @private **/
    _timer: null,

    /** @public **/
    locale: Ember.computed({
      set: function set(_, localeName) {
        var _this = this;

        var proposed = Ember.makeArray(localeName).map(_normalizeLocale.default);

        if (!(0, _isArrayEqual.default)(proposed, this._locale)) {
          this._locale = proposed;
          Ember.run.cancel(this._timer);
          this._timer = Ember.run.next(function () {
            _this.trigger('localeChanged');

            _this.updateDocumentLanguage(_this._locale);
          });
        }

        return this._locale;
      },
      get: function get() {
        return this._locale;
      }
    }),

    /**
     * Returns the first locale of the currently active locales
     *
     * @property primaryLocale
     * @public
     */
    primaryLocale: Ember.computed.readOnly('locale.0'),

    /** @public **/
    formatRelative: formatter('relative'),

    /** @public **/
    formatMessage: formatter('message'),

    /** @public **/
    formatNumber: formatter('number'),

    /** @public **/
    formatTime: formatter('time'),

    /** @public **/
    formatDate: formatter('date'),

    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: Ember.computed.readOnly('_adapter.locales'),

    /** @public **/
    init: function init() {
      this._super.apply(this, arguments);

      if (typeof Intl === 'undefined') {
        (false && Ember.warn("[ember-intl] Intl API was not found.\nSee: ".concat(_links.default.polyfill), false, {
          id: 'ember-intl-undefined-intljs'
        }));
      }

      var initialLocale = Ember.get(this, 'locale') || ['en-us'];
      this.setLocale(initialLocale);
      this._owner = Ember.getOwner(this);
      this._adapter = this._owner.lookup('ember-intl@adapter:default');
      this._formatters = {
        message: new _formatters.FormatMessage(),
        relative: new _formatters.FormatRelative(),
        number: new _formatters.FormatNumber(),
        time: new _formatters.FormatTime(),
        date: new _formatters.FormatDate()
      };

      if (!this.formats) {
        this.formats = this._owner.resolveRegistration('formats:main') || {};
      }

      (0, _hydrate.default)(this, this._owner);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._timer);
    },

    /** @public **/
    lookup: function lookup(key, localeName) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var localeNames = this.localeWithDefault(localeName);
      var translation;

      for (var i = 0; i < localeNames.length; i++) {
        translation = this._adapter.lookup(localeNames[i], key);

        if (translation !== undefined) {
          break;
        }
      }

      if (!options.resilient && translation === undefined) {
        var missingMessage = this._owner.resolveRegistration('util:intl/missing-message');

        return missingMessage.call(this, key, localeNames, options);
      }

      return translation;
    },

    /** @public **/
    t: function t(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var defaults = [key];
      var msg;

      if (options.default) {
        defaults = defaults.concat(options.default);
      }

      while (!msg && defaults.length) {
        msg = this.lookup(defaults.shift(), options.locale, Ember.assign({}, options, {
          resilient: defaults.length > 0
        }));
      }
      /* Avoids passing msg to intl-messageformat if it is not a string */


      if (typeof msg === 'string') {
        return this.formatMessage(msg, options);
      }

      return msg;
    },

    /** @public **/
    exists: function exists(key, localeName) {
      var _this2 = this;

      var localeNames = this.localeWithDefault(localeName);
      (false && !(Array.isArray(localeNames) && localeNames.length) && Ember.assert("[ember-intl] locale is unset, cannot lookup '".concat(key, "'"), Array.isArray(localeNames) && localeNames.length));
      return localeNames.some(function (localeName) {
        return _this2._adapter.has(localeName, key);
      });
    },

    /** @public */
    setLocale: function setLocale(locale) {
      Ember.set(this, 'locale', locale);
    },

    /**
     * A utility method for registering CLDR data against
     * intl-messageformat and intl-relativeformat.
     *
     * @method addLocaleData
     * @param {Object} locale data
     * @public
     */
    addLocaleData: function addLocaleData(data) {
      _intlMessageformat.default.__addLocaleData(data);

      _intlRelativeformat.default.__addLocaleData(data);
    },

    /** @public **/
    addTranslations: function addTranslations(localeName, payload) {
      var locale = this.translationsFor(localeName);
      return locale.addTranslations(payload);
    },

    /** @public **/
    translationsFor: function translationsFor(localeName) {
      return this._adapter.localeFactory((0, _normalizeLocale.default)(localeName));
    },

    /** @private **/
    getFormat: function getFormat(formatType, format) {
      var formats = Ember.get(this, 'formats');

      if (formats && formatType && typeof format === 'string') {
        return Ember.get(formats, "".concat(formatType, ".").concat(format));
      }
    },

    /** @private **/
    localeWithDefault: function localeWithDefault(localeName) {
      if (!localeName) {
        return this._locale || [];
      }

      if (typeof localeName === 'string') {
        return Ember.makeArray(localeName).map(_normalizeLocale.default);
      }

      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    },

    /** @private **/
    updateDocumentLanguage: function updateDocumentLanguage(locales) {
      var dom = (0, _getDom.default)(this);

      if (dom) {
        var _locales = _slicedToArray(locales, 1),
            primaryLocale = _locales[0];

        var html = dom.documentElement;
        html.setAttribute('lang', primaryLocale);
      }
    }
  });

  _exports.default = _default;

  function formatter(name) {
    return function (value, options, formats) {
      var formatOptions = options;

      if (options && typeof options.format === 'string') {
        formatOptions = Ember.assign({}, this.getFormat(name, formatOptions.format), formatOptions);
      }

      return this._formatters[name].format(value, formatOptions, {
        formats: formats || this.formats,
        locale: this.localeWithDefault(formatOptions && formatOptions.locale)
      });
    };
  }
});