define("shared/components/modal-save-rke-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "opXJ6q2/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"modalSaveRkeTemplate.title\"],[[\"clusterName\"],[[35,0,[\"displayName\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"modalSaveRkeTemplate.subtitle\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"horizontal-form container-fluid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row inline-form\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,\"for\",\"save-rke-template-cluster-template-name\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"modalSaveRkeTemplate.name\"],null]],[1,[34,2]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"id\"],[\"text\",[35,3],\"save-rke-template-cluster-template-name\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[8,\"save-cancel\",[],[[\"@save\",\"@cancel\",\"@saveDisabled\",\"@cancelLabel\"],[[30,[36,5],[[32,0],\"save\"],null],[30,[36,5],[[32,0],\"cancel\"],null],[34,6],\"generic.closeModal\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"cluster\",\"t\",\"field-required\",\"clusterTemplateName\",\"input\",\"action\",\"saveDisabled\"]}",
    "meta": {
      "moduleName": "shared/components/modal-save-rke-template/template.hbs"
    }
  });

  _exports.default = _default;
});