define("shared/components/metrics-summary/component", ["exports", "shared/components/metrics-summary/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    title: null,
    expandedDidChange: Ember.observer('expanded', function () {
      if (Ember.get(this, 'expanded')) {
        (0, _jquery.default)(window).trigger('resize');
      }
    })
  });

  _exports.default = _default;
});