define("ember-engines/-private/controller-ext", [], function () {
  "use strict";

  Ember.Controller.reopen({
    /*
      Creates an aliased form of a method that properly resolves external routes.
    */
    transitionToExternalRoute: function transitionToExternalRoute(routeName) {
      var externalRoute = Ember.getOwner(this)._getExternalRoute(routeName);

      var target = this.target;
      var method = target.transitionToRoute || target.transitionTo;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return method.apply(target, [externalRoute].concat(args));
    }
  });
});