define("global-admin/clusters/new/launch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QxoWqDEu",
    "block": "{\"symbols\":[],\"statements\":[[8,\"cru-cluster\",[],[[\"@applyClusterTemplate\",\"@model\",\"@provider\",\"@mode\",\"@close\",\"@clusterDriverError\",\"@clusterTemplateRevisionId\"],[[30,[36,2],[[30,[36,1],[[35,0]],null]],null],[34,3],[34,3,[\"provider\"]],\"new\",[30,[36,4],[[32,0],\"close\"],null],[30,[36,4],[[32,0],\"clusterDriverErrorAndTransition\"],null],[30,[36,5],[[35,0]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"clusterTemplateRevision\",\"is-empty\",\"not\",\"model\",\"action\",\"mut\"]}",
    "meta": {
      "moduleName": "global-admin/clusters/new/launch/template.hbs"
    }
  });

  _exports.default = _default;
});