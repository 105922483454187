define("shared/components/check-override-allowed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ovzkfIoh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,7],[[35,12]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,7],[[30,[36,11],null,[[\"questions\",\"paramName\"],[[35,10],[35,0]]]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,9],null,[[\"hasOverride\"],[true]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,4],[[35,8],[30,[36,3],[[35,8],false],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[34,8]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,2],[[35,1],[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,3],[[35,5],\"view\"],null],[30,[36,4],[[30,[36,2],[[35,1],[35,0]],null],[30,[36,3],[[30,[36,2],[[35,1],[35,0]],null],false],null]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,1],[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"paramName\",\"clusterTemplateRevision\",\"get\",\"eq\",\"or\",\"mode\",\"and\",\"if\",\"value\",\"hash\",\"questions\",\"has-override\",\"applyClusterTemplate\"]}",
    "meta": {
      "moduleName": "shared/components/check-override-allowed/template.hbs"
    }
  });

  _exports.default = _default;
});