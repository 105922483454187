define("global-admin/components/form-runasgroup-policy/component", ["exports", "global-admin/components/form-runasgroup-policy/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    model: null,
    readOnly: false,
    statusClass: null,
    status: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'model.runAsGroup', Ember.get(this, 'model.runAsGroup') || Ember.get(this, 'globalStore').createRecord({
        type: 'runAsGroupStrategyOptions',
        rule: 'RunAsAny'
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.get(this, 'expandFn')) {
        Ember.set(this, 'expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },
    actions: {
      add: function add() {
        Ember.get(this, 'model.runAsGroup.ranges').pushObject(Ember.get(this, 'globalStore').createRecord({
          type: 'idRange',
          min: 1,
          max: 6
        }));
      },
      remove: function remove(obj) {
        Ember.get(this, 'model.runAsGroup.ranges').removeObject(obj);
      }
    },
    ruleDidChange: Ember.observer('model.runAsGroup.rule', function () {
      var rule = Ember.get(this, 'model.runAsGroup.rule');

      if (rule === 'MustRunAs' || rule === 'MayRunAs') {
        Ember.set(this, 'model.runAsGroup.ranges', []);
        this.send('add');
      } else {
        Ember.set(this, 'model.runAsGroup.ranges', null);
      }
    })
  });

  _exports.default = _default;
});