define("shared/components/modal-resize-pvc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jhzCUF52",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"modalResizePVC.title\"],[[\"name\"],[[35,0,[\"name\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"protip\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"modalResizePVC.requestedCapacity.help\"],[[\"htmlSafe\"],[\"true\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-8\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"modalResizePVC.requestedCapacity.label\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"classNames\",\"value\"],[\"form-control\",[35,2]]]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"input-group-addon bg-default\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"cruPersistentVolume.capacity.unit\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,5],null,[[\"editing\",\"save\",\"cancel\"],[true,[30,[36,4],[[32,0],\"save\"],null],[30,[36,4],[[32,0],\"cancel\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"t\",\"storage\",\"input-number\",\"action\",\"save-cancel\"]}",
    "meta": {
      "moduleName": "shared/components/modal-resize-pvc/template.hbs"
    }
  });

  _exports.default = _default;
});