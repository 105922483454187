define("shared/components/form-labels-annotations/component", ["exports", "shared/mixins/manage-labels", "shared/components/accordion-list-item/component", "shared/components/form-labels-annotations/template", "ui/utils/constants", "jquery"], function (_exports, _manageLabels, _component, _template, _constants, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageLabels.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    detailKey: 'formLabelsAnnotations.detail',
    annotationsCount: 0,
    labelsCount: 0,
    expandAll: null,
    editing: true,
    readonlyLabels: null,
    readonlyAnnotations: null,
    // Inputs
    initialLabels: null,
    model: null,
    statusClass: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initLabelsAndAnnotations();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },
    actions: {
      addUserLabel: function addUserLabel() {
        var _this = this;

        this._super();

        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          (0, _jquery.default)('.js-label.key').last()[0].focus();
        });
      },
      annotationsChange: function annotationsChange(annotations) {
        Ember.set(this, 'annotationsCount', Object.keys(annotations).length);
        Ember.set(this, 'model.annotations', annotations);
      }
    },
    observeLabelCount: Ember.observer('userLabelArray.@each.key', function () {
      var count = this.get('userLabelArray').filterBy('key').get('length') || 0;
      this.set('labelsCount', count || 0);
    }),
    status: Ember.computed('labelsCount', 'annotationsCount', function () {
      var k = _component.STATUS.NONE;
      var annotationsCount = this.get('annotationsCount');
      var labelsCount = this.get('labelsCount');
      var count = labelsCount + annotationsCount;

      if (count) {
        k = _component.STATUS.COUNTCONFIGURED;
      }

      this.set('statusClass', (0, _component.classForStatus)(k));
      return this.get('intl').t("".concat(_component.STATUS_INTL_KEY, ".").concat(k), {
        count: count
      });
    }),
    initLabelsAndAnnotations: function initLabelsAndAnnotations() {
      var readonlyLabels = Ember.get(this, 'readonlyLabels') || [];
      var readonlyAnnotations = Ember.get(this, 'readonlyAnnotations') || [];
      var initialLabelsKeys = Object.keys(Ember.get(this, 'initialLabels') || {});
      initialLabelsKeys.forEach(function (key) {
        if (isReadonlyLabel(key)) {
          readonlyLabels.push(key);
        }
      });
      Object.keys(Ember.get(this, 'model.annotations') || {}).forEach(function (key) {
        if (_constants.default.ANNOTATIONS_TO_IGNORE_PREFIX.find(function (L) {
          return key.startsWith(L);
        }) || _constants.default.ANNOTATIONS_TO_IGNORE_CONTAINS.find(function (L) {
          return key.indexOf(L) > -1;
        })) {
          readonlyAnnotations.push(key);
        }
      });
      Ember.set(this, 'readonlyAnnotations', readonlyAnnotations);
      this.initLabels(this.get('initialLabels'), 'user', null, readonlyLabels, this.k3sLabelsToIgnore);
      this.labelsChanged();
      this.initCounts();

      function isReadonlyLabel(labelKey) {
        return _constants.default.LABEL_PREFIX_TO_IGNORE.find(function (L) {
          return labelKey !== _constants.default.LABEL_CATTLE_CLUSTER_AGENT && labelKey.startsWith(L);
        });
      }
    },
    initCounts: function initCounts() {
      var labels = this.get('model.labels');
      var annotations = this.get('model.annotations');
      this.set('labelsCount', labels && Object.keys(labels).length || 0);
      this.set('annotationsCount', annotations && Object.keys(annotations).length || 0);
    },
    updateLabels: function updateLabels(labels) {
      if (this.setLabels) {
        this.setLabels(labels);
      } else if (Ember.get(this, 'editing')) {
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });
        Ember.set(this, 'model.labels', out);
      }

      this.validate();
    },
    validate: function validate() {
      var errors = []; //    (this.get('labelArray')||[]).forEach((obj) => {
      //    });

      Ember.set(this, 'errors', errors);
    }
  });

  _exports.default = _default;
});