define("shared/components/shortcut-key/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zeO/hKUG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"m-5\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"mr-10\"],[12],[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"code\"],[12],[1,[30,[36,3],[[30,[36,2],[\"modalShortcuts.\",[35,1]],null]],[[\"key\"],[[35,0]]]]],[13]],\"parameters\":[]},{\"statements\":[[10,\"code\"],[12],[1,[34,0]],[13]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[1,[30,[36,3],[[35,5]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"key\",\"modifier\",\"concat\",\"t\",\"if\",\"label\"]}",
    "meta": {
      "moduleName": "shared/components/shortcut-key/template.hbs"
    }
  });

  _exports.default = _default;
});