define("global-admin/global-dns/providers/new/controller", ["exports", "shared/mixins/view-new-edit"], function (_exports, _viewNewEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DNS_PROVIDERS = ['route53', 'alidns', 'cloudflare'];

  var _default = Ember.Controller.extend(_viewNewEdit.default, {
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    queryParams: ['id', 'activeProvider'],
    memberAccessTypes: ['owner', 'read-only'],
    id: null,
    activeProvider: 'route53',
    mode: 'new',
    saveDisabled: false,
    config: Ember.computed.alias('model'),
    primaryResource: Ember.computed.alias('config'),
    actions: {
      switchProvider: function switchProvider(provider) {
        Ember.set(this, 'activeProvider', provider);
      },
      cancel: function cancel() {
        this.router.transitionTo('global-admin.global-dns.providers.index');
      },
      addAuthorizedPrincipal: function addAuthorizedPrincipal(principal) {
        if (principal) {
          var _this$model$members = this.model.members,
              members = _this$model$members === void 0 ? [] : _this$model$members;

          if (!members) {
            members = [];
          }

          Ember.set(principal, 'accessType', 'owner');
          members.pushObject(this.globalStore.createRecord(principal));
          Ember.set(this, 'model.members', members);
        }
      },
      removeMember: function removeMember(member) {
        var members = this.model.members;
        members.removeObject(member);
      }
    },
    additionalOptions: Ember.computed('activeProvider', 'config.route53.additionalOptions', 'config.cloudflare.additionalOptions', 'config.alidns.additionalOptions', {
      get: function get() {
        var activeProvider = this.activeProvider,
            config = this.config;
        var providerConfig = Ember.get(config, "".concat(activeProvider, "ProviderConfig"));
        return Ember.get(providerConfig, 'additionalOptions') || {};
      },
      set: function set(key, value) {
        Ember.set(this, "config.".concat(this.activeProvider, "ProviderConfig.additionalOptions"), value);
        return value;
      }
    }),
    availableProviders: Ember.computed('activeProvider', 'isEdit', function () {
      if (Ember.get(this, 'isEdit')) {
        return [{
          name: Ember.get(this, 'activeProvider')
        }];
      } else {
        return DNS_PROVIDERS.map(function (p) {
          return {
            name: p
          };
        });
      }
    }),
    validate: function validate() {
      var providerConfig = Ember.get(this, "config.".concat(this.activeProvider, "ProviderConfig"));
      var mode = this.mode;

      if (mode === 'edit' && providerConfig && providerConfig.hasOwnProperty('secretKey')) {
        if (providerConfig.secretKey === '' || providerConfig.secretKey === null) {
          delete providerConfig.secretKey;
        }
      }

      return this._super.apply(this, arguments);
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });

  _exports.default = _default;
});