define("global-admin/components/modal-revert-setting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Lt2c/QG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"modalRevertSettings.title\"],[[\"setting\"],[[35,0,[\"id\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"section\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"modal-content row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"modalRevertSettings.current\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[35,0,[\"value\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"modalRevertSettings.default\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[35,0,[\"default\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,3],null,[[\"errors\"],[[35,2]]]]],[2,\"\\n\"],[1,[30,[36,5],null,[[\"save\",\"cancel\",\"createLabel\"],[[30,[36,4],[[32,0],\"save\"],null],[30,[36,4],[[32,0],\"done\"],null],\"generic.save\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"setting\",\"t\",\"errors\",\"top-errors\",\"action\",\"save-cancel\"]}",
    "meta": {
      "moduleName": "global-admin/components/modal-revert-setting/template.hbs"
    }
  });

  _exports.default = _default;
});