define("shared/components/cluster-driver/driver-import/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-import/template"], function (_exports, _clusterDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'importedConfig',
    step: 1,
    nodeForInfo: null,
    isEdit: Ember.computed.equal('mode', 'edit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    isK3sCluster: Ember.computed.equal('model.cluster.driver', 'k3s'),
    isRke2Cluster: Ember.computed.equal('model.cluster.driver', 'rke2'),
    nodes: Ember.computed.reads('model.cluster.masterNodes'),
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'isEdit') && Ember.get(this, 'clusterState') === 'pending') {
        this.loadToken();
      }

      if (Ember.get(this, 'isEdit')) {
        if (this.isK3sCluster) {
          Ember.set(this, 'configField', 'k3sConfig');

          if (this.model.cluster.masterNodes.length === 1) {
            Ember.set(this, 'nodeForInfo', this.model.cluster.masterNodes.firstObject);
          }

          if (Ember.isEmpty(this.model.cluster.k3sConfig)) {
            Ember.set(this, 'model.cluster.k3sConfig', this.globalStore.createRecord({
              type: 'k3sConfig',
              kubernetesVersion: this.cluster.version.gitVersion
            }));
          }
        } else if (this.isRke2Cluster) {
          Ember.set(this, 'configField', 'rke2Config');

          if (this.model.cluster.masterNodes.length === 1) {
            Ember.set(this, 'nodeForInfo', this.model.cluster.masterNodes.firstObject);
          }

          if (Ember.isEmpty(this.model.cluster.rke2Config)) {
            Ember.set(this, 'model.cluster.rke2Config', this.globalStore.createRecord({
              type: 'rke2Config',
              kubernetesVersion: this.cluster.version.gitVersion
            }));
          }
        }
      }
    },
    actions: {
      setActiveNodeForInfo: function setActiveNodeForInfo(selection) {
        var node = selection ? this.nodes.findBy('id', selection.id) : null;
        Ember.set(this, 'nodeForInfo', node);
      }
    },
    clusterChanged: Ember.observer('originalCluster.state', function () {
      if (Ember.get(this, 'step') >= 2) {
        var state = Ember.get(this, 'originalCluster.state');

        if (!['pending', 'initializing', 'active'].includes(state)) {
          if (this.close) {
            this.close();
          }
        }
      }
    }),
    nodeInfoId: Ember.computed({
      get: function get() {
        var nodeForInfo = this.nodeForInfo;

        if (Ember.isEmpty(nodeForInfo)) {
          return null;
        } else {
          return nodeForInfo.id;
        }
      },
      set: function set(key, value) {
        var nodeForInfo = this.nodeForInfo;

        if (!Ember.isEmpty(nodeForInfo)) {
          Ember.set(nodeForInfo, 'id', value);
        }

        return value;
      }
    }),
    nodesOptions: Ember.computed('nodes.@each.state', function () {
      return this.nodes.map(function (node) {
        return {
          id: node.id,
          displayName: node.displayName
        };
      });
    }),
    willSave: function willSave() {
      var field = this.configField,
          config = this.config;
      var errors = [];

      if (field === 'k3sConfig' || field === 'rke2Config') {
        if (config.k3supgradeStrategy) {
          // doesn't work because missing deep validation
          // errors = config.k3supgradeStrategy.validationErrors();
          if (config.k3supgradeStrategy.serverConcurrency <= 0 || config.k3supgradeStrategy.workerConcurrency <= 0) {
            errors.push(this.intl.t('clusterNew.k3simport.errors.concurrency.negative'));
          }
        }

        if (config.rke2upgradeStrategy) {}
      }

      if (!Ember.isEmpty(errors)) {
        Ember.set(this, 'errors', errors);
        return false;
      }

      return this._super();
    },
    doneSaving: function doneSaving() {
      if (Ember.get(this, 'isEdit')) {
        if (this.close) {
          this.close();
        }
      } else {
        return this.loadToken();
      }
    },
    loadToken: function loadToken() {
      Ember.set(this, 'step', 2);
    }
  });

  _exports.default = _default;
});