define("liquid-fire/templates/components/illiquid-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E4X8aymY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,0]]]],\"hasEval\":false,\"upvars\":[\"_fixedModel\"]}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/illiquid-model.hbs"
    }
  });

  _exports.default = _default;
});