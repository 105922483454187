define("shared/components/copy-inline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6jSLZOu/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,7],null,[[\"type\",\"model\",\"tooltipTemplate\",\"class\",\"textChangedEvent\",\"baseClass\",\"isCopyTo\",\"tooltipFor\"],[\"tooltip-basic\",[30,[36,6],[[30,[36,5],[[35,4],[35,4,[\"tooltipText\"]],\"generic.emptyString\"],null]],null],\"tooltip-static\",\"copy-button-container\",[35,3],\"copy-clipboard\",true,\"copyToClipboard\"]],[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"tagName\",\"class\",\"clipboardTarget\",\"clipboardText\",\"success\"],[\"span\",\"hand bg-transparent\",[35,1],[35,0],\"alertSuccess\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"clipboardText\",\"target\",\"copy-button\",\"textChangedEvent\",\"model\",\"if\",\"t\",\"tooltip-element\",\"isSupported\"]}",
    "meta": {
      "moduleName": "shared/components/copy-inline/template.hbs"
    }
  });

  _exports.default = _default;
});