define("shared/components/marked-down/component", ["exports", "shared/components/marked-down/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Marked = null;
  var DOMPurify = null;

  var _default = Ember.Component.extend({
    layout: _template.default,
    markdown: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!Marked) {
        emberAutoImportDynamic("marked").then(function (module) {
          Marked = module.default;
          emberAutoImportDynamic("dompurify").then(function (module) {
            DOMPurify = module.default;

            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }

            Ember.run.next(function () {
              return _this.notifyPropertyChange('parsedMarkdown');
            });
          });
        });
      }
    },
    parsedMarkdown: Ember.computed('markdown', function () {
      if (!Marked) {
        return '';
      }

      var html = Marked(Ember.get(this, 'markdown'), {
        breaks: true
      });
      return Ember.String.htmlSafe(DOMPurify.sanitize(html));
    })
  });

  _exports.default = _default;
});