define("shared/components/cru-cloud-provider/component", ["exports", "shared/components/cru-cloud-provider/template", "ui/utils/constants", "shared/components/cru-cloud-provider/cloud-provider-info", "ui/utils/debounce"], function (_exports, _template, _constants, _cloudProviderInfo, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var azureDefaults = _constants.default.AZURE_DEFAULTS;
  var GENERIC_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.cloudConfig';
  var AWS_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.awsCloudProvider';
  var AZURE_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.azureCloudProvider';

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    configType: null,
    cluster: null,
    driver: null,
    selectedCloudProvider: 'none',
    mode: 'new',
    hasBuiltIn: false,
    configAnswers: null,
    clusterTemplateCreate: false,
    configVariable: null,
    questions: null,
    azureDefaults: azureDefaults,
    azureDescriptions: _cloudProviderInfo.azure,
    configName: Ember.computed.alias('cluster.rancherKubernetesEngineConfig.cloudProvider.name'),
    init: function init() {
      this._super.apply(this, arguments);

      var cloudProviderName = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.cloudProvider.name');

      if (cloudProviderName === 'aws') {
        Ember.setProperties(this, {
          selectedCloudProvider: 'amazonec2',
          configAnswers: Ember.get(this, AWS_PATH)
        });
      } else if (cloudProviderName === 'azure') {
        var reorderedAnswers = this.sortAzureFields(this.globalStore.getById('schema', 'azurecloudprovider'), Ember.get(this, AZURE_PATH));
        this.setCpFields("azureCloudProvider", reorderedAnswers);
        Ember.setProperties(this, {
          selectedCloudProvider: 'azure',
          configAnswers: reorderedAnswers
        });
      } else if (!cloudProviderName) {
        Ember.set(this, 'selectedCloudProvider', 'none');
      } else {
        Ember.setProperties(this, {
          selectedCloudProvider: 'generic',
          configAnswers: Ember.get(this, GENERIC_PATH)
        });
      }
    },
    driverDidChange: Ember.observer('driver', function () {
      Ember.set(this, 'selectedCloudProvider', 'none');
    }),
    modeChanged: Ember.observer('selectedCloudProvider', function () {
      var selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');

      if (selectedCloudProvider !== 'none') {
        this.constructConfig();
      } else {
        var config = Ember.get(this, 'cluster.rancherKubernetesEngineConfig');

        if (config && Ember.get(config, 'cloudProvider')) {
          delete config.cloudProvider;
        }
      }
    }),
    configAnswersDidChange: (0, _debounce.debouncedObserver)('mappedConfigAnswers.@each.{key,value}', function () {
      var mappedAnswers = Ember.get(this, 'mappedConfigAnswers');
      var selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      var configAnswersOut = {};
      var pathForSet;

      switch (selectedCloudProvider) {
        case 'azure':
          pathForSet = AZURE_PATH;
          break;

        case 'amazonec2':
          pathForSet = AWS_PATH;
          break;

        default:
          pathForSet = GENERIC_PATH;
          break;
      }

      mappedAnswers.forEach(function (answer) {
        Ember.set(configAnswersOut, answer.key, answer.value);
      });
      Ember.set(this, pathForSet, configAnswersOut);
    }),
    selectedCloudProviderOverrideAvailable: Ember.computed('applyClusterTemplate', 'clusterTemplateCreate', 'clusterTemplateRevision.{id,questions}', 'configName', 'selectedCloudProvider', 'isDestroying', 'isDestroyed', function () {
      var _this = this;

      var clusterTemplateRevision = this.clusterTemplateRevision,
          applyClusterTemplate = this.applyClusterTemplate;

      if (applyClusterTemplate && clusterTemplateRevision) {
        if (clusterTemplateRevision.questions) {
          var found = clusterTemplateRevision.questions.filter(function (ctr) {
            return ctr.variable.includes('rancherKubernetesEngineConfig.cloudProvider');
          });

          if (found.length === 0 && this.selectedCloudProvider !== 'none') {
            Ember.set(this, 'selectedCloudProvider', 'none');
          }

          return found.length >= 1;
        } else {
          if (this.configName) {
            Ember.run.next(function () {
              if (_this.isDestroyed || _this.isDestroying) {
                return;
              }

              Ember.set(_this, 'selectedCloudProvider', _this.configName);
            });
          }
        }
      } else {
        Ember.run.next(function () {
          if (!_this.configName) {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }

            Ember.set(_this, 'selectedCloudProvider', _this.selectedCloudProvider === 'generic' ? 'generic' : 'none');
          }
        });
      }

      return false;
    }),
    isCreateClusterOrClusterTemplate: Ember.computed('applyClusterTemplate', function () {
      var applyClusterTemplate = this.applyClusterTemplate;

      if (applyClusterTemplate) {
        return false;
      } else {
        return true;
      }
    }),
    mappedConfigAnswers: Ember.computed('configAnswers', function () {
      var configAnswers = Ember.get(this, 'configAnswers') || {};
      var out = [];
      Object.keys(configAnswers).forEach(function (answerKey) {
        out.push({
          key: answerKey,
          value: configAnswers[answerKey]
        });
      });
      return out;
    }),
    showVsphereHelperText: Ember.computed('selectedCloudProvider', 'driver', function () {
      var driver = Ember.get(this, 'driver');
      var selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      return (driver === 'custom' || driver === 'vmwarevsphere') && selectedCloudProvider === 'external';
    }),
    checkDefaults: function checkDefaults(record) {
      Ember.get(this, 'azureDefaults').forEach(function (def) {
        if (Ember.isEmpty(record[def])) {
          Ember.set(record, def, null);
        }
      });
    },
    sortAzureFields: function sortAzureFields(schema, answers) {
      var schemaFields = schema.getFieldNames();
      var resourceFields = Ember.get(schema, 'resourceFields');
      var descriptionInfo = _cloudProviderInfo.azure;
      var requiredFields = schemaFields.filter(function (item) {
        return Ember.get(descriptionInfo, "".concat(item, ".required"));
      }).sort();
      var keysWithoutFields = schemaFields.filter(function (item) {
        return !requiredFields.includes(item);
      }).sort();
      var prioritizedKeys = keysWithoutFields.unshiftObjects(requiredFields);
      var reorderedFields = {}; // Hack the schema to be required so validation will require them

      requiredFields.forEach(function (key) {
        schema.resourceFields[key].required = true;
      });
      prioritizedKeys.forEach(function (key) {
        var resourceField = Ember.get(resourceFields, key);

        if (answers.hasOwnProperty(key)) {
          Ember.set(reorderedFields, key, Ember.get(answers, key));
        } else {
          if (Ember.get(resourceField, 'type') === 'password') {
            Ember.set(reorderedFields, key, null);
          }
        }
      });
      return reorderedFields;
    },
    constructConfig: function constructConfig() {
      var nue = {};
      var selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      var cluster = Ember.get(this, 'cluster');
      var config = Ember.get(cluster, 'rancherKubernetesEngineConfig') || Ember.set(cluster, 'rancherKubernetesEngineConfig', {});

      switch (selectedCloudProvider) {
        case 'azure':
          nue = Ember.get(this, 'globalStore').createRecord({
            type: 'azureCloudProvider'
          });
          delete nue.type;
          this.checkDefaults(nue);
          nue = this.sortAzureFields(this.globalStore.getById('schema', 'azurecloudprovider'), nue);
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'azure',
            azureCloudProvider: nue
          }));
          Ember.setProperties(this, {
            hasBuiltIn: true,
            'cluster.rancherKubernetesEngineConfig': config,
            configAnswers: nue
          });
          break;

        case 'amazonec2':
          nue = Ember.get(this, 'globalStore').createRecord({
            type: 'awsCloudProvider'
          });
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'aws',
            awsCloudProvider: nue
          }));
          Ember.set(this, 'configAnswers', nue);
          break;

        case 'external':
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'external'
          }));
          break;

        default:
          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            cloudConfig: nue
          }));
          Ember.set(this, 'configAnswers', nue);
          break;
      }
    },
    setCpFields: function setCpFields() {
      throw new Error('setCpFields action is required!');
    },
    addOverride: function addOverride() {
      throw new Error('addOverride action is required!');
    }
  });

  _exports.default = _default;
});