define("shared/components/custom-command/component", ["exports", "shared/components/custom-command/template", "shared/mixins/manage-labels", "@rancher/ember-api-store/utils/validate", "shared/utils/util"], function (_exports, _template, _manageLabels, _validate, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageLabels.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    address: null,
    internalAddress: null,
    nodeName: null,
    labels: null,
    token: null,
    nodeNameErrors: null,
    isLinux: true,
    etcd: false,
    controlplane: false,
    worker: true,
    commandAdvanced: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        labels: {},
        nodeNameErrors: [],
        taints: []
      });
    },
    actions: {
      setTaints: function setTaints(taints) {
        Ember.set(this, 'taints', taints);
      },
      setLabels: function setLabels(labels) {
        Ember.set(this, 'labels', labels);
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });
        this.set('labels', out);
      },
      expand: function expand(item) {
        item.toggleProperty('expanded');
      }
    },
    isAddressValid: Ember.computed('address.length', function () {
      var address = this.address;
      return Ember.isEmpty(address) || (0, _util.validateEndpoint)(Ember.get(this, 'address'));
    }),
    isInternalAddressValid: Ember.computed('internalAddress.length', function () {
      var internalAddress = this.internalAddress;
      return Ember.isEmpty(internalAddress) || (0, _util.validateEndpoint)(Ember.get(this, 'internalAddress'));
    }),
    isNodeNameValid: Ember.computed('nodeName', function () {
      var nodeName = (Ember.get(this, 'nodeName') || '').toLowerCase();

      if (Ember.get(nodeName, 'length') === 0) {
        return true;
      } else {
        var errors = (0, _validate.validateHostname)(nodeName, 'Node Name', Ember.get(this, 'intl'), {
          restricted: true
        });
        Ember.set(this, 'nodeNameErrors', errors);
        return errors.length === 0;
      }
    }),
    command: Ember.computed('taints', 'labels', 'token.{nodeCommand,windowsNodeCommand}', 'etcd', 'controlplane', 'worker', 'address', 'internalAddress', 'nodeName', 'isLinux', function () {
      var _this = this;

      var out = Ember.get(this, 'token.nodeCommand');

      if (!out) {
        return;
      }

      var address = this.address,
          internalAddress = this.internalAddress,
          isLinux = this.isLinux,
          labels = this.labels,
          nodeName = this.nodeName,
          taints = this.taints;
      var roles = ['etcd', 'controlplane', 'worker'];
      var windowsSelected = !isLinux;
      var windowsCmdPostfix = " | iex}\"";

      if (windowsSelected) {
        Ember.run.next(function () {
          Ember.setProperties(_this, {
            etcd: false,
            controlplane: false
          });
        });
        out = (Ember.get(this, 'token.windowsNodeCommand') || '').replace('--isolation hyperv ', '').replace(windowsCmdPostfix, '');
      }

      if (nodeName) {
        out += " --node-name ".concat(nodeName.toLowerCase());
      }

      if (address) {
        out += " --address ".concat(address);
      }

      if (internalAddress) {
        out += " --internal-address ".concat(internalAddress);
      }

      for (var i = 0, k; i < roles.length; i++) {
        k = roles[i];

        if (Ember.get(this, k)) {
          out += " --".concat(k);
        }
      }

      Object.keys(labels).forEach(function (key) {
        out += " --label ".concat(key, "=").concat(labels[key]);
      });
      taints.forEach(function (taint) {
        out += " --taints ".concat(Ember.get(taint, 'key'), "=").concat(Ember.get(taint, 'value'), ":").concat(Ember.get(taint, 'effect'));
      });

      if (windowsSelected) {
        out += windowsCmdPostfix;
      }

      return out;
    })
  });

  _exports.default = _default;
});