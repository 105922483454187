define("shared/components/managed-import-cluster-info/component", ["exports", "shared/components/managed-import-cluster-info/template", "semver", "shared/utils/sort"], function (_exports, _template, _semver, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    releaseVersions: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    configField: 'k3sConfig',
    config: Ember.computed('cluster.{k3sConfig,rke2Config}', 'configField', function () {
      return Ember.get(this, "cluster.".concat(this.configField));
    }),
    upgradeStrategy: Ember.computed('config.{k3supgradeStrategy,rke2upgradeStrategy}', 'configField', function () {
      var configField = this.configField;
      var upgradeStrategyPath = configField === 'k3sConfig' ? 'k3supgradeStrategy' : 'rke2upgradeStrategy';
      return Ember.get(this.config, upgradeStrategyPath);
    }),
    serverConcurrency: Ember.computed('upgradeStrategy.serverConcurrency', {
      get: function get() {
        return Ember.get(this, 'upgradeStrategy.serverConcurrency');
      },
      set: function set(key, value) {
        Ember.set(this, 'upgradeStrategy.serverConcurrency', this.coerceToInt(value));
        return value;
      }
    }),
    workerConcurrency: Ember.computed('upgradeStrategy.workerConcurrency', {
      get: function get() {
        return Ember.get(this, 'upgradeStrategy.workerConcurrency');
      },
      set: function set(key, value) {
        Ember.set(this, 'upgradeStrategy.workerConcurrency', this.coerceToInt(value));
        return value;
      }
    }),
    allVersions: Ember.computed('releaseVersions.allVersions.[]', function () {
      var currentVersion = Ember.get(this, "config.kubernetesVersion");
      var versionsMapped = [];
      var allVersions = this.releaseVersions.allVersions || [];

      if (!allVersions.includes(currentVersion)) {
        allVersions.unshift(currentVersion);
      }

      allVersions = _toConsumableArray((0, _sort.sortVersions)(allVersions).reverse());
      allVersions.forEach(function (v) {
        if (_semver.default.gte(v, currentVersion)) {
          versionsMapped.pushObject({
            value: v,
            label: v
          });
        }
      });
      return versionsMapped;
    }),
    coerceToInt: function coerceToInt(value) {
      var errors;
      var coerced = value;
      errors = Ember.set(this, 'errors', []);
      coerced = parseInt(value, 10);

      if (isNaN(coerced)) {
        errors.push(this.intl.t('managedImportClusterInfo.error.int'));
        Ember.set(this, 'errors', errors);
        return value;
      }

      return coerced;
    }
  });

  _exports.default = _default;
});