define("shared/components/storage-class/provisioner-azure-disk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WhKSHCFJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row mb-20\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n      \"],[1,[30,[36,5],[[30,[36,4],[\"cruStorageClass.\",[35,3],\".storageaccounttype.label\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,9],null,[[\"editable\",\"value\"],[[35,8],[35,6]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,7],null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[35,6],\"form-control\",[30,[36,5],[[30,[36,4],[\"cruStorageClass.\",[35,3],\".storageaccounttype.placeholder\"],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n      \"],[1,[30,[36,5],[[30,[36,4],[\"cruStorageClass.\",[35,3],\".kind.label\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,10],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"classNames\",\"content\",\"value\",\"optionLabelPath\",\"localizedLabel\"],[\"form-control\",[35,1],[35,0],\"translationKey\",true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"text-muted\"],[12],[2,\"\\n        \"],[1,[34,0]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"kind\",\"kindOptions\",\"new-select\",\"provisioner\",\"concat\",\"t\",\"storageaccounttype\",\"input\",\"editing\",\"input-or-display\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-azure-disk/template.hbs"
    }
  });

  _exports.default = _default;
});