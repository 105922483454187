define("shared/mixins/store-tweaks", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create({
    cookies: Ember.inject.service(),
    defaultPageSize: -1,
    removeAfterDelete: false,

    get headers() {
      var _out;

      var out = (_out = {}, _defineProperty(_out, _constants.default.HEADER.ACTIONS, _constants.default.HEADER.ACTIONS_VALUE), _defineProperty(_out, _constants.default.HEADER.NO_CHALLENGE, _constants.default.HEADER.NO_CHALLENGE_VALUE), _out);
      var csrf = this.get("cookies.".concat(_constants.default.COOKIE.CSRF));

      if (csrf) {
        out[_constants.default.HEADER.CSRF] = csrf;
      }

      return out;
    }

  });

  _exports.default = _default;
});