define("nodes/custom-drivers/node-drivers/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }];

  var _default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    headers: HEADERS,
    actions: {
      addNewDriver: function addNewDriver() {
        var newDriver = this.get('globalStore').createRecord({
          type: 'nodeDriver',
          name: null,
          description: null,
          checksum: null,
          url: null,
          active: true
        });
        this.get('modalService').toggleModal('modal-edit-driver', newDriver);
      },
      addCatalogDriver: function addCatalogDriver(driver) {
        var _this = this;

        this.get('globalStore').request({
          url: "".concat(this.get('app.catalogEndpoint'), "/templates/").concat(driver.id)
        }).then(function (template) {
          _this.get('globalStore').request({
            url: template.versionLinks[template.defaultVersion]
          }).then(function (driver) {
            var newDriver = _this.createNewDriver(driver);

            _this.get('globalStore').createRecord(newDriver).save().catch(function (err) {
              _this.get('growl').fromError(err);
            });
          });
        });
      },
      upgradeDriver: function upgradeDriver(driver, version, url) {
        var _this2 = this;

        this.get('globalStore').request({
          url: url
        }).then(function (template) {
          driver.setProperties(_this2.createNewDriver(template));
          driver.save().catch(function (err) {
            _this2.get('growl').fromError(err);
          });
        });
      }
    },
    rows: Ember.computed('model.drivers.@each.{externalId,id,state,version}', 'model.drivers.content', function () {
      // possibly add some search here
      var drivers = this.get('model.drivers.content');
      return drivers;
    }),
    createNewDriver: function createNewDriver(driver) {
      return {
        type: 'nodeDriver',
        description: driver.description || null,
        checksum: (driver.files.checksum || '').trim() || null,
        uiUrl: (driver.files['ui-url'] || driver.files.uiUrl || '').trim() || null,
        url: (driver.files.url || '').trim() || null,
        externalId: _constants.default.EXTERNAL_ID.KIND_CATALOG + _constants.default.EXTERNAL_ID.KIND_SEPARATOR + driver.id,
        active: true
      };
    }
  });

  _exports.default = _default;
});