define("shared/components/settings-overridable-banner/component", ["exports", "shared/components/settings-overridable-banner/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    classNameBindings: ['show::hide'],
    show: false,
    backgroundColor: null,
    textColor: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this$model = this.model,
          model = _this$model === void 0 ? {} : _this$model;
      var background = model.background,
          textColor = model.textColor,
          bannerText = model.text;

      if (background) {
        (0, _jquery.default)(this.element).css({
          'background-color': background
        });
      }

      if (textColor) {
        (0, _jquery.default)(this.element).css({
          color: textColor
        });
      }

      if (bannerText) {
        Ember.set(this, 'bannerText', bannerText);
      }
    },
    bannerText: Ember.computed('model.text', function () {
      var _this$model2 = this.model,
          model = _this$model2 === void 0 ? {} : _this$model2;
      var _model$text = model.text,
          text = _model$text === void 0 ? '' : _model$text;
      return Ember.String.htmlSafe(text);
    })
  });

  _exports.default = _default;
});