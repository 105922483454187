define("shared/components/schema/input-certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oriSs0Dq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"icon icon-spinner icon-spin\"],[12],[13],[2,\" \"],[1,[30,[36,3],[\"generic.loading\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"classNames\",\"content\",\"prompt\",\"localizedPrompt\",\"optionLabelPath\",\"optionValuePath\",\"value\"],[\"form-control\",[35,1],\"schema.inputCertificate.prompt\",true,\"displayDetailedName\",\"name\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"choices\",\"new-select\",\"t\",\"loading\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/schema/input-certificate/template.hbs"
    }
  });

  _exports.default = _default;
});