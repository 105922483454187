define("shared/components/form-namespace/component", ["exports", "shared/components/form-namespace/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REUSE = 'reuse';
  var CREATE = 'create';

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    layout: _template.default,
    createLabel: 'formNamespace.label.create',
    reuseLabel: 'formNamespace.label.reuse',
    // Outputs
    namespace: null,
    errors: null,
    reuseNamespaceId: null,
    createNamespace: null,
    mode: REUSE,
    editing: true,
    required: true,
    allowCreate: true,
    hookName: 'saveNamespace',
    classNames: ['inline-form'],
    choices: null,
    isReuse: Ember.computed.equal('mode', REUSE),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.registerHook) {
        this.registerHook(this.saveNamespace.bind(this), {
          name: Ember.get(this, 'hookName'),
          key: '_beforeSaveHooks'
        });
      }

      var currentProjectsNamespaces = Ember.get(this, 'clusterStore').all('namespace').filterBy('projectId', Ember.get(this, 'scope.currentProject.id'));
      Ember.set(this, 'choices', currentProjectsNamespaces).sortBy('displayName');
      var all = Ember.get(this, 'choices');
      Ember.set(this, 'createNamespace', Ember.get(this, 'clusterStore').createRecord({
        type: 'namespace',
        name: '',
        projectId: Ember.get(this, 'scope.currentProject.id'),
        containerDefaultResourceLimit: Ember.get(this, 'scope.currentProject.containerDefaultResourceLimit')
      }));

      if (Ember.get(this, 'mode') === REUSE) {
        var namespace = Ember.get(this, 'namespace') || // Passed in
        all.findBy('isDefault', true) || // The default one
        all.objectAt(0); // Ok any one

        if (namespace && namespace.id) {
          Ember.set(this, 'reuseNamespaceId', Ember.get(namespace, 'id'));
        } else if (namespace) {
          Ember.set(this, 'createNamespace', namespace);
          Ember.set(this, 'mode', CREATE);
          return;
        } else {
          Ember.run.next(function () {
            Ember.set(_this, 'mode', CREATE);
          });
        }

        Ember.run.next(function () {
          _this.updateNamespace();
        });
      }
    },
    actions: {
      toggle: function toggle() {
        var mode = Ember.get(this, 'mode') === REUSE ? CREATE : REUSE;
        Ember.set(this, 'mode', mode);

        if (mode === CREATE) {
          Ember.run.next(function () {
            var elem = (0, _jquery.default)('.new-name')[0];

            if (elem) {
              elem.focus();
              elem.select();
            }
          });
        }
      }
    },
    updateNamespace: Ember.observer('reuseNamespaceId', 'mode', function () {
      var namespace;

      if (Ember.get(this, 'mode') === REUSE) {
        namespace = Ember.get(this, 'choices').findBy('id', Ember.get(this, 'reuseNamespaceId'));
      }

      if (!namespace) {
        namespace = Ember.get(this, 'createNamespace');
      }

      Ember.set(this, 'namespace', namespace);
    }),
    validate: Ember.observer('namespace.{id,name}', function () {
      var intl = Ember.get(this, 'intl');
      var errors = [];
      var namespace = Ember.get(this, 'namespace');

      if (namespace && Ember.get(namespace, 'name')) {
        namespace.validationErrors().forEach(function (err) {
          errors.push(intl.t('formNamespace.errors.validation', {
            error: err
          }));
        });
      } else {
        errors.push(intl.t('validation.required', {
          key: intl.t('generic.namespace')
        }));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors);
      } else {
        Ember.set(this, 'errors', null);
      }
    }),
    saveNamespace: function saveNamespace() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (Ember.get(this, 'isReuse')) {
        return Ember.RSVP.resolve();
      } else if (Ember.get(this, 'errors.length')) {
        return Ember.RSVP.reject();
      }

      var namespace = Ember.get(this, 'namespace');
      return namespace.save().then(function (newNamespace) {
        return newNamespace.waitForState('active');
      }).catch(function (err) {
        Ember.set(_this2, 'errors', [err.message]);
        return Ember.RSVP.reject(err);
      });
    }
  });

  _exports.default = _default;
});