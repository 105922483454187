define("shared/components/modal-istio-yaml/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bQzcxpLK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"modal-yaml\"],[12],[2,\"\\n  \"],[10,\"section\"],[14,0,\"header clearfix\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,1],[\"modalYaml.title.view\"],[[\"name\"],[[35,6]]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"classNames\",\"content\",\"value\"],[\"form-control\",[35,7],[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[35,4],[35,3]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"canChangeName\",\"value\",\"loading\",\"autoResize\",\"readOnly\",\"canUpload\",\"showUpload\",\"showDownload\",\"filename\"],[false,[35,4],[35,3],true,true,false,false,false,[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row mt-20\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center text-muted\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"modalYaml.noData\"],[[\"name\"],[[35,0]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,11],null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[35,4],\"with-clip\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,5,\"text-align: center\"],[14,0,\"mt-20 text-center\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-transparent\"],[24,4,\"button\"],[4,[38,12],[[32,0],\"cancel\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"generic.cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedType\",\"t\",\"filename\",\"loading\",\"yaml\",\"input-yaml\",\"name\",\"options\",\"new-select\",\"or\",\"if\",\"copy-to-clipboard\",\"action\"]}",
    "meta": {
      "moduleName": "shared/components/modal-istio-yaml/template.hbs"
    }
  });

  _exports.default = _default;
});