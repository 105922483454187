define("shared/components/language-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7uFSGF+S",
    "block": "{\"symbols\":[\"dd\",\"label\",\"lang\"],\"statements\":[[6,[37,12],null,[[\"verticalPosition\",\"horizontalPosition\",\"renderInPlace\"],[[35,11],[35,10],true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1,[\"Trigger\"]],[[24,0,\"hand btn btn-sm bg-transparent lang-select\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"icon icon-globe\"],[12],[13],[2,\" \"],[1,[34,5]],[2,\" \"],[10,\"i\"],[14,0,\"icon icon-chevron-down\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Content\"]],[[24,0,\"text-right lang-select\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,6,[\"isRancher\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"li\"],[4,[38,3],[[32,0],[32,1,[\"actions\",\"close\"]]],null],[12],[2,\"\\n        \"],[10,\"a\"],[14,\"role\",\"button\"],[14,6,\"http://translate.rancher.com\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[12],[2,\"\\n          \"],[1,[30,[36,4],[\"languageContribute\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[14,0,\"divider\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,9],[[30,[36,8],[[35,7]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"li\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[[35,0],[32,3]],null],\"disabled selected\"],null]]]],[4,[38,3],[[32,0],[32,1,[\"actions\",\"close\"]]],null],[12],[2,\"\\n        \"],[11,\"a\"],[24,\"role\",\"button\"],[24,6,\"#\"],[4,[38,3],[[32,0],\"selectLanguage\",[32,3]],null],[12],[2,\"\\n          \"],[1,[32,2]],[2,\" (\"],[1,[32,3]],[2,\")\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"selected\",\"eq\",\"if\",\"action\",\"t\",\"selectedLabel\",\"settings\",\"locales\",\"-each-in\",\"each\",\"dropdownHoriPosition\",\"dropdownVertPosition\",\"hover-dropdown\"]}",
    "meta": {
      "moduleName": "shared/components/language-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});