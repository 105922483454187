define("shared/components/storage-class/provisioner-rbd/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JnR5ar61",
    "block": "{\"symbols\":[\"fields\",\"field\"],\"statements\":[[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,10]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row mb-20\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,2],[[30,[36,1],[\"cruStorageClass.\",[35,0],\".\",[32,2],\".label\"],null]],null]],[13],[2,\"\\n\"],[6,[37,7],null,[[\"editable\",\"value\"],[[35,6],[30,[36,4],[[35,3],[32,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,5],null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[30,[36,4],[[35,3],[32,2]],null],\"form-control\",[30,[36,2],[[30,[36,1],[\"cruStorageClass.\",[35,0],\".\",[32,2],\".placeholder\"],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"provisioner\",\"concat\",\"t\",\"model\",\"get\",\"input\",\"editing\",\"input-or-display\",\"-track-array\",\"each\",\"fieldsGroup\"]}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-rbd/template.hbs"
    }
  });

  _exports.default = _default;
});