define("global-admin/mixins/authentication", ["exports", "ui/utils/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    isEnabled: Ember.computed('authConfig.enabled', 'testing', function () {
      var testing = Ember.get(this, 'testing');
      var enabled = Ember.get(this, 'authConfig.enabled');
      return enabled && !testing;
    }),
    editing: false,
    authConfig: null,
    actions: {
      cancel: function cancel() {
        if (this.editing) {
          Ember.set(this, 'editing', false);
        }

        this.router.transitionTo('global-admin.security.authentication');
      },
      edit: function edit() {
        Ember.set(this, 'editing', true);
      },
      waitAndRefresh: function waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show(); // eslint-disable-line

        setTimeout(function () {
          window.location.href = url || window.location.href;
        }, 1000);
      },
      gotError: function gotError(err) {
        if (err.message) {
          this.send('showError', err.message + (err.detail ? "(".concat(err.detail, ")") : ''));
        } else {
          this.send('showError', "Error (".concat(err.status, " - ").concat(err.code, ")"));
        }

        Ember.setProperties(this, {
          testing: false,
          saving: false
        });
      },
      showError: function showError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
        window.scrollY = 0;
      },
      clearError: function clearError() {
        Ember.set(this, 'errors', null);
      },
      disable: function disable() {
        var _this = this;

        this.send('clearError');
        var model = Ember.get(this, 'authConfig');
        var accessMode = Ember.get(model, 'id') === 'github' || Ember.get(model, 'id') === 'googleoauth' ? 'restricted' : 'unrestricted';
        Ember.setProperties(model, {
          enabled: false,
          accessMode: accessMode,
          allowedPrincipalIds: []
        });
        model.doAction('disable').then(function () {
          _this.send('waitAndRefresh');
        }).catch(function (err) {
          _this.send('gotError', err);
        }).finally(function () {
          Ember.set(_this, 'confirmDisable', false);
        });
      }
    }
  });

  _exports.default = _default;
});