define("shared/components/tooltip-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eHssbmSb",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0],\"hideAccessWarning\"],null],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"m-0 inline-block\"],[12],[1,[30,[36,1],[\"tooltipWarning.notConfigured\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dismiss inline-block\"],[12],[10,\"small\"],[12],[1,[30,[36,1],[\"tooltipWarning.dismiss\"],null]],[13],[2,\" \"],[10,\"i\"],[14,0,\"icon icon-close\"],[12],[13],[2,\" \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "shared/components/tooltip-warning/template.hbs"
    }
  });

  _exports.default = _default;
});