define("shared/helpers/driver-name", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params
    /* , options*/
    ) {
      var driver = params[0] || '';
      var intl = Ember.get(this, 'intl');
      var key = "nodeDriver.displayName.".concat(driver.toLowerCase());
      var name = (0, _util.ucFirst)(driver);

      if (intl.exists(key)) {
        name = intl.t(key);
      }

      return name;
    }
  });

  _exports.default = _default;
});