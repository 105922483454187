define("shared/components/input-url/component", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // const regularExpression_url = /((https?\:\/\/)|(www\.))(\S+)(\w{2,4})(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  // const regularExpression_ipAddress = /\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/g;
  // function validateInput(val) {
  //   if (val.match(regularExpression_url) || val.match(regularExpression_ipAddress) || val === '') {
  //     return true;
  //   }
  //   return false;
  // }
  var _default = Ember.TextField.extend({
    intl: Ember.inject.service(),
    type: 'url',
    classNameBindings: ['invalid:input-error'],
    invalid: false,
    stripScheme: true,
    isInvalid: null,
    urlWarning: null,
    urlError: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, 'setupValue');
    },
    setupValue: function setupValue() {
      var val = null;

      if (Ember.get(this, 'stripScheme')) {
        val = (0, _util.stripScheme)(Ember.get(this, 'element.value') || '');
      } else {
        val = Ember.get(this, 'element.value') || '';
      }

      Ember.set(this, 'value', this.validateInput(val));
    },
    _elementValueDidChange: function _elementValueDidChange() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var val = null;

      if (Ember.get(this, 'stripScheme')) {
        val = (0, _util.stripScheme)(Ember.get(this, 'element.value') || '');
      } else {
        val = Ember.get(this, 'element.value') || '';
      }

      Ember.run.debounce(this, 'validateInput', val, 250);
      Ember.set(this, 'value', val);
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      var val = null;

      if (Ember.get(this, 'stripScheme')) {
        val = (0, _util.stripScheme)(Ember.get(this, 'element.value') || '');
      } else {
        val = Ember.get(this, 'element.value') || '';
      }

      Ember.set(this, 'value', this.validateInput(val));
    },
    validateInput: function validateInput(val) {
      var warnings = [];
      var errors = [];

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (val && val.length > 0 && (0, _util.validateEndpoint)(val) && (0, _util.isPrivate)(val)) {
        warnings.addObject({
          type: 'warning',
          msg: 'hostSettings.notPublic.alert'
        });
      }

      if ((0, _util.isBadTld)(val)) {
        errors.addObject({
          type: 'error',
          msg: 'hostSettings.badTld.alert'
        });
      }

      if (errors.length > 0) {
        Ember.get(this, 'isInvalid')(Ember.set(this, 'invalid', true));
        Ember.get(this, 'urlError')(errors);
      } else {
        Ember.get(this, 'isInvalid')(Ember.set(this, 'invalid', false));
        Ember.get(this, 'urlError')([]);
      }

      if (warnings.length > 0) {
        Ember.get(this, 'urlWarning')(warnings);
      } else {
        Ember.get(this, 'urlWarning')([]);
      }

      return val;
    }
  });

  _exports.default = _default;
});