define("shared/components/principal-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/mCaK/jX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[15,\"oninput\",[30,[36,1],[[35,0]],[[\"value\"],[\"target.value\"]]]],[15,\"placeholder\",[30,[36,5],[[30,[36,4],[[30,[36,3],[[35,2,[\"provider\"]],\"githubconfig\"],null],\"inputIdentity.placeholder.generic\",\"inputIdentity.placeholder.github\"],null]],null]],[14,0,\"form-control\"],[14,4,\"text\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"search\",\"perform\",\"access\",\"eq\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "shared/components/principal-search/template.hbs"
    }
  });

  _exports.default = _default;
});