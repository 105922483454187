define("shared/components/accordion-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xgw56cmV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm bg-transparent pull-right\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"expandAll\"],null],[12],[1,[30,[36,3],[[30,[36,2],[[35,1],\"generic.collapseAll\",\"generic.expandAll\"],null]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,[[32,0],[35,5]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"expandAll\",\"if\",\"t\",\"showExpandAll\",\"expand\"]}",
    "meta": {
      "moduleName": "shared/components/accordion-list/template.hbs"
    }
  });

  _exports.default = _default;
});