define("shared/components/cru-cluster/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/child-hook", "shared/utils/load-script", "shared/components/cru-cluster/template", "jquery"], function (_exports, _viewNewEdit, _childHook, _loadScript, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MEMBER_CONFIG = {
    type: 'clusterRoleTemplateBinding'
  };
  var BUILD_IN_UI = ['tencentkubernetesengine', 'huaweicontainercloudengine', 'oraclecontainerengine', 'linodekubernetesengine'];

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    step: 1,
    initialProvider: null,
    memberConfig: MEMBER_CONFIG,
    newCluster: false,
    needReloadSchema: false,
    reloadingSchema: false,
    schemaReloaded: false,
    applyClusterTemplate: false,
    routeLoading: false,
    isPostSave: false,
    showClassicLauncher: false,
    nodePoolErrors: null,
    clusterTemplateRevisionId: null,
    agentEnvVars: [],
    cluster: Ember.computed.alias('model.cluster'),
    originalCluster: Ember.computed.alias('model.originalCluster'),
    primaryResource: Ember.computed.alias('model.cluster'),
    clusterState: Ember.computed.alias('originalCluster.state'),
    isCustom: Ember.computed.equal('driverInfo.nodeWhich', 'custom'),
    isK3sCluster: Ember.computed.equal('model.cluster.driver', 'k3s'),
    isRke2Cluster: Ember.computed.equal('model.cluster.driver', 'rke2'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.initAgentEnvVars();

      if (Ember.get(this, 'agentEnvVars')) {
        Ember.set(this, 'cluster.agentEnvVars', Ember.get(this, 'agentEnvVars'));
      } // On edit pass in initialProvider, for create just set provider directly


      var initialProvider = Ember.get(this, 'initialProvider');

      if (this.cookies.get('classicClusterLaunch')) {
        Ember.set(this, 'showClassicLauncher', true);
      } else {
        Ember.set(this, 'showClassicLauncher', false);
      }

      if (initialProvider) {
        Ember.set(this, 'provider', initialProvider);
      }

      if (Ember.isEmpty(Ember.get(this, 'cluster.id'))) {
        Ember.set(this, 'newCluster', true);
      }

      this.router.on('routeWillChange', function ()
      /* transition */
      {
        if (!_this.isDestroyed || !_this.isDestroying) {
          Ember.set(_this, 'routeLoading', true);
        }
      });
      this.router.on('routeDidChange', function ()
      /* transition */
      {
        if (!_this.isDestroyed || !_this.isDestroying) {
          Ember.set(_this, 'routeLoading', false);
        }
      });
    },
    actions: {
      updateFromYaml: function updateFromYaml(newOpts) {
        var primaryResource = this.primaryResource;

        var _cachedName;
        /**
         * If a user switches to the yaml entry view before they put a name in the cluster name input
         * and then they put a name in the input rather that the yaml, when they click save we overwrite
         * the name with the empty (or remove if not included) value.
         * Ensure the name they've entered in the UI is used. This will not affect name entry in yaml.
         */


        if (primaryResource.name) {
          _cachedName = primaryResource.name;
        }

        if (this.isEdit) {
          primaryResource.merge(newOpts);
        } else {
          primaryResource.replaceWith(newOpts);
        }

        if (Ember.isEmpty(primaryResource.name) && _cachedName) {
          Ember.set(primaryResource, 'name', _cachedName);
        }
      },
      clickNext: function clickNext() {
        (0, _jquery.default)('BUTTON[type="submit"]').click();
      },
      close: function close(saved) {
        if (this.close) {
          Ember.set(this, 'isPostSave', false);
          this.close(saved);
        }
      },
      setNodePoolErrors: function setNodePoolErrors(errors) {
        Ember.set(this, 'nodePoolErrors', errors);
      }
    },
    reloadSchema: Ember.observer('needReloadSchema', function () {
      var _this2 = this;

      if (!Ember.get(this, 'reloadingSchema') && Ember.get(this, 'needReloadSchema')) {
        Ember.set(this, 'reloadingSchema', true);
        Ember.get(this, 'globalStore').findAll('schema', {
          url: '/v3/schemas',
          forceReload: true
        }).then(function () {
          Ember.setProperties(_this2, {
            schemaReloaded: true,
            reloadingSchema: false
          });
        });
      }
    }),
    isEksClusterPending: Ember.computed('clusterState', 'provider', function () {
      var clusterState = this.clusterState,
          provider = this.provider;

      if (['pending', 'waiting'].includes(clusterState) && provider === 'amazoneksv2') {
        return true;
      }

      return false;
    }),
    kontainerDrivers: Ember.computed('model.kontainerDrivers.@each.{id,state}', function () {
      var nope = ['import', 'rancherkubernetesengine'];
      var kDrivers = Ember.get(this, 'model.kontainerDrivers') || [];
      var builtIn = kDrivers.filter(function (d) {
        return d.state === 'active' && (d.builtIn || BUILD_IN_UI.indexOf(d.id) > -1) && !nope.includes(d.id);
      });
      var custom = kDrivers.filter(function (d) {
        return d.state === 'active' && !d.builtIn && d.hasUi;
      });
      return {
        builtIn: builtIn,
        custom: custom
      };
    }),
    providerChoices: Ember.computed('app.proxyEndpoint', 'cluster.rancherKubernetesEngineConfig', 'intl.locale', 'isEdit', 'kontainerDrivers.[]', 'model.nodeDrivers', 'nodeDrivers.[]', 'schemaReloaded', function () {
      var _this3 = this;

      var kontainerDrivers = this.kontainerDrivers,
          intl = this.intl;
      var builtIn = kontainerDrivers.builtIn,
          custom = kontainerDrivers.custom;
      var out = [{
        name: 'googlegke',
        driver: 'googlegke',
        kontainerId: 'googlekubernetesengine'
      }, {
        name: 'amazoneks',
        driver: 'amazoneks',
        kontainerId: 'amazonelasticcontainerservice'
      }, // TODO - No driver entry exists for this since it is not a kontainerEngine driver, we need to disable by default the amazon eks v1 driver
      {
        displayName: 'Amazon EKS',
        driver: 'eks',
        name: 'amazoneksv2',
        nodePool: false,
        nodeWhich: 'eks',
        preSave: false,
        postSave: true
      }, {
        name: 'azureaks',
        driver: 'azureaks',
        kontainerId: 'azurekubernetesservice'
      }, {
        name: 'tencenttke',
        driver: 'tencenttke',
        kontainerId: 'tencentkubernetesengine'
      }, {
        name: 'huaweicce',
        driver: 'huaweicce',
        kontainerId: 'huaweicontainercloudengine'
      }, {
        name: 'oracleoke',
        driver: 'oracleoke',
        kontainerId: 'oraclecontainerengine'
      }, {
        name: 'linodelke',
        driver: 'linodelke',
        kontainerId: 'linodekubernetesengine'
      }];
      out = out.filter(function (o) {
        return builtIn.findBy('id', o.kontainerId) || o.name === 'amazoneksv2';
      });

      if (custom.length > 0) {
        custom.forEach(function (c) {
          var name = c.name;
          var configName = "".concat(name, "EngineConfig"); // need the hyph name

          var driverEngineSchema = Ember.get(_this3, 'globalStore').getById('schema', configName.toLowerCase());

          if (driverEngineSchema) {
            out.pushObject({
              displayName: Ember.get(c, 'displayName'),
              driver: Ember.get(c, 'name'),
              kontainerId: Ember.get(c, 'id'),
              name: Ember.get(c, 'name'),
              genericIcon: true // @TODO should have a way for drivers to provide an icon

            });
          } else {
            Ember.set(_this3, 'needReloadSchema', true);
          }
        });
      }

      Ember.get(this, 'model.nodeDrivers').filterBy('state', 'active').sortBy('name').forEach(function (driver) {
        var name = Ember.get(driver, 'name');
        var hasUi = Ember.get(driver, 'hasUi');
        var hasIcon = Ember.get(driver, 'hasBuiltinIconOnly');
        var uiUrl = Ember.get(driver, 'uiUrl');
        var configName = "".concat(name, "Config");
        var driverSchema = Ember.get(_this3, 'globalStore').getById('schema', configName.toLowerCase());

        if (uiUrl) {
          var cssUrl = (0, _loadScript.proxifyUrl)(uiUrl.replace(/\.js$/, '.css'), Ember.get(_this3, 'app.proxyEndpoint'));
          (0, _loadScript.loadStylesheet)(cssUrl, "driver-ui-css-".concat(name));
        }

        if (driverSchema) {
          out.push({
            name: name,
            driver: 'rke',
            genericIcon: !hasUi && !hasIcon,
            nodeComponent: hasUi ? name : 'generic',
            nodeWhich: name,
            nodePool: true
          });
        } else {
          Ember.set(_this3, 'needReloadSchema', true);
        }
      }), out.push({
        name: 'custom',
        driver: 'rke',
        nodeWhich: 'custom',
        nodePool: true,
        preSave: true
      });
      out.push({
        name: 'import',
        driver: 'import',
        preSave: true
      });
      out.push({
        name: 'imported',
        driver: 'import',
        preSave: true
      });
      out.push({
        name: 'importeks',
        driver: 'import-eks',
        preSave: false,
        postSave: true
      });
      out.push({
        name: 'k3s',
        driver: 'import',
        preSave: true
      });
      out.push({
        name: 'rke2',
        driver: 'import',
        preSave: true
      });
      out.forEach(function (driver) {
        var key = "clusterNew.".concat(driver.name, ".label");

        if (!Ember.get(driver, 'displayName') && intl.exists(key)) {
          Ember.set(driver, 'displayName', intl.t(key));
        }
      });

      if (Ember.get(this, 'isEdit') && Ember.get(this, 'cluster.rancherKubernetesEngineConfig')) {
        out = out.filterBy('driver', 'rke');
      }

      out.sortBy('name');
      return out;
    }),
    driverInfo: Ember.computed('provider', 'providerChoices', 'router.currentRoute.queryParams', function () {
      var name = Ember.get(this, 'provider');
      var router = this.router;
      var importProvider = Ember.get(router, 'currentRoute.queryParams.importProvider');

      if (name === 'import') {
        if (Ember.isEmpty(importProvider)) {
          return null;
        } else if (importProvider === 'eks') {
          name = 'importeks';
        }
      }

      var choices = Ember.get(this, 'providerChoices');
      var entry = choices.findBy('name', name);

      if (entry) {
        return {
          name: entry.name,
          displayName: entry.displayName,
          driverComponent: "cluster-driver/driver-".concat(entry.driver),
          nodeWhich: entry.nodeWhich,
          preSave: !!entry.preSave,
          postSave: !!entry.postSave,
          nodePool: entry.nodePool || false
        };
      }

      return {
        name: 'unknown',
        displayName: 'unknown',
        driverComponent: null,
        nodeWhich: 'unknown',
        preSave: false,
        nodePool: false
      };
    }),
    isImportedOther: Ember.computed('isK3sCluster', 'provider', 'router.currentRoute.queryParams.importProvider', function () {
      // This is a special case because k3s is treated as a special cluster and no longer looks imported by the time it's actve
      if (Ember.get(this, 'isK3sCluster')) {
        return true;
      }

      var name = Ember.get(this, 'provider');
      var importProvider = Ember.get(this, 'router.currentRoute.queryParams.importProvider');
      return name === 'import' && (!importProvider || importProvider === 'other');
    }),
    showDriverComponent: Ember.computed('routeLoading', 'provider', 'router.currentRoute.queryParams', function () {
      var driverInfo = this.driverInfo,
          provider = this.provider,
          routeLoading = this.routeLoading,
          router = this.router;
      var importProvider = Ember.get(router, 'currentRoute.queryParams.importProvider');

      if (routeLoading || Ember.isEmpty(driverInfo)) {
        return false;
      }

      if (provider === 'import') {
        if (!Ember.isEmpty(importProvider)) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    }),
    initAgentEnvVars: function initAgentEnvVars() {
      Ember.set(this, 'agentEnvVars', Ember.get(this, 'cluster.agentEnvVars') || []);
    },
    doSave: function doSave(opt) {
      opt = opt || {};
      opt.qp = {
        '_replace': 'true'
      };
      return this._super(opt);
    },
    didSave: function didSave() {
      var _this4 = this;

      var originalCluster = Ember.get(this, 'cluster');
      return originalCluster.waitForCondition('InitialRolesPopulated').then(function () {
        return _this4.applyHooks().then(function () {
          var clone = originalCluster.clone();
          Ember.setProperties(_this4, {
            cluster: clone,
            originalCluster: originalCluster
          });
          return clone;
        });
      });
    },
    doneSaving: function doneSaving(saved) {
      if (Ember.get(this, 'step') === 1 && Ember.get(this, 'driverInfo.preSave')) {
        Ember.setProperties(this, {
          step: 2,
          initialProvider: Ember.get(this, 'provider')
        });
      } else if (Ember.get(this, 'driverInfo.postSave')) {
        Ember.setProperties(this, {
          initialProvider: Ember.get(this, 'provider'),
          isPostSave: true,
          step: 2
        });
      } else {
        if (this.close) {
          this.close(saved);
        }
      }
    },
    errorSaving: function errorSaving()
    /* err */
    {
      if (this.applyClusterTemplate && this.primaryResource._cachedConfig) {
        var _this$primaryResource = this.primaryResource._cachedConfig,
            localClusterAuthEndpoint = _this$primaryResource.localClusterAuthEndpoint,
            rancherKubernetesEngineConfig = _this$primaryResource.rancherKubernetesEngineConfig,
            enableNetworkPolicy = _this$primaryResource.enableNetworkPolicy,
            defaultClusterRoleForProjectMembers = _this$primaryResource.defaultClusterRoleForProjectMembers,
            defaultPodSecurityPolicyTemplateId = _this$primaryResource.defaultPodSecurityPolicyTemplateId;
        Ember.setProperties(this.primaryResource, {
          localClusterAuthEndpoint: localClusterAuthEndpoint,
          rancherKubernetesEngineConfig: rancherKubernetesEngineConfig,
          enableNetworkPolicy: enableNetworkPolicy,
          defaultClusterRoleForProjectMembers: defaultClusterRoleForProjectMembers,
          defaultPodSecurityPolicyTemplateId: defaultPodSecurityPolicyTemplateId
        });
      }
    }
  });

  _exports.default = _default;
});