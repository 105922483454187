define("shared/components/catalog-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gA5AkNRv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[11,\"div\"],[24,\"role\",\"button\"],[4,[38,3],[[32,0],[35,2],[35,0,[\"id\"]]],null],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"catalog-icon\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[31,[[34,1,[\"baseAssets\"]],\"assets/images/generic-catalog.svg\"]]],[15,\"data-src\",[34,0,[\"links\",\"icon\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[14,0,\"ml-20 mr-20\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[35,0,[\"certified\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"certified\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[35,0,[\"certified\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"itemwrap mt-30\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"mb-0\"],[12],[2,\"\\n      \"],[1,[35,0,[\"displayName\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"app\",\"launchAction\",\"action\",\"showIcon\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/catalog-box/template.hbs"
    }
  });

  _exports.default = _default;
});