define("global-admin/security/accounts/detail/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GLOBAL_HEADERS = [{
    name: 'enabled',
    searchField: null,
    width: 30
  }, {
    name: 'name',
    sort: ['globalRole.displayName'],
    searchField: 'globalRole.displayName',
    translationKey: 'accountsPage.detail.table.headers.permission'
  }, {
    name: 'created',
    searchField: false,
    sort: ['createdTs'],
    translationKey: 'accountsPage.detail.table.headers.created',
    width: 200
  }];
  var PROJECT_HEADERS = [{
    name: 'projectName',
    searchField: 'project.displayName',
    sort: ['project.displayName', 'displayName'],
    translationKey: 'accountsPage.detail.table.headers.projectName'
  }, {
    name: 'name',
    searchField: 'roleTemplate.displayName',
    sort: ['roleTemplate.displayName', 'project.displayName'],
    translationKey: 'accountsPage.detail.table.headers.role'
  }, {
    name: 'created',
    searchField: false,
    sort: ['createdTs'],
    translationKey: 'accountsPage.detail.table.headers.created',
    width: 200
  }];
  var CLUSTER_HEADERS = [{
    name: 'clusterName',
    searchField: 'cluster.displayName',
    sort: ['cluster.displayName', 'project.displayName'],
    translationKey: 'accountsPage.detail.table.headers.clusterName'
  }, {
    name: 'name',
    searchField: 'roleTemplate.displayName',
    sort: ['roleTemplate.displayNAme'],
    translationKey: 'accountsPage.detail.table.headers.role'
  }, {
    name: 'created',
    searchField: false,
    sort: ['createdTs'],
    translationKey: 'accountsPage.detail.table.headers.created',
    width: 200
  }];
  var GROUP_HEADERS = [{
    name: 'groupName',
    searchField: 'group.displayName',
    sort: ['group.displayName', 'group.id'],
    translationKey: 'accountsPage.detail.table.headers.groupName'
  }, {
    name: 'name',
    searchField: 'roleTemplate.displayName',
    sort: ['roleTemplate.displayNAme'],
    translationKey: 'accountsPage.detail.table.headers.role'
  }, {
    name: 'created',
    searchField: false,
    sort: ['createdTs'],
    translationKey: 'accountsPage.detail.table.headers.created',
    width: 200
  }];

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    globalHeaders: GLOBAL_HEADERS,
    clusterHeaders: CLUSTER_HEADERS,
    projectHeaders: PROJECT_HEADERS,
    groupHeaders: GROUP_HEADERS,
    sortGlobalBy: 'name',
    sortClusterBy: 'name',
    sortProjectBy: 'projectName',
    globalRoleMapping: Ember.computed('model.user.globalRoleBindings.[]', 'model.globalRoles', function () {
      var _this = this;

      var out = (Ember.get(this, 'model.globalRoles') || []).filterBy('isHidden', false).map(function (role) {
        return {
          role: role,
          enabled: false
        };
      });
      (Ember.get(this, 'model.user.globalRoleBindings') || []).forEach(function (binding) {
        var globalRole = Ember.get(binding, 'globalRole');

        if (Ember.get(globalRole, 'isHidden')) {
          _this.getEnabledRoles(globalRole, out).forEach(function (r) {
            r.enabled = true;
            r.binding = binding;
          });
        } else {
          var entry = out.findBy('role', globalRole);

          if (entry) {
            entry.enabled = true;
            entry.binding = binding;
          }
        }
      });
      return out;
    }),
    hasPermission: function hasPermission(globalRoleRules, permission) {
      return globalRoleRules.find(function (gRule) {
        return ((Ember.get(gRule, 'apiGroups') || []).indexOf('*') > -1 || (Ember.get(gRule, 'apiGroups') || []).indexOf(permission.apiGroup) > -1) && ((Ember.get(gRule, 'resources') || []).indexOf('*') > -1 || (Ember.get(gRule, 'resources') || []).indexOf(permission.resource) > -1) && ((Ember.get(gRule, 'verbs') || []).indexOf('*') > -1 || (Ember.get(gRule, 'verbs') || []).indexOf(permission.verb) > -1);
      });
    },
    containsRule: function containsRule(globalRoleRules, rule) {
      var _this2 = this;

      var apiGroups = Ember.get(rule, 'apiGroups') || [];
      var resources = Ember.get(rule, 'resources') || [];
      var verbs = Ember.get(rule, 'verbs') || [];
      var permissions = [];
      apiGroups.forEach(function (apiGroup) {
        return resources.forEach(function (resource) {
          return verbs.forEach(function (verb) {
            return permissions.push({
              apiGroup: apiGroup,
              resource: resource,
              verb: verb
            });
          });
        });
      });
      return permissions.every(function (permission) {
        return _this2.hasPermission(globalRoleRules, permission);
      });
    },
    getEnabledRoles: function getEnabledRoles(globalRole, out) {
      var _this3 = this;

      var globalRoleRules = Ember.get(globalRole, 'rules') || [];
      return out.filter(function (r) {
        return (Ember.get(r, 'role.rules') || []).every(function (rule) {
          return _this3.containsRule(globalRoleRules, rule);
        });
      });
    }
  });

  _exports.default = _default;
});