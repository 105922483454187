define("global-admin/security/accounts/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        user: Ember.get(this, 'globalStore').find('user', params.user_id)
      });
    },
    afterModel: function afterModel() {
      Ember.run.schedule('afterRender', this, function () {
        var input = $('.user-display-name')[0]; // eslint-disable-line

        if (input) {
          input.focus();
        }
      });
    },
    resetController: function resetController(controller
    /* , isExiting, transition */
    ) {
      Ember.setProperties(controller, {
        primaryResource: null,
        password: '',
        confirm: '',
        errors: []
      });
    }
  });

  _exports.default = _default;
});