define("global-admin/components/account-group-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xgxLjGPL",
    "block": "{\"symbols\":[\"grb\",\"idx\"],\"statements\":[[10,\"td\"],[15,\"data-title\",[31,[[34,1,[\"name\"]]]]],[14,0,\"pr-15\"],[12],[2,\"\\n  \"],[8,\"identity-block\",[],[[\"@principalId\",\"@wide\"],[[34,2,[\"id\"]],false]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,1,[\"globalRoleName\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"comma-list\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[12],[2,\"\\n        \"],[8,\"link-to\",[],[[\"@route\",\"@model\",\"@query\"],[\"security.roles.detail\",[32,1,[\"groupRoleBindingId\"]],[30,[36,0],null,[[\"type\"],[\"global\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"groupRoleBindingName\"]]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,1,[\"actions\"]]]]],[14,0,\"actions\"],[12],[2,\"\\n  \"],[8,\"action-menu\",[],[[\"@model\"],[[34,6]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"dt\",\"model\",\"mappedGroupRoleBindingNamesIds\",\"-track-array\",\"each\",\"groupRoleBinding\"]}",
    "meta": {
      "moduleName": "global-admin/components/account-group-row/template.hbs"
    }
  });

  _exports.default = _default;
});