define("shared/components/form-name-description/component", ["exports", "shared/components/form-name-description/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Inputs
    // You can either set model or name+description
    model: null,
    name: null,
    description: null,
    editing: true,
    _name: '',
    _description: '',
    nameLabel: 'formNameDescription.name.label',
    namePlaceholder: 'formNameDescription.name.placeholder',
    nameHelpText: '',
    nameRequired: false,
    nameDisabled: false,
    focusDisabledOnInit: false,
    descriptionLabel: 'formNameDescription.description.label',
    descriptionHelp: '',
    descriptionPlaceholder: 'formNameDescription.description.placeholder',
    descriptionRequired: false,
    descriptionDisabled: false,
    descriptionShown: true,
    descriptionExpanded: false,
    expandDescriptionAction: 'formNameDescription.description.expand',
    rowClass: 'row',
    bothColClass: 'col span-6 mb-0 mt-0',
    colClass: 'col span-12',
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'model')) {
        this.modelChanged();
      } else {
        this.setProperties({
          _name: Ember.get(this, 'name'),
          _description: Ember.get(this, 'description')
        });
      }

      if ((Ember.get(this, '_description') || '').length) {
        Ember.set(this, 'descriptionExpanded', true);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying || Ember.get(_this, 'nameDisabled') || Ember.get(_this, 'focusDisabledOnInit')) {
          return;
        }

        var elem = (0, _jquery.default)('INPUT')[0];

        if (elem) {
          elem.focus();
        }
      });
    },
    actions: {
      expandDescription: function expandDescription() {
        var _this2 = this;

        Ember.set(this, 'descriptionExpanded', true);
        Ember.run.next(function () {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          var el = (0, _jquery.default)('.description')[0];

          if (el) {
            el.focus();
          }
        });
      }
    },
    modelChanged: Ember.observer('model', 'model.name', 'model.description', function () {
      this.setProperties({
        _name: Ember.get(this, 'model.name'),
        _description: Ember.get(this, 'model.description')
      });

      if ((Ember.get(this, 'model.description') || '').length) {
        Ember.set(this, 'descriptionExpanded', true);
      }
    }),
    nameChanged: Ember.observer('_name', function () {
      Ember.run.once(this, 'handleNameChanged');
    }),
    descriptionChanged: Ember.observer('_description', function () {
      Ember.run.once(this, 'handleDescriptionChanged');
    }),
    handleNameChanged: function handleNameChanged() {
      var val = Ember.get(this, '_name');

      if (Ember.get(this, 'model')) {
        Ember.set(this, 'model.name', val);
      } else {
        Ember.set(this, 'name', val);
      }
    },
    handleDescriptionChanged: function handleDescriptionChanged() {
      var val = Ember.get(this, '_description');

      if (Ember.get(this, 'model')) {
        Ember.set(this, 'model.description', val);
      } else {
        Ember.set(this, 'description', val);
      }
    }
  });

  _exports.default = _default;
});