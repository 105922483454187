define("shared/oci/service", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OCI_META_API = '/meta/oci';

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    request: function request() {
      var auth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var command = arguments.length > 1 ? arguments[1] : undefined;
      var opt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var url = "".concat(OCI_META_API, "/").concat(command);
      var token = Ember.get(auth, 'token');
      var headers = {
        'Accept': 'application/json'
      };
      var data = {};

      if (token === null || token === '' || token === undefined) {
        // OKE and cloud-credential form will pass these values directly
        data = auth;
        Ember.set(headers, 'Content-Type', 'application/json');
      } else {
        // All other paths must pass the cloudCredentialId as the token
        url = (0, _util.addQueryParam)(url, 'cloudCredentialId', token);
        url = (0, _util.addQueryParams)(url, opt.params || {});
      }

      if (Ember.get(auth, 'type') === 'cloud') {
        Ember.set(headers, 'x-api-cattleauth-header', "Bearer credID=".concat(token, " passwordField=privateKeyPassphrase"));
      } else {
        Ember.set(headers, 'X-Api-Auth-Header', "Bearer ".concat(token));
      }

      return fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }).then(function (resp) {
        var json = resp.json(); // there's always a body

        if (resp.status >= 200 && resp.status < 300) {
          return json;
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      }).catch(function (err) {
        return Ember.RSVP.reject(err);
      });
    }
  });

  _exports.default = _default;
});