define("global-admin/multi-cluster-apps/index/controller", ["exports", "ui/utils/constants", "ui/utils/search-text"], function (_exports, _constants, _searchText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    prefs: Ember.inject.service(),
    intl: Ember.inject.service(),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    sortBy: 'name',
    templatesObsvr: Ember.observer('model.apps.[]', function () {
      Ember.run.once(this, this.catalog.fetchAppTemplates, Ember.get(this, 'model.apps'));
    }),
    filteredApps: Ember.computed('model.apps.@each.{type,isFromCatalog,state}', 'searchText', function () {
      var apps = Ember.get(this, 'model.apps').filter(function (ns) {
        return !_constants.default.REMOVEDISH_STATES.includes(Ember.get(ns, 'state'));
      });
      apps = apps.sortBy('displayName');

      var _filter = (0, _searchText.filter)(apps, Ember.get(this, 'searchText')),
          matches = _filter.matches;

      var group = [];
      var dataIndex = 0;
      matches.forEach(function (app, index) {
        if (index % 2 === 0) {
          group.push([app]);
          dataIndex++;
        } else {
          group[dataIndex - 1].push(app);
        }
      });
      return group;
    }),
    showMultiClusterWarning: Ember.computed('scope.allClusters.@each.id', function () {
      var localCluster = this.scope.allClusters.findBy('id', 'local');
      return localCluster && !Ember.isEmpty(Ember.get(localCluster, 'id')) && Ember.get(localCluster, 'isReady');
    })
  });

  _exports.default = _default;
});