define("global-admin/clusters/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3D6Ghcfq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3,[\"currentRoute\",\"localName\"]],\"select\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"clustersPage.select.provider.label\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[35,3,[\"currentRoute\",\"localName\"]],\"cluster-template\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"clustersPage.select.template.label\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[35,1],\"import\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[35,3,[\"currentRoute\",\"queryParams\",\"importProvider\"]],\"eks\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"clustersPage.launch.importEks\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"clustersPage.launch.import\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"clustersPage.launch.new\"],null]],[2,\" - \"],[1,[30,[36,2],[[35,1]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"cluster-launch\"],[12],[2,\"\\n  \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"provider\",\"driver-name\",\"router\",\"eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "global-admin/clusters/new/template.hbs"
    }
  });

  _exports.default = _default;
});