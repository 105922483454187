define("shared/components/cluster-template-override-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1cPZTzEq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tooltip-element\",[],[[\"@type\",\"@tooltipTemplate\",\"@model\",\"@inlineBlock\"],[\"tooltip-toggle-override\",\"tooltip-static\",[34,0],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"btn-toggle\",[],[[\"@toggled\",\"@checked\"],[[30,[36,1],[[32,0],\"addOverride\"],null],[30,[36,3],[[35,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tooltipModel\",\"action\",\"btnToggled\",\"mut\"]}",
    "meta": {
      "moduleName": "shared/components/cluster-template-override-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});