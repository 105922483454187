define("shared/version-choices/service", ["exports", "semver", "shared/utils/parse-version", "shared/utils/constants"], function (_exports, _semver, _parseVersion, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    defaultK8sVersionRange: Ember.computed.alias("settings.".concat(_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE)),
    parseCloudProviderVersionChoices: function parseCloudProviderVersionChoices(versions, providerVersion, mode) {
      var intl = this.intl,
          defaultK8sVersionRange = this.defaultK8sVersionRange;
      var maxVersionRange = defaultK8sVersionRange.split(' ').pop();
      return versions.map(function (version) {
        if ((0, _parseVersion.satisfies)((0, _parseVersion.coerceVersion)(version), maxVersionRange)) {
          var out = {
            label: version,
            value: version
          };

          if (mode === 'edit') {
            if (_semver.default.lt((0, _parseVersion.coerceVersion)(version), (0, _parseVersion.coerceVersion)(providerVersion))) {
              Ember.setProperties(out, {
                disabled: true,
                label: "".concat(out.label, " ").concat(intl.t('formVersions.downgrade'))
              });
            }
          }

          return out;
        }
      }).filter(function (version) {
        return version;
      });
    }
  });

  _exports.default = _default;
});