define("shared/components/action-menu/component", ["exports", "shared/components/action-menu/template", "shared/utils/calculate-position"], function (_exports, _template, _calculatePosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltipService: Ember.inject.service('tooltip'),
    resourceActions: Ember.inject.service('resource-actions'),
    layout: _template.default,
    tagName: 'div',
    classNames: ['resource-actions', 'action-menu'],
    context: null,
    inTooltip: false,
    model: null,
    size: 'xs',
    actions: {
      clickedAction: function clickedAction(actionName) {
        Ember.get(this, 'resourceActions').triggerAction(actionName);
        Ember.set(Ember.get(this, 'tooltipService'), 'childOpened', false);
      },
      preload: function preload() {
        Ember.get(this, 'resourceActions').setActionItems(Ember.get(this, 'model'), Ember.get(this, 'context'));
      },
      actionsOpen: function actionsOpen() {
        Ember.set(Ember.get(this, 'tooltipService'), 'childOpened', true);
      },
      actionsClosed: function actionsClosed() {
        Ember.set(Ember.get(this, 'tooltipService'), 'childOpened', false);
        Ember.get(this, 'tooltipService').hide();
      },
      calculatePosition: _calculatePosition.default
    },
    sizeClass: Ember.computed('size', function () {
      var size = Ember.get(this, 'size');

      if (size && size !== 'md') {
        return "btn-".concat(size);
      }

      return '';
    }),
    click: function click(e) {
      var tgt = $(e.target); // eslint-disable-line

      var more = tgt.closest('.more-actions');

      if (more && more.length) {
        e.preventDefault();
        e.stopPropagation();

        if (Ember.get(this, 'inTooltip')) {
          Ember.set(Ember.get(this, 'resourceActions'), 'tooltipActions', true);
        } else {
          Ember.set(Ember.get(this, 'resourceActions'), 'tooltipActions', false);
        }

        Ember.get(this, 'resourceActions').setActionItems(Ember.get(this, 'model'), Ember.get(this, 'context'));
      }
    }
  });

  _exports.default = _default;
});