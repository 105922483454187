define("shared/components/storage-class/provisioner-aws-ebs/component", ["exports", "shared/components/storage-class/provisioner-aws-ebs/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.VOLUME_TYPES = void 0;
  var VOLUME_TYPES = ['gp2', 'io1', 'st1', 'sc1'];
  _exports.VOLUME_TYPES = VOLUME_TYPES;

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    volumeTypes: VOLUME_TYPES,
    volumeType: null,
    zones: null,
    zoneAutomatic: null,
    iopsPerGB: null,
    encrypted: true,
    kmsKeyId: null,
    kmsAutomatic: null,
    fsType: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var changes = {};
      changes['volumeType'] = Ember.get(this, 'parameters.type') || 'gp2';
      changes['iopsPerGB'] = Ember.get(this, 'parameters.iopsPerGB') || '';
      var zone = Ember.get(this, 'parameters.zone');
      var zones = Ember.get(this, 'parameters.zones');

      if (zones && zones.length) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zones;
      } else if (zone) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zone;
      } else {
        changes['zoneAutomatic'] = true;
        changes['zones'] = '';
      }

      var fsType = Ember.get(this, 'parameters.fsType');

      if (fsType) {
        changes['fsType'] = fsType;
      }

      changes['encrypted'] = Ember.get(this, 'parameters.encrypted') === 'true';
      var key = Ember.get(this, 'parameters.kmsKeyId');

      if (key) {
        changes['kmsKeyId'] = key;
        changes['kmsAutomatic'] = false;
      } else {
        changes['kmsAutomatic'] = true;
      }

      Ember.setProperties(this, changes);
    },
    supportsIops: Ember.computed('volumeType', function () {
      return !!Ember.get(this, 'volumeType').match(/^io\d+$/);
    }),
    updateParams: function updateParams() {
      var type = Ember.get(this, 'volumeType');
      var out = {
        type: type
      };
      var zoneAutomatic = Ember.get(this, 'zoneAutomatic');

      if (!zoneAutomatic) {
        var zones = (Ember.get(this, 'zones') || '').trim();

        if (zones) {
          out['zones'] = zones;
        }
      }

      if (Ember.get(this, 'supportsIops')) {
        out['iopsPerGB'] = "".concat(Ember.get(this, 'iopsPerGB') || '');
      }

      var encrypted = Ember.get(this, 'encrypted');
      var kmsKeyId = Ember.get(this, 'kmsKeyId');
      var kmsAutomatic = Ember.get(this, 'kmsAutomatic');

      if (encrypted) {
        out['encrypted'] = 'true';

        if (!kmsAutomatic && kmsKeyId) {
          out['kmsKeyId'] = kmsKeyId;
        }
      } else {
        out['encrypted'] = 'false';
      }

      if (this.fsType) {
        out['fsType'] = this.fsType;
      }

      Ember.set(this, 'parameters', out);
    }
  });

  _exports.default = _default;
});