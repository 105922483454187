define("shared/components/metrics-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o/8L513M",
    "block": "{\"symbols\":[\"parent\",\"&default\"],\"statements\":[[6,[37,9],null,[[\"grafanaUrl\",\"title\",\"detail\",\"expandAll\",\"expand\",\"expanded\",\"componentName\"],[[35,8],[35,7],[30,[36,6],[\"metricsAction.description\"],null],[35,0],[30,[36,5],[[32,0],[35,4]],null],[35,1],\"sortable-table\"]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,0]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"expandAll\",\"expanded\",\"or\",\"if\",\"expandFn\",\"action\",\"t\",\"title\",\"grafanaUrl\",\"accordion-list-item\"]}",
    "meta": {
      "moduleName": "shared/components/metrics-summary/template.hbs"
    }
  });

  _exports.default = _default;
});