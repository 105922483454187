define("global-admin/security/accounts/new-group/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.globalStore.findAll('globalrole').then(function (resp) {
        var globalRoles = resp.globalRoles;
        return {
          globalRoleBinding: _this.globalStore.createRecord({
            type: 'globalrolebinding'
          }),
          globalRoles: globalRoles
        };
      });
    }
  });

  _exports.default = _default;
});