define("ember-cli-clipboard/templates/components/copy-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OqQdkZkL",
    "block": "{\"symbols\":[\"@aria-label\",\"@classNames\",\"@class\",\"@disabled\",\"@title\",\"&attrs\",\"@clipboardText\",\"@clipboardTarget\",\"@clipboardAction\",\"@buttonType\",\"&default\"],\"statements\":[[11,\"button\"],[16,\"aria-label\",[32,1]],[16,0,[31,[\"copy-btn \",[32,3],\" \",[32,2]]]],[16,\"disabled\",[32,4]],[16,\"title\",[32,5]],[17,6],[16,\"data-clipboard-text\",[32,7]],[16,\"data-clipboard-target\",[32,8]],[16,\"data-clipboard-action\",[32,9]],[16,4,[30,[36,0],[[32,10],[32,10],\"button\"],null]],[4,[38,1],[[32,0,[\"setupElement\"]]],null],[4,[38,1],[[32,0,[\"registerClipboard\"]]],null],[4,[38,2],[[32,0,[\"registerClipboard\"]]],null],[4,[38,3],[[32,0,[\"destroyClipboard\"]]],null],[12],[2,\"\\n  \"],[18,11,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"did-update\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "ember-cli-clipboard/templates/components/copy-button.hbs"
    }
  });

  _exports.default = _default;
});