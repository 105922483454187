define("global-admin/cluster-templates/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FXLBFA68",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header clearfix\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"pull-left\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"clusterTemplatesPage.new.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@users\",\"@psps\",\"@mode\",\"@forceExpandOnInit\",\"@cancel\",\"@done\"],[[34,1,[\"clusterTemplate\"]],[34,1,[\"clusterTemplateRevision\"]],[34,1,[\"users\"]],[34,1,[\"psps\"]],\"new\",false,[30,[36,2],[[32,0],\"cancel\"],null],[30,[36,2],[[32,0],\"done\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"action\"]}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/new/template.hbs"
    }
  });

  _exports.default = _default;
});