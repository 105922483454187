define("shared/components/tooltip-expiring/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e/H4KERS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"tooltip-expire\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mb-5\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"tooltipExpire.label\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,2,[\"canRotateCerts\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"rotateCertificates\"],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"tooltipExpire.link\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"model\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/tooltip-expiring/template.hbs"
    }
  });

  _exports.default = _default;
});