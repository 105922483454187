define("shared/components/copy-to-clipboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WWyl+Gyu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,11]],null,[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"type\",\"model\",\"tooltipTemplate\",\"class\",\"textChangedEvent\",\"baseClass\",\"isCopyTo\",\"tooltipFor\"],[\"tooltip-basic\",[30,[36,1],[[30,[36,3],[[35,9],[35,9,[\"tooltipText\"]],\"generic.emptyString\"],null]],null],\"tooltip-static\",\"copy-button-container bg-transparent\",[35,8],\"copy-clipboard\",true,\"copyToClipboard\"]],[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"class\",\"clipboardTarget\",\"clipboardText\",\"success\"],[[35,6],[35,5],[35,4],\"alertSuccess\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\" \"],[10,\"i\"],[15,0,[31,[\"icon \",[34,2]]]],[12],[13]],\"parameters\":[]}]]],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\" \"],[1,[30,[36,1],[[35,0]],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"buttonText\",\"t\",\"icon\",\"if\",\"clipboardText\",\"target\",\"buttonClasses\",\"copy-button\",\"textChangedEvent\",\"model\",\"tooltip-element\",\"isSupported\"]}",
    "meta": {
      "moduleName": "shared/components/copy-to-clipboard/template.hbs"
    }
  });

  _exports.default = _default;
});