define("shared/mixins/reservation-check", ["exports", "shared/utils/util", "shared/utils/parse-unit"], function (_exports, _util, _parseUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scope: Ember.inject.service(),
    preRequestsCpu: null,
    preRequestsMemory: null,
    clusterLevelMinCpu: 1000,
    clusterLevelMinMemory: 1000,
    projectLevelMinCpu: 500,
    projectLevelMinMemory: 500,
    insufficient: Ember.computed('scope.currentCluster.nodes', 'insufficientMemory', 'insufficientCpu', function () {
      var allNodes = Ember.get(this, 'scope.currentCluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      return Ember.get(this, 'insufficientMemory') || Ember.get(this, 'insufficientCpu');
    }),
    leftCpu: Ember.computed('cluster.nodes.@each.{allocatable,requested}', function () {
      var allNodes = Ember.get(this, 'cluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      var schedulableNodes = allNodes.filterBy('isUnschedulable', false);
      var leftCpu = 0;
      schedulableNodes.forEach(function (node) {
        var left = (0, _util.convertToMillis)(Ember.get(node, 'allocatable.cpu') || '0') - (0, _util.convertToMillis)(Ember.get(node, 'requested.cpu') || '0');
        leftCpu += left;
      });
      return leftCpu;
    }),
    leftMemory: Ember.computed('cluster.nodes.@each.{allocatable,requested}', function () {
      var allNodes = Ember.get(this, 'cluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      var schedulableNodes = allNodes.filterBy('isUnschedulable', false);
      var leftMemory = 0;
      schedulableNodes.forEach(function (node) {
        var left = (0, _parseUnit.parseSi)(Ember.get(node, 'allocatable.memory'), 1024) / 1048576 - (0, _parseUnit.parseSi)(Ember.get(node, 'requested.memory') || '0', 1024) / 1048576;
        leftMemory += left;
      });
      return leftMemory;
    }),
    insufficientMemory: Ember.computed('preRequestsMemory', 'requestsMemory', 'leftMemory', function () {
      var preRequestsMemory = this.preRequestsMemory,
          requestsMemory = this.requestsMemory,
          leftMemory = this.leftMemory,
          enabled = this.enabled,
          minMemory = this.minMemory;

      if (enabled) {
        return leftMemory <= parseInt(requestsMemory || 0, 10) - parseInt(preRequestsMemory || 0, 10);
      } else {
        return leftMemory <= minMemory;
      }
    }),
    insufficientCpu: Ember.computed('preRequestsCpu', 'requestsCpu', 'leftCpu', function () {
      var preRequestsCpu = this.preRequestsCpu,
          requestsCpu = this.requestsCpu,
          leftCpu = this.leftCpu,
          enabled = this.enabled,
          minCpu = this.minCpu;

      if (enabled) {
        return leftCpu <= parseInt(requestsCpu || 0, 10) - parseInt(preRequestsCpu || 0, 10);
      } else {
        return leftCpu <= minCpu;
      }
    }),
    minCpu: Ember.computed('clusterLevelMinCpu', 'level', 'projectLevelMinCpu', 'requestsCpu', function () {
      var cpu = parseInt(Ember.get(this, 'requestsCpu') || 0, 10);

      if (isNaN(cpu)) {
        cpu = 0;
      }

      return (Ember.get(this, 'level') === 'cluster' ? Ember.get(this, 'clusterLevelMinCpu') : Ember.get(this, 'projectLevelMinCpu')) + cpu;
    }),
    minMemory: Ember.computed('clusterLevelMinMemory', 'level', 'projectLevelMinMemory', 'requestsMemory', function () {
      var memory = parseInt(Ember.get(this, 'requestsMemory') || 0, 10);

      if (isNaN(memory)) {
        memory = 0;
      }

      return (Ember.get(this, 'level') === 'cluster' ? Ember.get(this, 'clusterLevelMinMemory') : Ember.get(this, 'projectLevelMinMemory')) + memory;
    }),
    getComponentInsufficient: function getComponentInsufficient(component, type, reservation) {
      var allNodes = Ember.get(this, 'cluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      var maxLeft = Ember.get(this, "".concat(component, "SchedulableNodes")).reduce(function (out, node) {
        var left;

        if (type === 'cpu') {
          left = (0, _util.convertToMillis)(Ember.get(node, "allocatable.".concat(type))) - (0, _util.convertToMillis)(Ember.get(node, "requested.".concat(type)) || '0');
        } else if (type === 'memory') {
          left = (0, _parseUnit.parseSi)(Ember.get(node, 'allocatable.memory'), 1024) / 1048576 - (0, _parseUnit.parseSi)(Ember.get(node, 'requested.memory') || '0', 1024) / 1048576;
        }

        return left > out ? left : out;
      }, 0);
      var request = reservation ? reservation : Ember.get(this, "config.".concat(component, "Request").concat((0, _util.ucFirst)(type))) || 0;
      return Ember.get(this, 'enabled') ? false : maxLeft <= request;
    },
    getSchedulableNodes: function getSchedulableNodes(component) {
      var _this = this;

      var allNodes = Ember.get(this, 'cluster.nodes') || [];
      var out = allNodes.filterBy('isUnschedulable', false).filter(function (node) {
        return (Ember.get(_this, "".concat(component, "NodeSelectors")) || []).every(function (selector) {
          var labelValue = (Ember.get(node, 'labels') || {})[Ember.get(selector, 'key')];

          if (Ember.get(selector, 'value') === '') {
            return labelValue !== undefined;
          } else {
            return Ember.get(selector, 'value') === labelValue;
          }
        });
      });
      return out;
    },
    getComponentWarning: function getComponentWarning(component, componentCpu, componentMemory, displayComponent) {
      var insufficientCpu = Ember.get(this, "insufficient".concat((0, _util.ucFirst)(component), "Cpu"));
      var insufficientMemory = Ember.get(this, "insufficient".concat((0, _util.ucFirst)(component), "Memory"));
      var nodeSelectors = Ember.get(this, "".concat(component, "NodeSelectors")) || [];
      var intl = Ember.get(this, 'intl');
      var cpu = componentCpu ? componentCpu : Ember.get(this, "config.".concat(component, "RequestCpu"));
      var memory = componentMemory ? componentMemory : Ember.get(this, "config.".concat(component, "RequestMemory"));
      var prefix = 'clusterIstioPage.insufficientSize.selectors';

      if (nodeSelectors.length === 0) {
        prefix = 'clusterIstioPage.insufficientSize.workload';
      } else {
        var unsupportedSelectors = nodeSelectors.filter(function (n) {
          return n.value === 'true' || n.value === 'false' || /^\d+$/g.test(n.value);
        });

        if (unsupportedSelectors.length > 0) {
          return intl.t("clusterIstioPage.insufficientSize.selectors.unsupported", {
            component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
          });
        }
      }

      if (insufficientCpu && insufficientMemory) {
        return intl.t("".concat(prefix, ".all"), {
          cpu: cpu,
          memory: memory,
          component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
        });
      } else if (insufficientCpu) {
        return intl.t("".concat(prefix, ".cpu"), {
          cpu: cpu,
          component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
        });
      } else if (insufficientMemory) {
        return intl.t("".concat(prefix, ".memory"), {
          memory: memory,
          component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
        });
      }
    },
    validateLimitAndRequest: function validateLimitAndRequest(component) {
      var _this2 = this;

      var errors = [];
      ['requestCpu', 'limitCpu', 'requestMemory', 'limitMemory'].map(function (suffix) {
        var key = "config.".concat(component).concat((0, _util.ucFirst)(suffix));

        if (!Ember.get(_this2, key)) {
          errors.pushObject((0, _util.requiredError)("formReservation.".concat(suffix, ".label"), {
            component: (0, _util.ucFirst)(component)
          }));
        }
      });
      return errors;
    },
    updateCpuMemoryPreRequest: function updateCpuMemoryPreRequest() {
      var answers = Ember.get(this, 'app.answers') || {};
      var workloads = this.getEnalbedWorkloads(answers);
      var preRequestsCpu = workloads.reduce(function (all, current) {
        var value = answers["".concat(current, ".resources.requests.cpu")];
        return value ? all + (0, _util.convertToMillis)(value) : all;
      }, 0);
      var preRequestsMemory = workloads.reduce(function (all, current) {
        var value = answers["".concat(current, ".resources.requests.memory")];
        return value ? all + (0, _parseUnit.parseSi)(value) / 1048576 : all;
      }, 0);
      Ember.setProperties(this, {
        preRequestsCpu: preRequestsCpu,
        preRequestsMemory: preRequestsMemory
      });
    }
  });

  _exports.default = _default;
});