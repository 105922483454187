define("@rancher/ember-api-store/services/store", ["exports", "@rancher/ember-api-store/mixins/serializable", "@rancher/ember-api-store/models/error", "@rancher/ember-api-store/utils/normalize", "@rancher/ember-api-store/utils/apply-headers", "@rancher/ember-api-store/utils/url-options", "@rancher/ember-api-store/utils/fetch", "set-cookie-parser"], function (_exports, _serializable, _error, _normalize, _applyHeaders, _urlOptions, _fetch, _setCookieParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.defaultMetaKeys = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var getOwner = Ember.getOwner;

  function getOwnerKey() {
    var x = {};
    Ember.setOwner(x);
    return Object.keys(x)[0];
  }

  var ownerKey = getOwnerKey();
  var defaultMetaKeys = ['actionLinks', 'createDefaults', 'createTypes', 'filters', 'links', 'pagination', 'resourceType', 'sort', 'sortLinks', 'type'];
  _exports.defaultMetaKeys = defaultMetaKeys;
  var Store = Ember.Service.extend({
    cookies: Ember.inject.service(),
    defaultTimeout: 30000,
    defaultPageSize: 1000,
    baseUrl: '/v1',
    metaKeys: null,
    replaceActions: 'actionLinks',
    dropKeys: null,
    headers: null,
    arrayProxyClass: Ember.ArrayProxy,
    arrayProxyKey: 'content',
    arrayProxyOptions: null,
    // true: automatically remove from store after a record.delete() succeeds.  You might want to disable this if your API has a multi-step deleted vs purged state.
    removeAfterDelete: true,
    fastboot: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:fastboot');
    }),
    init: function init() {
      this._super();

      if (!Ember.get(this, 'metaKeys')) {
        Ember.set(this, 'metaKeys', defaultMetaKeys.slice());
      }

      this._state = {
        cache: null,
        cacheMap: null,
        shoebox: null,
        classCache: null,
        foundAll: null,
        findQueue: null,
        watchHasMany: null,
        watchReference: null,
        missingReference: null
      };
      this.reset();
    },
    // All the saved state goes in here
    _state: null,
    // You can observe this to tell when a reset() happens
    generation: 0,
    initFastBoot: function initFastBoot(name) {
      var _this = this;

      var fastboot = Ember.get(this, 'fastboot');

      if (fastboot) {
        if (fastboot.get('isFastBoot')) {
          var shoebox = this._state.shoebox = {};
          fastboot.get('shoebox').put(name, {
            shoebox: shoebox,
            foundAll: this._state.foundAll
          });
        } else {
          var box = fastboot.get('shoebox').retrieve(name);

          if (box) {
            Object.keys(box.foundAll || {}).forEach(function (key) {
              _this._state.foundAll[key] = box.foundAll[key];
            });
            Object.keys(box.shoebox || {}).forEach(function (type) {
              var map = box.shoebox[type];
              Object.values(map).forEach(function (pojo) {
                _this._typeify(pojo);
              });
            });
          }
        }
      }
    },
    // Synchronously get record from local cache by [type] and [id].
    // Returns undefined if the record is not in cache, does not talk to API.
    getById: function getById(type, id) {
      type = (0, _normalize.normalizeType)(type, this);

      var group = this._groupMap(type);

      if (group) {
        return group[id];
      }
    },
    // Synchronously returns whether record for [type] and [id] is in the local cache.
    hasRecordFor: function hasRecordFor(type, id) {
      return !!this.getById(type, id);
    },
    // Synchronously returns whether this exact record object is in the local cache
    hasRecord: function hasRecord(obj) {
      if (!obj) {
        return false;
      }

      var type = (0, _normalize.normalizeType)(obj.get('type'), this);

      var group = this._groupMap(type);

      return group[obj.get('id')] === obj;
    },
    hasType: function hasType(name) {
      var type = (0, _normalize.normalizeType)(name, this);

      var group = this._groupMap(type, false);

      return !!group;
    },
    isCacheable: function isCacheable(opt) {
      return !opt || opt.depaginate && !opt.filter && !opt.forceReload;
    },
    // Asynchronous, returns promise.
    // find(type[,null, opt]): Query API for all records of [type]
    // find(type,id[,opt]): Query API for record [id] of [type]
    // opt:
    //  filter: Filter by fields, e.g. {field: value, anotherField: anotherValue} (default: none)
    //  forceReload: Ask the server even if the type+id is already in cache. (default: false)
    //  limit: Number of reqords to return per page (default: 1000)
    //  depaginate: If the response is paginated, retrieve all the pages. (default: true)
    //  headers: Headers to send in the request (default: none).  Also includes ones specified in the model constructor.
    //  url: Use this specific URL instead of looking up the URL for the type/id.  This should only be used for bootstraping schemas on startup.
    find: function find(type, id, opt) {
      var _this2 = this;

      type = (0, _normalize.normalizeType)(type, this);
      opt = opt || {};
      opt.depaginate = opt.depaginate !== false;

      if (!id && !opt.limit) {
        opt.limit = this.defaultPageSize;
      }

      if (!type) {
        return Ember.RSVP.reject(_error.default.create({
          detail: 'type not specified'
        }));
      } // If this is a request for all of the items of [type], then we'll remember that and not ask again for a subsequent request


      var isCacheable = this.isCacheable(opt);
      opt.isForAll = !id && isCacheable; // See if we already have this resource, unless forceReload is on.

      if (opt.forceReload !== true) {
        if (opt.isForAll && this._state.foundAll[type]) {
          return Ember.RSVP.resolve(this.all(type), 'Cached find all ' + type);
        } else if (isCacheable && id) {
          var existing = this.getById(type, id);

          if (existing) {
            return Ember.RSVP.resolve(existing, 'Cached find ' + type + ':' + id);
          }
        }
      } // If URL is explicitly given, go straight to making the request.  Do not pass go, do not collect $200.
      // This is used for bootstraping to load the schema initially, and shouldn't be used for much else.


      if (opt.url) {
        return this._findWithUrl(opt.url, type, opt);
      } else {
        // Otherwise lookup the schema for the type and generate the URL based on it.
        return this.find('schema', type, {
          url: 'schemas/' + encodeURIComponent(type)
        }).then(function (schema) {
          if (schema) {
            var url = schema.linkFor('collection') + (id ? '/' + encodeURIComponent(id) : '');

            if (url) {
              return _this2._findWithUrl(url, type, opt);
            }
          }

          return Ember.RSVP.reject(_error.default.create({
            detail: 'Unable to find schema for "' + type + '"'
          }));
        });
      }
    },
    // Returns a 'live' array of all records of [type] in the cache.
    all: function all(type) {
      type = (0, _normalize.normalizeType)(type, this);

      var group = this._group(type);

      return this._createArrayProxy(group);
    },
    haveAll: function haveAll(type) {
      type = (0, _normalize.normalizeType)(type, this);
      return this._state.foundAll[type];
    },
    // find(type) && return all(type)
    findAll: function findAll(type, opt) {
      var _this3 = this;

      type = (0, _normalize.normalizeType)(type, this);
      opt = opt || {};

      if (this.haveAll(type) && this.isCacheable(opt)) {
        return Ember.RSVP.resolve(this.all(type), 'All ' + type + ' already cached');
      } else {
        return this.find(type, undefined, opt).then(function () {
          return _this3.all(type);
        });
      }
    },
    normalizeUrl: function normalizeUrl(url) {
      var includingAbsolute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var fastboot = Ember.get(this, 'fastboot');
      var origin;

      if (fastboot && fastboot.isFastBoot) {
        origin = "".concat(fastboot.request.protocol, "//").concat(fastboot.request.host);
      } else {
        origin = window.location.origin;
      } // Make absolute URLs to ourselves root-relative


      if (includingAbsolute && url.indexOf(origin) === 0) {
        url = url.substr(origin.length);
      } // Make relative URLs root-relative


      if (!url.match(/^https?:/) && url.indexOf('/') !== 0) {
        url = Ember.get(this, 'baseUrl').replace(/\/\+$/, '') + '/' + url;
      } // For fastboot everything has to be absolute


      if (fastboot && !url.match(/^https?:/)) {
        url = "".concat(origin).concat(url);
      }

      return url;
    },
    // Makes an AJAX request and returns a promise that resolves to an object
    // This is separate from request() so it can be mocked for tests, or if you just want a basic AJAX request.
    rawRequest: function rawRequest(opt) {
      var cookieSvc = Ember.get(this, 'cookies');
      opt.url = this.normalizeUrl(opt.url);
      opt.headers = this._headers(opt.headers);
      var fastboot = Ember.get(this, 'fastboot');

      if (fastboot && fastboot.isFastBoot) {
        var cookies = cookieSvc.read(null, {
          raw: true
        });
        var ary = [];
        Object.keys(cookies).forEach(function (k) {
          ary.push("".concat(k, "=").concat(cookies[k]));
        });
        opt.headers['cookie'] = ary.join('; ');
      }

      opt.processData = false;

      if (typeof opt.dataType === 'undefined') {
        opt.dataType = 'text'; // Don't let jQuery JSON parse
      }

      if (opt.timeout !== null && !opt.timeout) {
        opt.timeout = this.defaultTimeout;
      }

      if (opt.data) {
        if (!opt.contentType) {
          opt.contentType = 'application/json';
        }

        if (_serializable.default.detect(opt.data)) {
          opt.data = JSON.stringify(opt.data.serialize());
        } else if (_typeof(opt.data) === 'object') {
          opt.data = JSON.stringify(opt.data);
        }
      }

      var out = (0, _fetch.default)(opt.url, opt);

      if (fastboot && fastboot.isFastBoot) {
        var method = opt.method || 'GET';
        return out.then(function (res) {
          copyCookies(res);
          console.log('[Fastboot Fetch]', method, opt.url, res.status);
          return res;
        }).catch(function (err) {
          copyCookies(err);
          console.log('[Fastboot Fetch Error]', method, opt.url, err.status);
          return Ember.RSVP.reject(err);
        });
      }

      return out; // Copy cookies from the request's response to the fastboot response

      function copyCookies(obj) {
        if (!obj || !obj.headers) {
          return;
        }

        var headers = obj.headers.get('set-cookie');

        if (headers) {
          (0, _setCookieParser.parse)(headers).forEach(function (opts) {
            var name = opts.name;
            var value = opts.value;
            delete opts.name;
            delete opts.value;
            opts.raw = true;
            cookieSvc.write(name, value, opts);
          });
        }
      }
    },
    // Makes an AJAX request that resolves to a resource model
    request: function request(opt) {
      var _this4 = this;

      opt.url = this.normalizeUrl(opt.url);
      opt.depaginate = opt.depaginate !== false;

      if (this.mungeRequest) {
        opt = this.mungeRequest(opt);
      }

      return this.rawRequest(opt).then(function (xhr) {
        return _this4._requestSuccess(xhr, opt);
      }).catch(function (xhr) {
        return _this4._requestFailed(xhr, opt);
      });
    },
    // Forget about all the resources that hae been previously remembered.
    reset: function reset() {
      var state = this._state;
      var cache = state.cache;

      if (cache) {
        Object.keys(cache).forEach(function (key) {
          if (cache[key] && cache[key].clear) {
            cache[key].clear();
          }
        });
      } else {
        state.cache = {};
      }

      var foundAll = state.foundAll;

      if (foundAll) {
        Object.keys(foundAll).forEach(function (key) {
          foundAll[key] = false;
        });
      } else {
        state.foundAll = {};
      }

      if (state.shoebox) {
        state.shoebox = {};
      }

      state.cacheMap = {};
      state.findQueue = {};
      state.classCache = [];
      state.watchHasMany = {};
      state.watchReference = {};
      state.missingReference = {};
      this.incrementProperty('generation');
    },
    resetType: function resetType(type) {
      type = (0, _normalize.normalizeType)(type, this);

      var group = this._group(type);

      this._state.foundAll[type] = false;
      this._state.cacheMap[type] = {};

      if (this._state.shoebox) {
        this._state.shoebox[type] = {};
      }

      group.clear();
    },
    // ---------
    // Below here be dragons
    // ---------
    _createArrayProxy: function _createArrayProxy(content) {
      var data = _defineProperty({}, this.arrayProxyKey, content);

      var opt = Ember.get(this, 'arrayProxyOptions') || {};
      Object.keys(opt).forEach(function (key) {
        data[key] = opt[key];
      });
      return this.arrayProxyClass.create(data);
    },
    _headers: function _headers(perRequest) {
      var out = {
        'accept': 'application/json',
        'content-type': 'application/json'
      };
      (0, _applyHeaders.applyHeaders)(Ember.get(this, 'headers'), out);
      (0, _applyHeaders.applyHeaders)(perRequest, out);
      return out;
    },
    _findWithUrl: function _findWithUrl(url, type, opt) {
      var _this5 = this;

      var queue = this._state.findQueue;
      var cls = getOwner(this).lookup('model:' + type);
      url = (0, _urlOptions.urlOptions)(url, opt, cls); // Collect Headers

      var newHeaders = {};

      if (cls && cls.constructor.headers) {
        (0, _applyHeaders.applyHeaders)(cls.constructor.headers, newHeaders, true);
      }

      (0, _applyHeaders.applyHeaders)(opt.headers, newHeaders, true); // End: Collect headers

      var later;
      var queueKey = JSON.stringify(newHeaders) + url; // check to see if the request is in the findQueue

      if (queue[queueKey]) {
        // get the filterd promise object
        var filteredPromise = queue[queueKey];
        var defer = Ember.RSVP.defer();
        filteredPromise.push(defer);
        later = defer.promise;
      } else {
        // request is not in the findQueue
        opt.url = url;
        opt.headers = newHeaders;
        later = this.request(opt).then(function (result) {
          if (opt.isForAll) {
            _this5._state.foundAll[type] = true;

            if (opt.removeMissing && result.type === 'collection') {
              var all = _this5._group(type);

              var toRemove = [];
              all.forEach(function (obj) {
                if (!result.includes(obj)) {
                  toRemove.push(obj);
                }
              });
              toRemove.forEach(function (obj) {
                _this5._remove(type, obj);
              });
            }
          }

          _this5._finishFind(queueKey, result, 'resolve');

          return result;
        }, function (reason) {
          _this5._finishFind(queueKey, reason, 'reject');

          return Ember.RSVP.reject(reason);
        }); // set the queue array to empty indicating we've had 1 promise already

        queue[queueKey] = [];
      }

      return later;
    },
    _finishFind: function _finishFind(key, result, action) {
      var queue = this._state.findQueue;
      var promises = queue[key];

      if (promises) {
        while (promises.length) {
          if (action === 'resolve') {
            promises.pop().resolve(result);
          } else if (action === 'reject') {
            promises.pop().reject(result);
          }
        }
      }

      delete queue[key];
    },
    _requestSuccess: function _requestSuccess(xhr, opt) {
      var _this6 = this;

      opt.responseStatus = xhr.status;

      if (xhr.status === 204) {
        return;
      }

      if (xhr.body && _typeof(xhr.body) === 'object') {
        Ember.beginPropertyChanges();

        var response = this._typeify(xhr.body);

        delete xhr.body;
        Object.defineProperty(response, 'xhr', {
          value: xhr,
          configurable: true
        });
        Ember.endPropertyChanges(); // Depaginate

        if (opt.depaginate && typeof response.depaginate === 'function') {
          return response.depaginate().then(function () {
            return response;
          }).catch(function (xhr) {
            return _this6._requestFailed(xhr, opt);
          });
        } else {
          return response;
        }
      } else {
        return xhr.body;
      }
    },
    _requestFailed: function _requestFailed(xhr, opt) {
      var body;

      if (xhr.err) {
        if (xhr.err === 'timeout') {
          body = _error.default.create({
            code: 'Timeout',
            status: xhr.status,
            message: "API request timeout (".concat(opt.timeout / 1000, " sec)"),
            detail: (opt.method || 'GET') + ' ' + opt.url
          });
        } else {
          body = _error.default.create({
            code: 'Xhr',
            status: xhr.status,
            message: xhr.err
          });
        }

        return finish(body);
      } else if (xhr.body && _typeof(xhr.body) === 'object') {
        Ember.beginPropertyChanges();
        var out = finish(this._typeify(xhr.body));
        Ember.endPropertyChanges();
        return out;
      } else {
        body = _error.default.create({
          status: xhr.status,
          message: xhr.body || xhr.message
        });
        return finish(body);
      }

      function finish(body) {
        if (!_error.default.detectInstance(body)) {
          body = _error.default.create(body);
        }

        delete xhr.body;
        Object.defineProperty(body, 'xhr', {
          value: xhr,
          configurable: true
        });
        return Ember.RSVP.reject(body);
      }
    },
    // Get the cache array group for [type]
    _group: function _group(type) {
      type = (0, _normalize.normalizeType)(type, this);
      var cache = this._state.cache;
      var group = cache[type];

      if (!group) {
        group = [];
        cache[type] = group;
      }

      return group;
    },
    // Get the cache map group for [type]
    _groupMap: function _groupMap(type) {
      var autoCreate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      type = (0, _normalize.normalizeType)(type, this);
      var cache = this._state.cacheMap;
      var group = cache[type];

      if (!group && autoCreate) {
        group = {};
        cache[type] = group;
      }

      return group;
    },
    // Get the shoebox group for [type]
    _shoebox: function _shoebox(type) {
      type = (0, _normalize.normalizeType)(type, this);
      var box = this._state.shoebox;

      if (!box) {
        return null;
      }

      var group = box[type];

      if (!group) {
        group = {};
        box[type] = group;
      }

      return group;
    },
    // Add a record instance of [type] to cache
    _add: function _add(type, obj) {
      type = (0, _normalize.normalizeType)(type, this);
      var id = obj.id;

      var group = this._group(type);

      var groupMap = this._groupMap(type);

      var shoebox = this._shoebox(type);

      group.pushObject(obj);
      groupMap[obj.id] = obj;

      if (shoebox) {
        shoebox[obj.id] = obj.serialize();
      } // Update hasMany relationships


      var watches = this._state.watchHasMany[type] || [];
      var notify = [];
      var watch, val;

      for (var i = 0; i < watches.length; i++) {
        watch = watches[i];
        val = obj.get(watch.targetField);
        notify.push({
          type: watch.thisType,
          id: val,
          field: watch.thisField,
          sourceStore: watch.sourceStore
        });
      } // Update references relationships that have been looking for this resource


      var key = type + ':' + id;
      var missings = this._state.missingReference[key];

      if (missings) {
        notify.pushObjects(missings);
        delete this._state.missingReference[key];
      }

      this.notifyFieldsChanged(notify);

      if (obj.wasAdded && typeof obj.wasAdded === 'function') {
        obj.wasAdded();
      }
    },
    // Add a lot of instances of the same type quickly.
    //   - There must be a model for the type already defined.
    //   - Instances cannot contain any nested other types (e.g. subtypes),
    //     (they will not be deserialized into their correct type.)
    //   - wasAdded hooks are not called
    // Basically this is just for loading schemas faster.
    _bulkAdd: function _bulkAdd(type, pojos) {
      var _this7 = this;

      type = (0, _normalize.normalizeType)(type, this);

      var group = this._group(type);

      var groupMap = this._groupMap(type);

      var shoebox = this._shoebox(type);

      var cls = getOwner(this).lookup('model:' + type);
      group.pushObjects(pojos.map(function (input) {
        // actions is very unhappy property name for Ember...
        if (_this7.replaceActions && typeof input.actions !== 'undefined') {
          input[_this7.replaceActions] = input.actions;
          delete input.actions;
        } // Schemas are special


        if (type === 'schema') {
          input._id = input.id;
          input.id = (0, _normalize.normalizeType)(input.id, _this7);
        }

        input.store = _this7;
        var obj = cls.constructor.create(input);
        groupMap[obj.id] = obj;

        if (shoebox) {
          shoebox[obj._id || obj.id] = obj.serialize();
        }

        return obj;
      }));
    },
    // Remove a record of [type] from cache, given the id or the record instance.
    _remove: function _remove(type, obj) {
      type = (0, _normalize.normalizeType)(type, this);
      var id = obj.id;

      var group = this._group(type);

      var groupMap = this._groupMap(type);

      var shoebox = this._shoebox(type);

      group.removeObject(obj);
      delete groupMap[id];

      if (shoebox) {
        delete shoebox[id];
      } // Update hasMany relationships that refer to this resource


      var watches = this._state.watchHasMany[type] || [];
      var notify = [];
      var watch;

      for (var i = 0; i < watches.length; i++) {
        watch = watches[i];
        notify.push({
          type: watch.thisType,
          id: obj.get(watch.targetField),
          field: watch.thisField
        });
      } // Update references relationships that have used this resource


      var key = type + ':' + id;
      var existing = this._state.watchReference[key];

      if (existing) {
        notify.pushObjects(existing);
        delete this._state.watchReference[key];
      }

      this.notifyFieldsChanged(notify);

      if (obj.wasRemoved && typeof obj.wasRemoved === 'function') {
        obj.wasRemoved();
      } // If there's a different baseType, remove that one too


      var baseType = (0, _normalize.normalizeType)(obj.baseType, this);

      if (baseType && type !== baseType) {
        this._remove(baseType, obj);
      }
    },
    // Turn a POJO into a Model: {updateStore: true}
    _typeify: function _typeify(input) {
      var _this8 = this;

      var opt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (!input || _typeof(input) !== 'object') {
        // Simple values can just be returned
        return input;
      }

      if (!opt) {
        opt = {
          applyDefaults: false
        };
      }

      var type = Ember.get(input, 'type');

      if (Ember.isArray(input)) {
        // Recurse over arrays
        return input.map(function (x) {
          return _this8._typeify(x, opt);
        });
      } else if (!type) {
        // If it doesn't have a type then there's no sub-fields to typeify
        return input;
      }

      type = (0, _normalize.normalizeType)(type, this);

      if (type === 'collection') {
        return this.createCollection(input, opt);
      } else if (!type) {
        return input;
      }

      var rec = this.createRecord(input, opt);

      if (!input.id || opt.updateStore === false) {
        return rec;
      } // This must be after createRecord so that mangleIn() can change the baseType


      var baseType = (0, _normalize.normalizeType)(rec.get('baseType'), this);

      if (baseType) {
        // Only use baseType if it's different from type
        if (baseType === type) {
          baseType = null;
        }
      }

      var out = rec;
      var cacheEntry = this.getById(type, rec.id);

      if (cacheEntry) {
        // Check for hasMany relationship changes
        var watches = (this._state.watchHasMany[type] || []).slice();
        var notify = [];

        if (baseType) {
          watches.addObjects(this._state.watchHasMany[baseType] || []);
        }

        var watch, oldVal, newVal;

        for (var i = 0; i < watches.length; i++) {
          watch = watches[i];
          oldVal = cacheEntry.get(watch.targetField);
          newVal = rec.get(watch.targetField); //console.log('Compare', watch.targetField, oldVal, 'to', newVal);

          if (oldVal !== newVal) {
            notify.push({
              type: watch.thisType,
              id: oldVal,
              field: watch.thisField
            });
            notify.push({
              type: watch.thisType,
              id: newVal,
              field: watch.thisField
            });
          }
        }

        cacheEntry.replaceWith(rec);
        out = cacheEntry; // Update changed hasMany's

        this.notifyFieldsChanged(notify);
      } else {
        this._add(type, rec);

        if (baseType) {
          this._add(baseType, rec);
        }
      }

      return out;
    },
    notifyFieldsChanged: function notifyFieldsChanged(ary) {
      var entry, tgt;

      for (var i = 0; i < ary.length; i++) {
        entry = ary[i];

        if (entry.sourceStore) {
          tgt = entry.sourceStore.getById(entry.type, entry.id);
        } else {
          tgt = this.getById(entry.type, entry.id);
        }

        if (tgt) {
          //console.log('Notify', entry.type, entry.id, 'that', entry.field,'changed');
          tgt.notifyPropertyChange(entry.field);
        }
      }
    },
    // Create a collection: {key: 'data'}
    createCollection: function createCollection(input, opt) {
      var _this9 = this;

      Ember.beginPropertyChanges();
      var key = opt && opt.key ? opt.key : 'data';
      var cls = getOwner(this).lookup('model:collection');
      var content = input[key].map(function (x) {
        return _this9._typeify(x, opt);
      });
      var output = cls.constructor.create({
        content: content
      });
      Object.defineProperty(output, 'store', {
        value: this,
        configurable: true
      });
      output.setProperties(Ember.getProperties(input, Ember.get(this, 'metaKeys')));
      Ember.endPropertyChanges();
      return output;
    },
    getClassFor: function getClassFor(type) {
      var cls = this._state.classCache[type];

      if (cls) {
        return cls;
      }

      var owner = getOwner(this);

      if (type) {
        cls = owner.lookup('model:' + type);
      }

      if (!cls) {
        cls = owner.lookup('model:resource');
      }

      this._state.classCache[type] = cls;
      return cls;
    },
    canCreate: function canCreate(type) {
      var schema = this.getById('schema', type);
      return schema && schema.collectionMethods && schema.collectionMethods.indexOf('POST') > -1;
    },
    canList: function canList(type) {
      var schema = this.getById('schema', type);
      return schema && schema.collectionMethods && schema.collectionMethods.indexOf('GET') > -1;
    },
    // Create a record: {applyDefaults: false}
    createRecord: function createRecord(data, opt) {
      opt = opt || {};
      var type = (0, _normalize.normalizeType)(Ember.get(opt, 'type') || Ember.get(data, 'type') || '', this);
      var cls;

      if (type) {
        cls = this.getClassFor(type);
      }

      var schema = this.getById('schema', type);
      var input = data;

      if (opt.applyDefaults !== false && schema) {
        input = schema.getCreateDefaults(data);
      } // actions is very unhappy property name for Ember...


      if (this.replaceActions && typeof input.actions !== 'undefined') {
        input[this.replaceActions] = input.actions;
        delete input.actions;
      }

      var cons = cls.constructor;

      if (cons.mangleIn && typeof cons.mangleIn === 'function') {
        input = cons.mangleIn(input, this);
      }

      if (schema) {
        var fields = schema.get('typeifyFields');

        for (var i = fields.length - 1; i >= 0; i--) {
          var k = fields[i];

          if (input[k]) {
            input[k] = this._typeify(input[k], opt);
          }
        }
      }

      var output = cons.create(input);
      Object.defineProperty(output, ownerKey, {
        enumerable: false,
        value: Ember.getOwner(this)
      });
      Object.defineProperty(output, 'store', {
        enumerable: false,
        value: this,
        configurable: true
      });
      return output;
    }
  });
  var _default = Store;
  _exports.default = _default;
});