define("shared/components/k3s-node-env-var/component", ["exports", "shared/components/k3s-node-env-var/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    name: 'key',
    sort: ['key'],
    translationKey: 'annotationsSection.key'
  }, {
    name: 'value',
    sort: ['value', 'key'],
    translationKey: 'annotationsSection.value'
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['col', 'span-12'],
    node: null,
    headers: HEADERS
  });

  _exports.default = _default;
});