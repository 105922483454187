define("shared/components/cru-node-pools/component", ["exports", "shared/components/cru-node-pools/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var headers = [{
    name: 'hostnamePrefix',
    sort: ['hostnamePrefix', 'id'],
    translationKey: 'clusterNew.rke.nodes.hostnamePrefix',
    scope: 'embedded'
  }, {
    name: 'count',
    sort: ['quantity', 'displayName.id'],
    translationKey: 'clusterNew.rke.nodes.count',
    width: 100
  }, {
    name: 'nodeTemplate',
    sort: ['nodeTemplate.displayName', 'nodeTemplate.id'],
    translationKey: 'clusterNew.rke.nodes.template'
  }, {
    name: 'deleteNotReadyAfterSecs',
    sort: ['deleteNotReadyAfterSecs'],
    translationKey: 'clusterNew.rke.nodes.deleteNotReadyAfterSecs',
    width: 155
  }, {
    name: 'aggressivedrain',
    sort: false,
    scope: 'embedded',
    translationKey: 'clusterNew.rke.nodes.drainBeforeDelete',
    classNames: ['pl-5'],
    width: 150
  }, {
    name: 'etcd',
    sort: false,
    translationKey: 'clusterNew.rke.role.header.etcd',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'controlplane',
    sort: false,
    translationKey: 'clusterNew.rke.role.header.controlplane',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'worker',
    sort: false,
    translationKey: 'clusterNew.rke.role.header.worker',
    scope: 'embedded',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'advanced',
    sort: false,
    scope: 'embedded',
    translationKey: 'clusterNew.rke.nodes.taints',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'remove',
    sort: false,
    classNames: ['text-center'],
    width: 35
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    nodeTemplates: null,
    driver: null,
    // docker-machine driver
    originalPools: null,
    nodePools: null,
    errors: null,
    taintCapabilites: Ember.computed.alias('scope.currentCluster.capabilities.taintSupport'),
    init: function init() {
      this._super.apply(this, arguments);

      var originalPools = (Ember.get(this, 'cluster.nodePools') || []).slice();
      Ember.set(this, 'originalPools', originalPools);
      Ember.set(this, 'nodePools', originalPools.slice().map(function (p) {
        return p.clone();
      }));

      if (Ember.get(this, 'mode') === 'new' && Ember.get(originalPools, 'length') === 0) {
        Ember.get(this, 'nodePools').pushObject(Ember.get(this, 'globalStore').createRecord({
          type: 'nodePool',
          quantity: 1,
          worker: true
        }));
      }

      if (this.registerHook) {
        this.registerHook(this.savePools.bind(this), 'savePools');
      }

      this.setDefaultNodeTemplate();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.validate();
    },
    actions: {
      addPool: function addPool() {
        var nodePools = Ember.get(this, 'nodePools');
        var templateId = null;
        var lastNode = nodePools[nodePools.length - 1];

        if (lastNode) {
          templateId = Ember.get(lastNode, 'nodeTemplateId');
        }

        nodePools.pushObject(Ember.get(this, 'globalStore').createRecord({
          type: 'nodePool',
          nodeTemplateId: templateId,
          worker: true
        }));
      },
      removePool: function removePool(pool) {
        Ember.get(this, 'nodePools').removeObject(pool);
      },
      addNodeTemplate: function addNodeTemplate(node) {
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          nodeTemplate: null,
          driver: Ember.get(this, 'driver'),
          onAdd: function onAdd(nodeTemplate) {
            Ember.set(node, 'nodeTemplateId', Ember.get(nodeTemplate, 'id'));
          }
        });
      },
      configAdvancedOptions: function configAdvancedOptions(pool) {
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-pool', pool);
      }
    },
    setDefaultNodeTemplate: Ember.observer('driver', function () {
      var templates = Ember.get(this, 'filteredNodeTemplates') || [];

      if (templates.length === 1) {
        (Ember.get(this, 'nodePools') || []).forEach(function (pool) {
          if (!Ember.get(pool, 'nodeTemplateId')) {
            Ember.set(pool, 'nodeTemplateId', Ember.get(templates, 'firstObject.id'));
          }
        });
      }
    }),
    driverChanged: Ember.observer('driver', function () {
      var driver = Ember.get(this, 'driver');
      Ember.get(this, 'nodePools').forEach(function (pool) {
        var tpl = Ember.get(pool, 'nodeTemplate');

        if (tpl && Ember.get(tpl, 'driver') !== driver) {
          Ember.set(pool, 'nodeTemplateId', null);
        }
      });
    }),
    validate: Ember.observer('etcdOk', 'controlPlaneOk', 'workerOk', 'nodePools.@each.{quantity,hostnamePrefix,nodeTemplateId}', 'nodePools.[]', 'driver', function () {
      var intl = Ember.get(this, 'intl');
      var driver = Ember.get(this, 'driver');
      var errors = [];

      if (Ember.get(this, 'mode') === 'new' && !Ember.get(this, 'etcdOk')) {
        errors.push(intl.t('clusterNew.rke.errors.etcd'));
      }

      if (!Ember.get(this, 'controlPlaneOk')) {
        errors.push(intl.t('clusterNew.rke.errors.controlPlane'));
      }

      if (!Ember.get(this, 'workerOk')) {
        errors.push(intl.t('clusterNew.rke.errors.worker'));
      }

      if (driver === 'digitalocean') {
        var nodePools = Ember.get(this, 'nodePools');
        var isError = false;
        nodePools.map(function (p) {
          var _p$hostnamePrefix = p.hostnamePrefix,
              hostnamePrefix = _p$hostnamePrefix === void 0 ? '' : _p$hostnamePrefix;

          if (hostnamePrefix.includes('_')) {
            isError = true;
          }
        });

        if (isError) {
          errors.push(intl.t('nodeDriver.digitalocean.errors.hostnamePrefix'));
        }
      }

      Ember.get(this, 'nodePools').forEach(function (pool) {
        // ClusterId is required but not known yet
        if (!Ember.get(pool, 'clusterId')) {
          Ember.set(pool, 'clusterId', '__later__');
        }

        errors.pushObjects(pool.validationErrors());
      });

      if (this.setNodePoolErrors) {
        this.setNodePoolErrors(errors);
      }
    }),
    showIamWarning: Ember.computed('driver', 'nodePools.@each.nodeTemplateId', 'cluster.rancherKubernetesEngineConfig.cloudProvider.name', function () {
      var cloudProvider = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.cloudProvider.name');
      var driver = Ember.get(this, 'driver');

      if (driver === 'amazonec2' && cloudProvider === 'aws') {
        var found = (Ember.get(this, 'nodePools') || []).some(function (pool) {
          var nodeTemplate = Ember.get(pool, 'nodeTemplate');
          return nodeTemplate && !Ember.get(nodeTemplate, 'amazonec2Config.iamInstanceProfile');
        });
        return found ? true : false;
      }

      return false;
    }),
    filteredNodeTemplates: Ember.computed('driver', 'nodeTemplates.@each.{state,driver}', 'nodePools.@each.nodeTemplateId', function () {
      var _this = this;

      var driver = Ember.get(this, 'driver');
      var templates = Ember.get(this, 'nodeTemplates').filterBy('state', 'active').filterBy('driver', driver);
      (Ember.get(this, 'nodePools') || []).forEach(function (pool) {
        var templateId = Ember.get(pool, 'nodeTemplateId');

        if (templateId && !templates.findBy('id', templateId)) {
          templates.push(Ember.get(_this, 'globalStore').createRecord({
            type: 'nodetemplate',
            id: templateId,
            name: "(".concat(templateId, ")")
          }));
        }
      });
      return templates;
    }),
    etcdOk: Ember.computed('nodePools.@each.{quantity,etcd}', function () {
      var count = this._nodeCountFor('etcd');

      return count === 1 || count === 3 || count === 5;
    }),
    etcdCount: Ember.computed('nodePools.@each.{quantity,etcd}', function () {
      return this._nodeCountFor('etcd');
    }),
    controlPlaneOk: Ember.computed('nodePools.@each.{quantity,controlPlane}', function () {
      var count = this._nodeCountFor('controlPlane');

      return count >= 1;
    }),
    workerOk: Ember.computed('nodePools.@each.{quantity,worker}', function () {
      var count = this._nodeCountFor('worker');

      return count >= 1;
    }),
    headers: Ember.computed('taintCapabilites', 'mode', function () {
      var taintCapabilites = this.taintCapabilites,
          mode = this.mode;

      if (taintCapabilites || mode === 'new') {
        return headers;
      } else {
        return headers.filter(function (h) {
          return h.name !== 'advanced';
        });
      }
    }),
    savePools: function savePools() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying || Ember.get(this, 'driver') === 'custom') {
        return;
      }

      var nodePools = Ember.get(this, 'nodePools');
      var original = Ember.get(this, 'originalPools');
      var remove = [];
      original.forEach(function (pool) {
        if (!nodePools.some(function (p) {
          return p.id === pool.id;
        })) {
          // Remove
          remove.push(pool);
        }
      });
      var clusterId = Ember.get(this, 'cluster.id');
      nodePools.forEach(function (pool) {
        var controlPlane = pool.controlPlane,
            etcd = pool.etcd,
            worker = pool.worker;
        Ember.set(pool, 'clusterId', clusterId);

        if (!controlPlane && !etcd && !worker) {
          Ember.set(pool, 'worker', true);
        }
      });
      return Ember.RSVP.all(nodePools.map(function (x) {
        return x.save();
      })).then(function () {
        return Ember.RSVP.all(remove.map(function (x) {
          return x.delete();
        })).then(function () {
          return Ember.get(_this2, 'cluster');
        });
      });
    },
    _nodeCountFor: function _nodeCountFor(role) {
      var count = 0;
      (Ember.get(this, 'nodePools') || []).filterBy(role, true).forEach(function (pool) {
        var more = Ember.get(pool, 'quantity');

        if (more) {
          more = parseInt(more, 10);
        }

        count += more;
      });
      return count;
    }
  });

  _exports.default = _default;
});