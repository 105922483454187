define("shared/components/storage-class/provisioner-generic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GN+oJEFP",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"initialMap\",\"addActionLabel\",\"editing\",\"changed\"],[[35,0],\"cruStorageClass.parameters.addActionLabel\",[35,3],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"parameters\",\"mut\",\"action\",\"editing\",\"form-key-value\"]}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-generic/template.hbs"
    }
  });

  _exports.default = _default;
});