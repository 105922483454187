define("global-admin/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hSBPSOlN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header has-tabs clearfix p-0\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"tab-nav\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"settings.advanced\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"settingsPage.tabs.settings\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\"],[\"settings.features\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"settingsPage.tabs.feature\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "global-admin/settings/template.hbs"
    }
  });

  _exports.default = _default;
});