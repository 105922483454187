define("shared/components/k3s-node-args/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yE+WISWD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"clusterDashboard.k3sInfo.nodeArgs.title\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"clusterDashboard.k3sInfo.nodeArgs.detail\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,1,[\"k3sNodeArgs\",\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"pre\"],[14,0,\"bg-setting\"],[14,5,\"font-size: 14px;\"],[12],[1,[35,1,[\"k3sNodeArgs\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"mt-20 text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"clusterDashboard.k3sInfo.nodeArgs.noArgs\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"node\",\"gte\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/k3s-node-args/template.hbs"
    }
  });

  _exports.default = _default;
});