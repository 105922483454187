define("shared/components/hover-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5W29nKmz",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[8,\"basic-dropdown\",[],[[\"@renderInPlace\",\"@verticalPosition\",\"@horizontalPosition\",\"@matchTriggerWidth\",\"@triggerComponent\",\"@contentComponent\",\"@registerAPI\",\"@calculatePosition\",\"@onOpen\",\"@onClose\",\"@onFocus\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[30,[36,6],[[32,0],\"registerAPI\"],null],[30,[36,6],[[32,0],\"calculatePosition\"],null],[34,7],[34,8],[34,9]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,[[30,[36,12],[[32,1],[30,[36,11],null,[[\"Trigger\",\"Content\"],[[30,[36,10],[[32,1,[\"Trigger\"]]],[[\"onMouseDown\",\"onMouseEnter\",\"onMouseLeave\"],[[30,[36,6],[[32,0],\"prevent\"],null],[30,[36,6],[[32,0],\"open\",false],null],[30,[36,6],[[32,0],\"close\",false],null]]]],[30,[36,10],[[32,1,[\"Content\"]]],[[\"onMouseEnter\",\"onMouseLeave\"],[[30,[36,6],[[32,0],\"open\",true],null],[30,[36,6],[[32,0],\"close\",true],null]]]]]]]],null]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"renderInPlace\",\"verticalPosition\",\"horizontalPosition\",\"matchTriggerWidth\",\"triggerComponent\",\"contentComponent\",\"action\",\"onOpen\",\"onClose\",\"onFocus\",\"component\",\"hash\",\"assign\"]}",
    "meta": {
      "moduleName": "shared/components/hover-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});