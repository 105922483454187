define("shared/components/modal-yaml/component", ["exports", "ui/mixins/new-or-edit", "codemirror", "js-yaml", "shared/mixins/modal-base", "shared/utils/fetch-yaml", "shared/components/modal-yaml/template"], function (_exports, _newOrEdit, _codemirror, _jsYaml, _modalBase, _fetchYaml, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    store: Ember.inject.service('store'),
    layout: _template.default,
    model: null,
    errors: null,
    compose: null,
    loading: null,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    resource: Ember.computed.alias('modalService.modalOpts.resource'),
    readOnly: Ember.computed.alias('modalService.modalOpts.readOnly'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      window.jsyaml || (window.jsyaml = _jsYaml.default);
      var resource = Ember.get(this, 'resource');

      if (resource && resource.links.yaml) {
        var yamlLink = resource.links.yaml;
        Ember.set(this, 'loading', true);
        return (0, _fetchYaml.default)(yamlLink).then(function (yaml) {
          Ember.set(_this, 'editing', true);
          Ember.set(_this, 'model', {
            resource: resource,
            yaml: yaml
          });
        }).catch(function (error) {
          Ember.set(_this, 'errors', [Ember.get(error, 'message') || Ember.get(error, 'xhr')]);
        }).finally(function () {
          Ember.set(_this, 'loading', false);
        });
      } else {
        Ember.set(this, 'editing', false);
        Ember.set(this, 'model', {
          resource: resource,
          yaml: ''
        });
      }
    },
    actions: {
      cancel: function cancel() {
        return this._super.apply(this, arguments);
      },
      close: function close() {
        return this._super.apply(this, arguments);
      },
      save: function save(success) {
        var _this2 = this;

        var model = Ember.get(this, 'model');
        var lintError = [];

        try {
          _jsYaml.default.safeLoadAll(model.yaml, function (y) {
            lintError.pushObjects(_codemirror.default.lint.yaml(y));
          });
        } catch (error) {
          Ember.set(this, 'errors', [error.message || error.xhr]);
          success(false);
          return;
        }

        if (lintError.length) {
          Ember.set(this, 'errors', [Ember.get(this, 'intl').t('yamlPage.errors')]);
          success(false);
          return;
        }

        Ember.set(this, 'errors', null);
        var resource = model.resource;

        if (resource) {
          Ember.get(this, 'store').request({
            data: JSON.stringify(_jsYaml.default.load(model.yaml)),
            url: resource.links.yaml,
            method: 'PUT'
          }).then(function () {
            _this2.send('cancel');
          }).catch(function (error) {
            Ember.set(_this2, 'errors', [Ember.get(error, 'message') || Ember.get(error, 'xhr')]);
            success(false);
          });
          return;
        }
      }
    },
    lintObserver: Ember.observer('model.yaml', function () {
      var yaml = Ember.get(this, 'model.yaml');
      var lintError = [];

      _jsYaml.default.safeLoadAll(yaml, function (y) {
        lintError.pushObjects(_codemirror.default.lint.yaml(y));
      });

      if (lintError.length) {
        Ember.set(this, 'errors', null);
      }
    }),
    filename: Ember.computed('model.resource', function () {
      var resource = Ember.get(this, 'model.resource');

      if (resource) {
        return "".concat(resource.name, ".yaml");
      }

      return 'kubenetes.yaml';
    })
  });

  _exports.default = _default;
});