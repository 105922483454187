define("ember-template-compiler/lib/plugins/utils", ["exports", "ember-babel"], function (_exports, _emberBabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPath = isPath;
  _exports.isSubExpression = isSubExpression;
  _exports.trackLocals = trackLocals;

  function isPath(node) {
    return node.type === 'PathExpression';
  }

  function isSubExpression(node) {
    return node.type === 'SubExpression';
  }

  function trackLocals() {
    var locals = new Map();
    var node = {
      enter: function enter(node) {
        for (var _iterator = (0, _emberBabel.createForOfIteratorHelperLoose)(node.blockParams), _step; !(_step = _iterator()).done;) {
          var param = _step.value;
          var value = locals.get(param) || 0;
          locals.set(param, value + 1);
        }
      },
      exit: function exit(node) {
        for (var _iterator2 = (0, _emberBabel.createForOfIteratorHelperLoose)(node.blockParams), _step2; !(_step2 = _iterator2()).done;) {
          var param = _step2.value;
          var value = locals.get(param) - 1;

          if (value === 0) {
            locals.delete(param);
          } else {
            locals.set(param, value);
          }
        }
      }
    };
    return {
      hasLocal: function hasLocal(key) {
        return locals.has(key);
      },
      node: node
    };
  }
});