define("global-admin/components/site-access/component", ["exports", "ui/utils/errors", "global-admin/components/site-access/template"], function (_exports, _errors, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    tagName: 'section',
    classNames: ['well'],
    model: null,
    individuals: 'siteAccess.users',
    collection: 'siteAccess.groups',
    principals: null,
    saved: true,
    errors: null,
    actions: {
      addAuthorized: function addAuthorized(principal) {
        if (!principal) {
          return;
        }

        this.send('clearError');
        Ember.set(this, 'saved', false);

        if (this.checkDuplicate(principal)) {
          this.send('gotError', Ember.get(this, 'intl').t('siteAccess.dupe'));
        } else {
          Ember.get(this, 'model.allowedPrincipalIds').pushObject(principal.id);
        }
      },
      refreshAllTokens: function refreshAllTokens() {
        var _this = this;

        Ember.set(this, 'refreshing', true);
        this.globalStore.request({
          url: '/v3/users?action=refreshauthprovideraccess',
          method: 'POST',
          data: {}
        }).catch(function (err) {
          Ember.set(_this, 'errors', [err.message]);
        }).finally(function () {
          Ember.set(_this, 'refreshing', false);
        });
      },
      removeAuthorized: function removeAuthorized(id) {
        Ember.set(this, 'saved', false);
        Ember.get(this, 'model.allowedPrincipalIds').removeObject(id);
      },
      save: function save(btnCb) {
        var _this2 = this;

        this.send('clearError');

        if (Ember.get(this, 'model.accessMode') !== 'unrestricted' && !Ember.get(this, 'model.allowedPrincipalIds.length')) {
          this.send('gotError', Ember.get(this, 'intl').t('siteAccess.minimum'));
          btnCb();
          return;
        }

        Ember.set(this, 'saved', false);
        var model = Ember.get(this, 'model');
        model.save().then(function () {
          Ember.set(_this2, 'saved', true);
        }).catch(function (err) {
          _this2.send('gotError', err);
        }).finally(function () {
          btnCb();
        });
      },
      cancel: function cancel() {
        if (this.cancel) {
          this.cancel();
        } else {
          this.router.transitionTo('global-admin.security.authentication');
        }
      },
      gotError: function gotError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      },
      clearError: function clearError() {
        Ember.set(this, 'errors', null);
        Ember.setProperties(this, {
          errors: null
        });
      }
    },
    showList: Ember.computed('model.accessMode', function () {
      return Ember.get(this, 'model.accessMode') !== 'unrestricted';
    }),
    accessModeChanged: Ember.on('init', Ember.observer('model.accessMode', function () {
      Ember.set(this, 'saved', false);
      var allowedPrincipals = Ember.get(this, 'model.allowedPrincipalIds') || []; // ['princ_id1://yada']

      if (Ember.get(this, 'model.accessMode') !== 'unrestricted') {
        var found = false;
        var myPIds = Ember.get(this, 'access.me.principalIds');
        myPIds.forEach(function (id) {
          if (allowedPrincipals.indexOf(id) >= 0) {
            found = true;
          }
        });

        if (!found && !allowedPrincipals.length) {
          allowedPrincipals.pushObject(Ember.get(this, 'access.principal.id'));
        }
      }

      Ember.set(this, 'model.allowedPrincipalIds', allowedPrincipals);
    })),
    checkDuplicate: function checkDuplicate(principal) {
      return (Ember.get(this, 'model.allowedPrincipalIds') || []).includes(principal.id) ? true : false;
    }
  });

  _exports.default = _default;
});