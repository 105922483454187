define("global-admin/security/roles/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    roleTemplateService: Ember.inject.service('roleTemplate'),
    globalStore: Ember.inject.service(),
    model: function model()
    /* params */
    {
      return Ember.RSVP.hash({
        roleTemplates: Ember.get(this, 'roleTemplateService.allVisibleRoleTemplates'),
        globalRoles: Ember.get(this, 'globalStore').findAll('globalrole')
      });
    },
    queryParams: {
      context: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});