define("shared/components/modal-yaml/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OpSghN4W",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"modal-yaml\"],[12],[2,\"\\n  \"],[1,[30,[36,11],null,[[\"title\",\"filename\",\"canChangeName\",\"value\",\"loading\",\"autoResize\",\"readOnly\",\"canUpload\",\"showUpload\"],[[30,[36,10],[[35,0],[30,[36,3],[[30,[36,10],[[35,4],\"modalYaml.title.view\",\"modalYaml.title.edit\"],null]],[[\"name\"],[[35,7,[\"resource\",\"displayName\"]]]]],[30,[36,3],[\"modalYaml.title.new\"],[[\"type\"],[[30,[36,9],[[35,7,[\"resource\",\"displayName\"]]],null]]]]],null],[35,8],false,[35,7,[\"yaml\"]],[35,6],true,[35,4],[30,[36,5],[[35,4]],null],[30,[36,5],[[35,4]],null]]]]],[2,\"\\n  \"],[1,[30,[36,12],null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[35,7,[\"yaml\"]],\"with-clip\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,14],null,[[\"errors\"],[[35,13]]]]],[2,\"\\n\\n\"],[6,[37,10],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,5,\"text-align: center\"],[14,0,\"mt-20 text-center\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-transparent\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"cancel\"],null],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"generic.cancel\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"save\",\"cancel\",\"editing\"],[[30,[36,1],[[32,0],\"save\"],null],[30,[36,1],[[32,0],\"cancel\"],null],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"editing\",\"action\",\"save-cancel\",\"t\",\"readOnly\",\"not\",\"loading\",\"model\",\"filename\",\"uc-first\",\"if\",\"input-yaml\",\"copy-to-clipboard\",\"errors\",\"top-errors\"]}",
    "meta": {
      "moduleName": "shared/components/modal-yaml/template.hbs"
    }
  });

  _exports.default = _default;
});