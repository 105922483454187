define("shared/components/schema/input-config-map/component", ["exports", "shared/components/schema/input-config-map/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    // Inputs
    namespace: null,
    selectClass: 'form-control',
    valueKey: 'name',
    // What to set the value as.. 'name' or 'id'
    // For use as a catalog question
    field: null,
    // Read default from a schema resourceField
    value: null,
    // name or id output string
    selected: null,
    // Selected configMap ID
    namespaceConfigMaps: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'namespaceConfigMaps', Ember.get(this, 'store').all('configMap'));
      var def = Ember.get(this, 'value') || Ember.get(this, 'field.default');

      if (def && !Ember.get(this, 'selected')) {
        var exact;
        var namespaceId = Ember.get(this, 'namespace.id');

        if (!exact && namespaceId) {
          Ember.get(this, 'namespaceConfigMaps').forEach(function (configMap) {
            if (def === Ember.get(configMap, 'name') && Ember.get(configMap, 'namespaceId') === namespaceId) {
              exact = Ember.get(configMap, 'id');
            }
          });
        }

        Ember.run.next(function () {
          Ember.set(_this, 'selected', exact || null);
        });
      }
    },
    selectedChanged: Ember.observer('selected', function () {
      var id = Ember.get(this, 'selected');
      var str = null;

      if (id) {
        var configMap = Ember.get(this, 'namespaceConfigMaps').findBy('id', id);

        if (configMap) {
          Ember.set(this, 'selectedConfigMap', configMap);
          str = Ember.get(configMap, Ember.get(this, 'valueKey'));
        } else {
          Ember.set(this, 'selectedConfigMap', null);
        }
      }

      Ember.set(this, 'value', str);
    }),
    filtered: Ember.computed('exclude', 'namespace.id', 'namespaceConfigMaps.[]', function () {
      var intl = Ember.get(this, 'intl');
      var out = [];
      var namespaceId = Ember.get(this, 'namespace.id');

      if (namespaceId) {
        Ember.get(this, 'namespaceConfigMaps').filterBy('namespaceId', namespaceId).forEach(function (configMap) {
          out.push({
            label: Ember.get(configMap, 'name'),
            value: Ember.get(configMap, 'id'),
            group: intl.t('generic.namespace')
          });
        });
      }

      var exclude = Ember.get(this, 'exclude');

      if (exclude) {
        if (!Ember.isArray(exclude)) {
          exclude = [exclude];
        }

        out = out.filter(function (x) {
          return !exclude.includes(x.value);
        });
      }

      return out.sortBy('group', 'label');
    })
  });

  _exports.default = _default;
});