define("shared/components/btn-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "miI76QNb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[14,0,\"switch\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"type\",\"checked\"],[\"checkbox\",[35,0]]]]],[2,\"\\n  \"],[10,\"div\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"checked\",\"input\"]}",
    "meta": {
      "moduleName": "shared/components/btn-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});