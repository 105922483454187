define("shared/components/tooltip-toggle-override/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KsDC4l3A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"tooltipToggleOverride.label\"],[[\"path\",\"htmlSafe\"],[[35,0,[\"path\"]],true]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"t\"]}",
    "meta": {
      "moduleName": "shared/components/tooltip-toggle-override/template.hbs"
    }
  });

  _exports.default = _default;
});