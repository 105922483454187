define("global-admin/components/form-global-roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GUO9XqzN",
    "block": "{\"symbols\":[\"role\",\"role\",\"baseRole\"],\"statements\":[[8,\"accordion-list-item\",[],[[\"@title\",\"@detail\",\"@expandOnInit\",\"@showExpand\"],[[30,[36,0],[\"formGlobalRoles.title\"],null],[30,[36,0],[\"formGlobalRoles.description\"],[[\"appName\",\"type\"],[[35,3,[\"appName\"]],[30,[36,2],[[35,1],\"user\",\"group\"],null]]]],true,false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"form-global-roles-row\",[],[[\"@model\"],[[32,3]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"accordion-list-item\",[],[[\"@title\",\"@detail\",\"@expandOnInit\",\"@showExpand\"],[[30,[36,0],[\"formGlobalRoles.mode.userCreated.label\"],null],[30,[36,0],[\"formGlobalRoles.mode.userCreated.detail\"],[[\"type\"],[[30,[36,2],[[35,1],\"user\",\"group\"],null]]]],true,false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"form-global-roles-row\",[],[[\"@model\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"accordion-list-item\",[],[[\"@title\",\"@detail\",\"@expandOnInit\",\"@showExpand\"],[[30,[36,0],[\"formGlobalRoles.mode.custom.label\"],null],[30,[36,0],[\"formGlobalRoles.mode.custom.detail\"],[[\"type\"],[[30,[36,2],[[35,1],\"user\",\"group\"],null]]]],true,false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"form-global-roles-row\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"user\",\"if\",\"settings\",\"baseRoles\",\"-track-array\",\"each\",\"userRoles\",\"additionalRoles\"]}",
    "meta": {
      "moduleName": "global-admin/components/form-global-roles/template.hbs"
    }
  });

  _exports.default = _default;
});