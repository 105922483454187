define("shared/components/schema/input-hostname/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wznjML78",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,4,[\"ingress-ip-domain\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"selection\",\"value\"],[[35,2],\"automatic\"]]]],[2,\" \"],[1,[30,[36,5],[\"formIngress.hostMode.automatic\"],[[\"domain\",\"htmlSafe\"],[[35,4,[\"ingress-ip-domain\"]],true]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"selection\",\"value\"],[[35,2],\"manual\"]]]],[2,\" \\n      \"],[1,[30,[36,5],[\"formIngress.hostMode.manual\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,2],\"manual\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"value\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"value\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"schema/input-string\",\"mode\",\"radio-button\",\"settings\",\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/schema/input-hostname/template.hbs"
    }
  });

  _exports.default = _default;
});