define("shared/components/top-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQVGyzu3",
    "block": "{\"symbols\":[\"error\",\"line\"],\"statements\":[[10,\"div\"],[14,0,\"banner-icon\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"icon icon-alert\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"banner-message\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"p\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"is-array\",\"if\",\"errors\"]}",
    "meta": {
      "moduleName": "shared/components/top-errors/template.hbs"
    }
  });

  _exports.default = _default;
});