define("shared/components/metrics-graph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7broE1uc",
    "block": "{\"symbols\":[\"row\",\"item\"],\"statements\":[[6,[37,11],[[30,[36,10],[[30,[36,10],[[35,12]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[30,[36,10],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"text-left pl-20\"],[12],[1,[30,[36,2],[[30,[36,8],[\"metrics.\",[32,2,[\"graph\",\"title\"]]],null]],null]],[13],[2,\"\\n        \"],[1,[30,[36,9],null,[[\"series\",\"loading\",\"formatter\"],[[32,2,[\"series\"]],[35,3],[32,2,[\"graph\",\"unit\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[6,[37,7],[[30,[36,6],[[35,5],[30,[36,4],[[35,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row mt-20\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center text-muted\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[35,1]],[[\"appName\"],[[35,0,[\"appName\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row mt-20\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"icon icon-spinner icon-spin icon-3x\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"settings\",\"noDataLabel\",\"t\",\"loading\",\"not\",\"noGraphs\",\"and\",\"if\",\"concat\",\"graph-area\",\"-track-array\",\"each\",\"rows\"]}",
    "meta": {
      "moduleName": "shared/components/metrics-graph/template.hbs"
    }
  });

  _exports.default = _default;
});