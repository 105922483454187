define("global-admin/components/other-role-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+3ui0G9Z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"content\",\"value\",\"readOnly\"],[[35,3],[35,2,[\"roleId\"]],[35,1]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\" \"],[13],[2,\"\\n\"],[6,[37,5],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary btn-sm\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"remove\"],null],[12],[10,\"i\"],[14,0,\"icon icon-minus\"],[12],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"readOnly\",\"model\",\"choices\",\"searchable-select\",\"unless\"]}",
    "meta": {
      "moduleName": "global-admin/components/other-role-row/template.hbs"
    }
  });

  _exports.default = _default;
});