define("nodes/components/driver-other/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-other/template"], function (_exports, _nodeDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nodeDriver.default, {
    layout: _template.default,
    // Set by Driver
    driverName: null,
    typeDocumentations: null,
    config: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'editing') && Ember.get(this, 'driverName')) {
        var config = Ember.get(this, "model.".concat(Ember.get(this, 'driverName'), "Config"));
        Ember.set(this, 'config', config);
      }
    },
    schema: Ember.computed('driverName', 'globalStore', function () {
      var configName = "".concat(Ember.get(this, 'driverName'), "Config");
      return Ember.get(this, 'globalStore').getById('schema', configName.toLowerCase());
    }),
    bootstrap: function bootstrap() {
      if (Ember.get(this, 'driverName')) {
        var configName = "".concat(Ember.get(this, 'driverName'), "Config");
        var config = Ember.get(this, 'globalStore').createRecord({
          type: configName
        });
        var model = Ember.get(this, 'model');
        Ember.set(model, configName, config);
        Ember.set(this, 'config', config);
      }
    },
    validate: function validate() {
      var errors = [];

      if (!Ember.get(this, 'model.name')) {
        errors.push('Name is required');
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    }
  });

  _exports.default = _default;
});