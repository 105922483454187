define("global-admin/security/authentication/activedirectory/controller", ["exports", "ui/utils/constants", "global-admin/mixins/authentication", "global-admin/mixins/ldap-auth"], function (_exports, _constants, _authentication, _ldapAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PLAIN_PORT = 389;
  var TLS_PORT = 636;

  var _default = Ember.Controller.extend(_authentication.default, _ldapAuth.default, {
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    confirmDisable: false,
    errors: null,
    testing: false,
    providerName: 'ldap.providerName.ad',
    providerSaveLabel: 'ldap.providerName.saveLabels.ad',
    addUserInput: '',
    addOrgInput: '',
    username: '',
    password: '',
    editing: false,
    authConfig: Ember.computed.alias('model.activeDirectory'),
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'authConfig')) {
        this.tlsChanged();
      }
    },
    actions: {
      test: function test() {
        var _this = this;

        this.send('clearError');
        var model = Ember.get(this, 'authConfig');
        var am = Ember.get(model, 'accessMode') || 'unrestricted';
        Ember.setProperties(model, {
          accessMode: am
        });
        var errors = model.validationErrors();

        if (errors.get('length')) {
          Ember.setProperties(this, {
            errors: errors,
            testing: false
          });
          model.set('enabled', false);
        } else {
          Ember.set(this, 'testing', true);
          model.doAction('testAndApply', {
            activeDirectoryConfig: model,
            enabled: true,
            username: Ember.get(this, 'username'),
            password: Ember.get(this, 'password')
          }).then(function () {
            _this.send('waitAndRefresh');
          }).catch(function (err) {
            Ember.set(model, 'enabled', false);

            _this.send('gotError', err);
          });
        }
      }
    },
    tlsChanged: Ember.observer('authConfig.tls', function () {
      var on = Ember.get(this, 'authConfig.tls') || false;
      var port = parseInt(Ember.get(this, 'authConfig.port'), 10);
      var authConfig = Ember.get(this, 'authConfig');

      if (on && port === PLAIN_PORT) {
        Ember.set(this, 'authConfig.port', TLS_PORT);
      } else if (!on && port === TLS_PORT) {
        Ember.setProperties(authConfig, {
          port: PLAIN_PORT,
          tls: false
        });
      }
    }),
    createDisabled: Ember.computed('username.length', 'password.length', function () {
      return !Ember.get(this, 'username.length') || !Ember.get(this, 'password.length');
    }),
    numUsers: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(function (principal) {
        return principal.includes(_constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_USER);
      }).get('length');
    }),
    numGroups: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(function (principal) {
        return principal.includes(_constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_GROUP);
      }).get('length');
    }),
    configServers: Ember.computed('authConfig.servers', {
      get: function get() {
        return (Ember.get(this, 'authConfig.servers') || []).join(',');
      },
      set: function set(key, value) {
        Ember.set(this, 'authConfig.servers', value.split(','));
        return value;
      }
    }),
    userType: _constants.default.PROJECT.TYPE_LDAP_USER,
    groupType: _constants.default.PROJECT.TYPE_LDAP_GROUP
  });

  _exports.default = _default;
});