define("shared/mixins/lazy-icon", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    srcSet: false,
    genericIconPath: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'genericIconPath', "".concat(Ember.get(this, 'app.baseAssets'), "assets/images/generic-catalog.svg"));
    },
    initAppIcon: function initAppIcon() {
      var _this = this;

      if (!Ember.get(this, 'srcSet')) {
        Ember.set(this, 'srcSet', true);
        var $icon = (0, _jquery.default)(this.element).find('.catalog-icon > img');
        var $srcPath = $icon.attr('src');

        if ($srcPath === this.genericIconPath) {
          $icon.attr('src', $icon.data('src'));
          var img = (0, _jquery.default)(this.element).find('img');
          img.on('error', function () {
            if (_this.isDestroyed || _this.isDestroying) {
              if (img) {
                img.off('error');
              }

              return;
            }

            $icon.attr('src', _this.genericIconPath);
          });
        }
      }
    }
  });

  _exports.default = _default;
});