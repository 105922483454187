define("shared/components/tooltip-node-group-update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wjPfl/0S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"tooltip-node-group-update\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mb-5\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"tooltipNodeGroupUpdate.label\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[11,\"span\"],[24,0,\"text-link hand\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0],\"updateNodeGroup\"],null]],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"tooltipNodeGroupUpdate.link\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"on\"]}",
    "meta": {
      "moduleName": "shared/components/tooltip-node-group-update/template.hbs"
    }
  });

  _exports.default = _default;
});