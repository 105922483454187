define("liquid-fire/transitions/wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(ms, opts) {
    var _this = this;

    for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      rest[_key - 2] = arguments[_key];
    }

    opts = opts !== undefined ? opts : {};
    return new Ember.RSVP.Promise(function (resolve) {
      setTimeout(function () {
        var _this$lookup;

        resolve((_this$lookup = _this.lookup(opts.then || 'default')).call.apply(_this$lookup, [_this].concat(rest)));
      }, ms);
    });
  }
});