define("shared/components/cluster-driver/driver-huaweicce/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-huaweicce/template", "jquery"], function (_exports, _clusterDriver, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'huaweiEngineConfig',
    zones: [{
      label: 'cn-north-1',
      value: 'cn-north-1'
    }],
    clusterType: [{
      label: 'VirtualMachine',
      value: 'VirtualMachine'
    }, {
      label: 'BareMetal',
      value: 'BareMetal'
    }],
    masterVersions: [{
      label: 'v1.11.3',
      value: 'v1.11.3'
    }],
    eipChargeModeContent: [{
      label: 'BandWith',
      value: null
    }, {
      label: 'Traffic',
      value: 'traffic'
    }],
    containerNetworkMode: [{
      label: 'overlay_l2',
      value: 'overlay_l2'
    }, {
      label: 'underlay_ipvlan',
      value: 'underlay_ipvlan'
    }, {
      label: 'vpc-router',
      value: 'vpc-router'
    }],
    volumeTypeContent: [{
      label: 'SATA',
      value: 'SATA'
    }, {
      label: 'SAS',
      value: 'SAS'
    }, {
      label: 'SSD',
      value: 'SSD'
    }],
    eipTypeContent: [{
      label: '5_bgp',
      value: '5_bgp'
    }, {
      label: '5_sbgp',
      value: '5_sbgp'
    }],
    containerNetworkModeContent: [{
      label: 'overlay_l2',
      value: 'overlay_l2'
    }, {
      label: 'underlay_ipvlan',
      value: 'underlay_ipvlan'
    }, {
      label: 'vpc-router',
      value: 'vpc-router'
    }],
    nodeOperationSystemContent: [{
      label: 'EulerOS 2.2',
      value: 'EulerOS 2.2'
    }, {
      label: 'CentOS 7.4',
      value: 'CentOS 7.4'
    }],
    containerNetworkCidrContent: [{
      label: '172.16.0.0/16',
      value: '172.16.0.0/16'
    }],
    validityPeriodContent: [{
      label: '1 month',
      value: '1 month'
    }, {
      label: '2 months',
      value: '2 month'
    }, {
      label: '3 months',
      value: '3 month'
    }, {
      label: '4 months',
      value: '4 month'
    }, {
      label: '5 months',
      value: '5 month'
    }, {
      label: '6 months',
      value: '6 month'
    }, {
      label: '7 months',
      value: '7 month'
    }, {
      label: '8 months',
      value: '8 month'
    }, {
      label: '9 months',
      value: '9 month'
    }, {
      label: '1 year',
      value: '1 year'
    }],
    eipShareTypeContent: [{
      label: 'PER',
      value: 'PER'
    }],
    vpcs: null,
    subnets: null,
    eipIds: null,
    nodeFlavors: null,
    keypairs: null,
    availableZones: null,
    step: 1,
    eipSelection: 'none',
    highAvailabilityEnabled: 's2',
    managementScale: 'small',
    validityPeriod: '1 month',
    authConfigred: false,
    publicCloud: null,
    editing: Ember.computed.equal('mode', 'edit'),
    init: function init() {
      this._super.apply(this, arguments);

      var config = Ember.get(this, 'cluster.huaweiEngineConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          type: 'huaweiEngineConfig',
          accessKey: null,
          secretKey: null,
          region: 'cn-north-1',
          projectId: null,
          dataVolumeSize: 100,
          vpcId: null,
          clusterType: 'VirtualMachine',
          masterVersion: 'v1.11.3',
          billingMode: 0,
          containerNetworkMode: 'overlay_l2',
          clusterFlavor: 'cce.s2.small',
          dataVolumeType: 'SATA',
          rootVolumeType: 'SATA',
          nodeCount: 1,
          rootVolumeSize: 40,
          externalServerEnabled: false,
          nodeOperationSystem: 'EulerOS 2.2',
          containerNetworkCidr: '172.16.0.0/16',
          bmsIsAutoRenew: 'false',
          userName: 'root'
        });
        Ember.set(this, 'cluster.huaweiEngineConfig', config);
        this.validityPeriodChange();
      } else {
        var clusterFlavor = Ember.get(config, 'clusterFlavor');

        if (clusterFlavor) {
          var arr = clusterFlavor.split('.');
          Ember.setProperties(this, {
            'highAvailabilityEnabled': arr[1],
            'managementScale': arr[2]
          });
        }

        Ember.setProperties(config, {
          accessKey: null,
          secretKey: null
        });

        if (Ember.get(config, 'nodeLabels') === null) {
          Ember.set(config, 'nodeLabels', {});
        }

        if (Ember.get(config, 'eipIds') === null) {
          Ember.set(config, 'eipIds', []);
        }
      }
    },
    actions: {
      multiEipSelect: function multiEipSelect() {
        var options = Array.prototype.slice.call((0, _jquery.default)('.existing-eips')[0], 0);
        var selectedOptions = [];
        options.filterBy('selected', true).forEach(function (cap) {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'config.eipIds', selectedOptions || []);
      },
      checkAccount: function checkAccount(cb) {
        var _this = this;

        var requiredConfig = ['projectId', 'accessKey', 'secretKey', 'region'];
        var requiredCluster = ['name'];
        Ember.set(this, 'errors', []);
        var errors = [];
        errors = this.validateFields(errors, requiredConfig, 'config');
        errors = this.validateFields(errors, requiredCluster, 'cluster');

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        Ember.setProperties(this, {
          'errors': null,
          'config.accessKey': (Ember.get(this, 'config.accessKey') || '').trim(),
          'config.secretKey': (Ember.get(this, 'config.secretKey') || '').trim()
        });

        try {
          var location = window.location;
          var region = Ember.get(this, 'config.region');
          var endpoint = "vpc.".concat(region, ".myhuaweicloud.com");
          endpoint = "".concat(Ember.get(this, 'app.proxyEndpoint'), "/").concat(endpoint.replace('//', '/'));
          endpoint = "".concat(location.origin).concat(endpoint);
          var client = new HW.ECS({
            ak: Ember.get(this, 'config.accessKey'),
            sk: Ember.get(this, 'config.secretKey'),
            projectId: Ember.get(this, 'config.projectId'),
            endpoint: endpoint,
            region: region,
            toSignedHost: "vpc.".concat(region, ".myhuaweicloud.com")
          });
          client.getVpcs(function (err, response) {
            if (err) {
              var _errors = _this.get('errors') || [];

              _errors.pushObject(err);

              Ember.set(_this, 'errors', _errors);
              cb();
              return;
            }

            Ember.set(_this, 'vpcs', response.body.vpcs);

            if (Ember.get(_this, 'mode') === 'new') {
              Ember.set(_this, 'config.vpcId', response.body.vpcs[0] && response.body.vpcs[0].id || null);
            }

            client.getSubnet(function (err, response) {
              if (err) {
                var _errors2 = _this.get('errors') || [];

                _errors2.pushObject(err);

                Ember.set(_this, 'errors', _errors2);
                cb();
                return;
              }

              Ember.set(_this, 'subnets', response.body.subnets);

              if (Ember.get(_this, 'mode') === 'new') {
                Ember.set(_this, 'config.subnetId', response.body.subnets[0] && response.body.subnets[0].id || null);
              }

              client.getPublicips(function (err, response) {
                if (err) {
                  var _errors3 = _this.get('errors') || [];

                  _errors3.pushObject(err);

                  Ember.set(_this, 'errors', _errors3);
                  cb();
                  return;
                }

                Ember.set(_this, 'eipIds', response.body.publicips);
                client.getNetwork(function (err, response) {
                  if (err) {
                    var _errors4 = _this.get('errors') || [];

                    _errors4.pushObject(err);

                    Ember.set(_this, 'errors', _errors4);
                    cb();
                    return;
                  }

                  Ember.set(_this, 'publicCloud', true);
                  Ember.set(_this, 'networks', response.body.networks);
                  Ember.set(_this, 'step', 2);
                  cb();
                });
              });
            });
          });
        } catch (err) {
          var _errors5 = Ember.get(this, 'errors') || [];

          _errors5.pushObject(err.message || err);

          Ember.set(this, 'errors', _errors5);
          cb();
          return;
        }
      },
      configreNode: function configreNode(cb) {
        var _this2 = this;

        var requiredConfig = ['vpcId', 'subnetId', 'containerNetworkCidr'];
        Ember.set(this, 'errors', []);
        var errors = [];
        errors = this.validateFields(errors, requiredConfig, 'config');

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        if (Ember.get(this, 'authConfigred')) {
          Ember.set(this, 'step', 3);
          cb();
          return;
        }

        try {
          var location = window.location;
          var region = Ember.get(this, 'config.region');
          var endpoint = "ecs.".concat(region, ".myhuaweicloud.com");
          endpoint = "".concat(Ember.get(this, 'app.proxyEndpoint'), "/").concat(endpoint.replace('//', '/'));
          endpoint = "".concat(location.origin).concat(endpoint);
          var client = new HW.ECS({
            ak: Ember.get(this, 'config.accessKey'),
            sk: Ember.get(this, 'config.secretKey'),
            projectId: Ember.get(this, 'config.projectId'),
            endpoint: endpoint,
            region: region,
            toSignedHost: "ecs.".concat(region, ".myhuaweicloud.com"),
            service: 'ecs'
          });
          client.listCloudServerFlavors(function (err, response) {
            if (err) {
              var _errors6 = _this2.get('errors') || [];

              _errors6.pushObject(err);

              Ember.set(_this2, 'errors', _errors6);
              cb();
              return;
            }

            Ember.set(_this2, 'nodeFlavors', response.body.flavors);

            if (Ember.get(_this2, 'mode') === 'new') {
              Ember.set(_this2, 'config.nodeFlavor', response.body.flavors[0] && response.body.flavors[0].name || null);
            }

            client.listKeypairs(function (err, response) {
              if (err) {
                var _errors7 = _this2.get('errors') || [];

                _errors7.pushObject(err);

                Ember.set(_this2, 'errors', _errors7);
                cb();
                return;
              }

              Ember.set(_this2, 'keypairs', response.body.keypairs);
              var keypairs = response.body.keypairs || [];
              Ember.set(_this2, 'config.sshKey', keypairs[0] && keypairs[0].keypair.name);
              client.getAvaliableZone(function (err, response) {
                if (err) {
                  var _errors8 = _this2.get('errors') || [];

                  _errors8.pushObject(err);

                  Ember.set(_this2, 'errors', _errors8);
                  cb();
                  return;
                }

                var availableZones = (response.body.availabilityZoneInfo || []).filter(function (z) {
                  return z.zoneState.available;
                });
                Ember.set(_this2, 'availableZones', availableZones);

                if (Ember.get(_this2, 'mode') === 'new') {
                  Ember.setProperties(_this2, {
                    'config.keypairs': response.body.availabilityZoneInfo[0] && response.body.availabilityZoneInfo[0].zoneName || null,
                    'config.availableZone': availableZones.get('firstObject.zoneName')
                  });
                }

                Ember.set(_this2, 'step', 3);
              });
            });
          });
        } catch (err) {
          var _errors9 = Ember.get(this, 'errors') || [];

          _errors9.pushObject(err.message || err);

          Ember.set(this, 'errors', _errors9);
          cb();
          return;
        }
      },
      setLabels: function setLabels(section) {
        var obj = {};
        section.map(function (s) {
          if (s.key && s.value) {
            obj[s.key] = s.value;
          }
        });
        Ember.set(this, 'config.labels', obj);
      },
      setNodeLabels: function setNodeLabels(section) {
        var obj = {};
        section.map(function (s) {
          if (s.key && s.value) {
            obj[s.key] = s.value;
          }
        });
        Ember.set(this, 'config.nodeLabels', obj);
      }
    },
    clusterTypeChange: Ember.observer('config.clusterType', function () {
      var _this3 = this;

      var clusterType = Ember.get(this, 'config.clusterType');
      var publicCloud = Ember.get(this, 'publicCloud');

      if (clusterType === 'VirtualMachine') {
        Ember.set(this, 'config.billingMode', 0);
        Ember.set(this, 'config.highwaySubnet', null);
        Ember.set(this, 'highAvailabilityEnabled', 's2');
      }

      if (clusterType !== 'BareMetal' || Ember.get(this, 'mode') !== 'new') {
        return;
      }

      var networks = Ember.get(this, 'networks') || [];
      var filter = [];

      if (publicCloud) {
        filter = networks.filter(function (n) {
          return n.status === 'ACTIVE' && n.tenant_id === Ember.get(_this3, 'config.projectId') && n["provider:network_type"] === 'geneve';
        });
      } else {
        filter = networks.filter(function (n) {
          return n.status === 'ACTIVE';
        });
      }

      Ember.set(this, 'config.highwaySubnet', filter[0] && filter[0].id);
      Ember.set(this, 'highAvailabilityEnabled', 't2');
    }),
    vpcIdChange: Ember.observer('config.vpcId', function () {
      var vpcId = Ember.get(this, 'config.vpcId');
      var subnets = Ember.get(this, 'subnets') || [];
      var filter = subnets.filter(function (s) {
        return s.vpc_id === vpcId;
      });
      Ember.set(this, 'config.subnetId', filter[0] && filter[0].id || null);
    }),
    eipSelectionChange: Ember.observer('eipSelection', function () {
      var eipSelection = Ember.get(this, 'eipSelection');

      if (eipSelection === 'none') {
        Ember.setProperties(this, {
          'config.eipIds': [],
          'config.eipCount': null,
          'config.eipType': null,
          'config.eipShareType': null,
          'config.eipChargeMode': null,
          'config.eipBandwidthSize': null
        });
      }

      if (eipSelection === 'exist') {
        Ember.setProperties(this, {
          'config.eipCount': null,
          'config.eipType': null,
          'config.eipShareType': null,
          'config.eipChargeMode': null,
          'config.eipBandwidthSize': null
        });
      }

      if (eipSelection === 'new') {
        Ember.setProperties(this, {
          'config.eipIds': [],
          'config.eipCount': 1,
          'config.eipType': '5_bgp',
          'config.eipBandwidthSize': 1,
          'config.eipShareType': 'PER'
        });
      }
    }),
    externalServerChange: Ember.observer('config.externalServerEnabled', function () {
      var externalServerEnabled = Ember.get(this, 'config.externalServerEnabled');

      if (!externalServerEnabled) {
        Ember.set(this, 'config.clusterEipId', null);
      }
    }),
    clusterFlavorObserver: Ember.observer('managementScale', 'highAvailabilityEnabled', function () {
      var managementScale = this.managementScale,
          highAvailabilityEnabled = this.highAvailabilityEnabled;
      Ember.set(this, 'config.clusterFlavor', "cce.".concat(highAvailabilityEnabled, ".").concat(managementScale));
    }),
    validityPeriodChange: Ember.observer('validityPeriod', function () {
      var validityPeriod = Ember.get(this, 'validityPeriod');

      if (!validityPeriod) {
        Ember.setProperties(this, {
          'config.bmsPeriodNum': null,
          'config.bmsPeriodType': null
        });
        return;
      }

      var arr = validityPeriod.split(' ');
      Ember.setProperties(this, {
        'config.bmsPeriodNum': parseInt(arr[0]),
        'config.bmsPeriodType': arr[1]
      });
    }),
    billingModeChange: Ember.observer('config.billingMode', function () {
      var billingMode = Ember.get(this, 'config.billingMode');

      if (billingMode === 0) {
        Ember.setProperties(this, {
          'validityPeriod': null,
          'config.bmsIsAutoRenew': null
        });
      }

      if (billingMode === 2) {
        Ember.setProperties(this, {
          'config.bmsIsAutoRenew': 'false',
          'validityPeriod': '1 month'
        });
      }
    }),
    managementScaleContent: Ember.computed('config.clusterType', function () {
      var clusterType = Ember.get(this, 'config.clusterType');

      if (clusterType === 'BareMetal') {
        return [{
          label: '10',
          value: 'small'
        }, {
          label: '100',
          value: 'medium'
        }, {
          label: '500',
          value: 'large'
        }];
      }

      return [{
        label: '50',
        value: 'small'
      }, {
        label: '200',
        value: 'medium'
      }, {
        label: '1000',
        value: 'large'
      }];
    }),
    vpcContent: Ember.computed('vpcs.[]', function () {
      var vpcs = Ember.get(this, 'vpcs') || [];
      return vpcs.map(function (v) {
        return {
          label: v.name,
          value: v.id
        };
      });
    }),
    editedVpcName: Ember.computed('config.vpcId', 'vpcs', function () {
      var vpcId = Ember.get(this, 'config.vpcId');
      var vpcs = Ember.get(this, 'vpcs') || [];
      var filter = vpcs.filter(function (v) {
        return v.id === vpcId;
      })[0] || {};
      return filter.name;
    }),
    subnetContent: Ember.computed('config.vpcId', 'subnets.[]', function () {
      var subnets = Ember.get(this, 'subnets') || [];
      var vpcId = Ember.get(this, 'config.vpcId');
      var filter = subnets.filter(function (s) {
        return s.vpc_id === vpcId;
      }).map(function (s) {
        return {
          label: s.name,
          value: s.id
        };
      });
      return filter;
    }),
    editedSubnetName: Ember.computed('config.subnetId', 'subnets', function () {
      var subnetId = Ember.get(this, 'config.subnetId');
      var subnets = Ember.get(this, 'subnets') || [];
      var filter = subnets.filter(function (s) {
        return s.id === subnetId;
      })[0] || {};
      return filter.name;
    }),
    eipIdContent: Ember.computed('eipIds.[]', function () {
      var eipIds = Ember.get(this, 'eipIds') || [];
      return eipIds.filter(function (e) {
        return e.status === 'DOWN';
      }).map(function (e) {
        return {
          label: e.public_ip_address,
          value: e.id
        };
      });
    }),
    clusterEipName: Ember.computed('config.clusterEipId', 'eipIds', function () {
      var eipIds = Ember.get(this, 'eipIds') || [];
      var clusterEipId = Ember.get(this, 'config.clusterEipId');
      var filter = eipIds.filter(function (e) {
        return e.id === clusterEipId;
      })[0] || {};
      return filter.public_ip_address;
    }),
    nodeFlavorContent: Ember.computed('nodeFlavors.[]', function () {
      var nodeFlavors = Ember.get(this, 'nodeFlavors') || [];
      return nodeFlavors.map(function (n) {
        return {
          label: "".concat(n.name, " ( vCPUs: ").concat(n.vcpus, ", memory: ").concat(n.ram / 1024, " GB )"),
          value: n.name
        };
      });
    }),
    availableZoneContent: Ember.computed('availableZones.[]', function () {
      var zones = Ember.get(this, 'availableZones');
      return zones.map(function (z) {
        if (z.zoneState.available) {
          return {
            label: z.zoneName,
            value: z.zoneName
          };
        }
      });
    }),
    sshkeyContent: Ember.computed('keypairs.[]', function () {
      var keypairs = Ember.get(this, 'keypairs');
      return keypairs.map(function (k) {
        return {
          label: k.keypair.name,
          value: k.keypair.name
        };
      });
    }),
    editedSshName: Ember.computed('config.sshKey', 'keypairs', function () {
      var sshKey = Ember.get(this, 'config.sshKey');
      var keypairs = Ember.get(this, 'keypairs');
      var filter = keypairs.filter(function (k) {
        return k.keypair.name === sshKey;
      })[0] || {};
      return filter.keypair && filter.keypair.name || '';
    }),
    nodeCountMax: Ember.computed('config.clusterFlavor', function () {
      var clusterFlavor = Ember.get(this, 'config.clusterFlavor') || '';

      if (clusterFlavor.endsWith('small')) {
        return 50;
      }

      if (clusterFlavor.endsWith('medium')) {
        return 200;
      }

      return 1000;
    }),
    managementScaleDisplay: Ember.computed('managementScale', 'managementScaleContent', function () {
      var managementScale = Ember.get(this, 'managementScale');
      var managementScaleContent = Ember.get(this, 'managementScaleContent') || [];
      var filter = managementScaleContent.filter(function (m) {
        return m.value === managementScale;
      })[0] || {};
      return filter.label;
    }),
    networkContent: Ember.computed('config.projectId', 'networks.[]', 'publicCloud', function () {
      var _this4 = this;

      var networks = Ember.get(this, 'networks');
      var publicCloud = Ember.get(this, 'publicCloud');
      var arr = [];

      if (publicCloud) {
        arr = networks.filter(function (n) {
          return n.status === 'ACTIVE' && n.tenant_id === Ember.get(_this4, 'config.projectId') && n["provider:network_type"] === 'geneve';
        });
      } else {
        arr = networks.filter(function (n) {
          return n.status === 'ACTIVE';
        });
      }

      return arr.map(function (a) {
        return {
          label: a.name,
          value: a.id
        };
      });
    }),
    billingModeName: Ember.computed('config.billingMode', function () {
      var billingMode = Ember.get(this, 'config.billingMode');
      var intl = Ember.get(this, 'intl');
      return billingMode === 0 ? intl.t('clusterNew.huaweicce.billingMode.payPerUse') : intl.t('clusterNew.huaweicce.billingMode.yearly');
    }),
    billingModeContent: Ember.computed('config.clusterType', function () {
      var clusterType = Ember.get(this, 'config.clusterType');
      var intl = Ember.get(this, 'intl');

      if (clusterType === 'VirtualMachine') {
        return [{
          label: intl.t('clusterNew.huaweicce.billingMode.payPerUse'),
          value: 0
        }];
      } else {
        return [{
          label: intl.t('clusterNew.huaweicce.billingMode.payPerUse'),
          value: 0
        }, {
          label: intl.t('clusterNew.huaweicce.billingMode.yearly'),
          value: 2
        }];
      }
    }),
    validityPeriodName: Ember.computed('config.bmsPeriodNum', 'config.bmsPeriodType', function () {
      var _Ember$get = Ember.get(this, 'config'),
          bmsPeriodNum = _Ember$get.bmsPeriodNum,
          bmsPeriodType = _Ember$get.bmsPeriodType;

      return "".concat(bmsPeriodNum, " ").concat(bmsPeriodType);
    }),
    bmsIsAutoRenewName: Ember.computed('config.bmsIsAutoRenew', function () {
      return Ember.get(this, 'config.bmsIsAutoRenew') === 'true' ? 'Enabled' : 'Disabled';
    }),
    validateFields: function validateFields() {
      var _this5 = this;

      var errors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var requiredFields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var parent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var intl = Ember.get(this, 'intl');

      if (parent) {
        requiredFields.map(function (item) {
          if (!Ember.get(_this5, "".concat(parent, ".").concat(item))) {
            errors.pushObject("\"".concat(intl.t("clusterNew.huaweicce.".concat(item, ".label")), "\" ").concat(intl.t("generic.isRequired")));
          }
        });
      } else {
        requiredFields.map(function (item) {
          if (!Ember.get(_this5, "".concat(item))) {
            errors.pushObject("\"".concat(intl.t("clusterNew.huaweicce.".concat(item, ".label")), "\" ").concat(intl.t("generic.isRequired")));
          }
        });
      }

      return errors;
    },
    willSave: function willSave() {
      if (Ember.get(this, 'mode') === 'new') {
        var authenticatingProxyCa = Ember.get(this, 'authenticatingProxyCa') || '';

        if (Ember.get(this, 'config.authentiactionMode') === 'authenticating_proxy') {
          Ember.set(this, 'config.authenticatingProxyCa', AWS.util.base64.encode(authenticatingProxyCa));
        } else {
          Ember.set(this, 'config.authenticatingProxyCa', null);
        }
      }

      return this._super.apply(this, arguments);
    },
    validate: function validate() {
      this._super.apply(this, arguments);

      var errors = Ember.get(this, 'errors') || [];
      errors = this.validateFields(errors, ['sshKey'], 'config');

      if (Ember.get(this, 'config.authentiactionMode') === 'authenticating_proxy') {
        errors = this.validateFields(errors, ['authenticatingProxyCa'], 'config');
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    }
  });

  _exports.default = _default;
});