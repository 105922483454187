define("global-admin/multi-cluster-apps/catalog/launch/route", ["exports", "shared/utils/util", "ui/utils/constants"], function (_exports, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    store: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    parentRoute: 'multi-cluster-apps.catalog',
    model: function model(params
    /* , transition*/
    ) {
      var _this = this;

      var store = Ember.get(this, 'globalStore');
      var dependencies = {
        tpl: Ember.get(this, 'catalog').fetchTemplate(params.template),
        projects: this.scope.getAllProjects(),
        clusters: this.scope.getAllClusters()
      };

      if (params.upgrade) {
        dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate("".concat(params.template, "-").concat(params.upgrade), true);
      }

      if (params.appId) {
        dependencies.app = store.find('multiclusterapp', params.appId);
      }

      return Ember.RSVP.hash(dependencies, 'Load dependencies').then(function (results) {
        var app = Ember.get(results, 'app');
        var def = Ember.get(results, 'tpl.defaultVersion');
        var kind = 'helm';
        var neuApp = null;
        var links;
        var catalogTemplateUrl = null; // get diff versions

        links = results.tpl.versionLinks;

        if (app && params.appId && !params.upgrade) {
          def = Ember.get(app, 'externalIdInfo.version');
        }

        if (!links[def]) {
          def = Ember.get(results, 'tpl.latestVersion');
        }

        catalogTemplateUrl = links[def];
        return _this.catalog.fetchByUrl(catalogTemplateUrl).then(function ()
        /* catalogTemplate */
        {
          var verArr = Object.keys(links).filter(function (key) {
            return !!links[key];
          }).map(function (key) {
            return {
              version: key,
              sortVersion: key,
              link: links[key]
            };
          });

          if (results.app) {
            if (Ember.get(params, 'clone')) {
              neuApp = results.app.cloneForNew();
              Ember.set(neuApp, 'name', _this.dedupeName(results.app.name));

              if (neuApp.targets.length > 0) {
                var neuTargets = [];
                neuApp.targets.forEach(function (t) {
                  neuTargets.pushObject(t.cloneForNew());
                });
                Ember.set(neuApp, 'targets', neuTargets);
              }
            } else {
              neuApp = results.app.clone();
            }
          } else {
            var defaultAnswwer = store.createRecord({
              clusterId: null,
              projectId: null,
              type: 'answer',
              values: []
            });
            neuApp = store.createRecord({
              type: 'multiclusterapp',
              answers: [defaultAnswwer],
              catalogId: results.tpl.catalogId,
              targets: [],
              members: [],
              roles: ['project-member']
            });
          }

          var catalogTemplateUrlKey = def;

          if (neuApp.id) {
            var currentAppVersion = Ember.get(neuApp, 'externalIdInfo.version');
            var currentVersion = verArr.filter(function (ver) {
              return ver.version === currentAppVersion;
            });

            if (currentVersion.length === 0) {
              verArr.unshift({
                link: Ember.get(verArr, 'firstObject.link').substring(0, Ember.get(verArr, 'firstObject.link.length') - Ember.get(verArr, 'firstObject.version.length')) + currentAppVersion,
                sortVersion: currentAppVersion,
                version: "".concat(currentAppVersion, " (current)")
              });
            } else {
              currentVersion.forEach(function (ver) {
                Ember.set(ver, 'version', "".concat(ver.version, " (current)"));
              });

              if (!params.upgrade) {
                catalogTemplateUrlKey = currentAppVersion;
              }
            }
          }

          if (results.tpl && results.tpl.id === _constants.default.STORAGE.LONGHORN_CATALOG_TEMPLATE_ID) {
            Ember.set(neuApp, 'name', _constants.default.STORAGE.LONGHORN_CATALOG_ITEM_DEFAULT_NAME);
          }

          return {
            catalogTemplateUrl: links[catalogTemplateUrlKey],
            // catalogTemplateUrl gets qp's added and this needs with out
            clusters: results.clusters,
            isClone: Ember.get(params, 'clone') ? true : false,
            multiClusterApp: neuApp,
            projects: results.projects,
            tpl: results.tpl,
            tplKind: kind,
            upgradeTemplate: results.upgrade,
            versionLinks: links,
            versionsArray: verArr
          };
        });
      });
    },
    afterModel: function afterModel(model
    /* , transition */
    ) {
      var _this2 = this;

      if (Ember.get(model, 'multiClusterApp.id') && Ember.get(model, 'multiClusterApp.members.length')) {
        var membersPromises = [];
        Ember.get(model, 'multiClusterApp.members').forEach(function (member) {
          var id = Ember.get(member, 'userPrincipalId') || Ember.get(member, 'groupPrincipalId');
          membersPromises.push(_this2.globalStore.find('principal', id));
        });
        return Ember.RSVP.all(membersPromises).catch(function ()
        /* err */
        {
          // we fail here when we can't look up a principal (e.g. logged in as local but its an external auth provider principal)
          return;
        });
      }

      return;
    },
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.setProperties(controller, {
          appId: null,
          catalog: null,
          template: null,
          upgrade: null,
          clone: null
        });
      }
    },
    dedupeName: function dedupeName(name) {
      var suffix = (0, _util.randomStr)(5, 5, 'novowels');
      var newName = null;
      newName = "".concat(name, "-").concat(suffix);
      return newName;
    }
  });

  _exports.default = _default;
});