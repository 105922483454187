define("shared/components/storage-class/provisioner-longhorn/component", ["exports", "shared/components/storage-class/provisioner-longhorn/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DEFAULT_PROVISIONER_CONFIG = {
    numberOfReplicas: '3',
    staleReplicaTimeout: '2880',
    fromBackup: '',
    diskSelector: '',
    nodeSelector: '',
    recurringJobs: ''
  };

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'longhorn',
    mode: 'new',
    defaultProvisionerConfig: DEFAULT_PROVISIONER_CONFIG,
    fields: Object.keys(DEFAULT_PROVISIONER_CONFIG),
    didReceiveAttrs: function didReceiveAttrs() {
      var defaultProvisionerConfig = this.defaultProvisionerConfig;
      var parameters = Ember.get(this, 'parameters') || {};
      Ember.setProperties(this, {
        model: _objectSpread(_objectSpread({}, defaultProvisionerConfig), parameters)
      });
    },
    updateParams: function updateParams() {
      var filteredEntries = Object.entries(Ember.get(this, 'model') || {}).filter(function (entry) {
        return entry[1];
      });
      Ember.set(this, 'parameters', Object.fromEntries(filteredEntries));
    }
  });

  _exports.default = _default;
});