define("global-admin/components/saml-config/component", ["exports", "global-admin/components/saml-config/template", "global-admin/mixins/authentication", "global-admin/mixins/saml-auth"], function (_exports, _template, _authentication, _samlAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PLAIN_PORT = 389;
  var TLS_PORT = 636;

  var _default = Ember.Component.extend(_authentication.default, _samlAuth.default, {
    globalStore: Ember.inject.service(),
    layout: _template.default,
    advanced: false,
    createLabel: null,
    editLabel: null,
    providerName: null,
    providerNamePath: null,
    saveLabel: null,
    openLdapIsExpanded: false,
    isShibboleth: Ember.computed.equal('providerName', 'shibboleth'),
    shibbolethOpenLdapConfig: Ember.computed.alias('authConfig.openLdapConfig'),
    actions: {
      openLdapExpanded: function openLdapExpanded(expandFn, item) {
        var openLdapIsExpanded = this.openLdapIsExpanded,
            authConfig = this.authConfig; // Intentionally not reset, only needs to indicate it was opened once

        if (!openLdapIsExpanded && Ember.isEmpty(authConfig.openLdapConfig)) {
          Ember.set(this, 'openLdapIsExpanded', true);
          Ember.set(this, 'authConfig.openLdapConfig', this.globalStore.createRecord({
            type: 'ldapFields'
          }));
        }

        expandFn(item);
      }
    },
    tlsChanged: Ember.observer('shibbolethOpenLdapConfig.tls', function () {
      var authConfig = Ember.get(this, 'shibbolethOpenLdapConfig');
      var on = Ember.get(authConfig, 'tls') || false;
      var port = parseInt(Ember.get(authConfig, 'port'), 10);

      if (on && port === PLAIN_PORT) {
        Ember.set(this, 'shibbolethOpenLdapConfig.port', TLS_PORT);
      } else if (!on && port === TLS_PORT) {
        Ember.setProperties(authConfig, {
          port: PLAIN_PORT,
          tls: false
        });
      }
    }),
    configServers: Ember.computed('shibbolethOpenLdapConfig.servers', {
      get: function get() {
        return (Ember.get(this, 'shibbolethOpenLdapConfig.servers') || []).join(',');
      },
      set: function set(key, value) {
        Ember.set(this, 'shibbolethOpenLdapConfig.servers', value.split(','));
        return value;
      }
    })
  });

  _exports.default = _default;
});