define("shared/components/pretty-json/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pbU6Ff8D",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"pre\"],[14,0,\"bg-pre-light\"],[12],[10,\"code\"],[12],[1,[34,1]],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"json\",\"if\"]}",
    "meta": {
      "moduleName": "shared/components/pretty-json/template.hbs"
    }
  });

  _exports.default = _default;
});