define("shared/components/modal-confirm-deactivate/component", ["exports", "ui/utils/platform", "shared/mixins/modal-base", "shared/components/modal-confirm-deactivate/template", "jquery"], function (_exports, _platform, _modalBase, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal', 'modal-logs'],
    alternateLabel: _platform.alternateLabel,
    originalModel: Ember.computed.alias('modalService.modalOpts.originalModel'),
    action: Ember.computed.alias('modalService.modalOpts.action'),
    didRender: function didRender() {
      setTimeout(function () {
        try {
          (0, _jquery.default)('BUTTON')[0].focus();
        } catch (e) {}
      }, 500);
    },
    actions: {
      confirm: function confirm() {
        this.get('originalModel').send(this.get('action'));
        this.send('cancel');
      }
    },
    isNodeDriver: Ember.computed('originalModel.type', function () {
      return Ember.get(this, 'originalModel.type') === 'nodeDriver';
    }),
    isClusterDriver: Ember.computed('originalModel.type', function () {
      return Ember.get(this, 'originalModel.type') === 'kontainerDriver';
    }),
    isService: Ember.computed('originalModel.type', 'intl.locale', function () {
      var type = this.get('originalModel.type');
      var out = {};
      var intl = this.get('intl');

      switch (type) {
        case 'project':
          out.message = intl.t('modalConfirmDeactivate.buttons.project.message');
          out.button = intl.t('modalConfirmDeactivate.buttons.project.button');
          break;

        case 'environment':
          out.message = intl.t('modalConfirmDeactivate.buttons.environment.message');
          out.button = intl.t('modalConfirmDeactivate.buttons.environment.button');
          break;

        default:
          out.message = intl.t('modalConfirmDeactivate.buttons.default.message');
          out.button = intl.t('modalConfirmDeactivate.buttons.default.button');
          break;
      }

      return out;
    })
  });

  _exports.default = _default;
});