define("shared/components/cluster-ribbon-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wjJGlSHY",
    "block": "{\"symbols\":[\"clusterProvider\"],\"statements\":[[10,\"div\"],[14,0,\"row nav nav-boxes inline-form mt-30 mb-30\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"over-hr\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[\"clusterRibbonNav.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col span-12 col-inline mt-0 center\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],[\"clusters.new\",[30,[36,2],null,[[\"provider\"],[[32,1,[\"name\"]]]]]],[[\"clusterName\",\"genericIcon\",\"removeRecent\"],[[32,1,[\"name\"]],[30,[36,1],[[32,1,[\"genericIcon\"]],true],null],[30,[36,0],[[32,0],\"removeRecent\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,8],null,[[\"class\",\"route\"],[\"mb-20 mt-10 span-12 col col-inline nav-box-item driver\",\"clusters.new.index\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"icon icon-plus-circle icon-4x mt-20\\n          \"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"driver-name\"],[12],[2,\"\\n        All Providers\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"query-params\",\"link-to-cluster-driver\",\"t\",\"recentClusterProviders\",\"-track-array\",\"each\",\"link-to\"]}",
    "meta": {
      "moduleName": "shared/components/cluster-ribbon-nav/template.hbs"
    }
  });

  _exports.default = _default;
});