define("global-admin/security/accounts/groups/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    hasRefreshProviderAccess: false,
    beforeModel: function beforeModel() {
      return this.globalStore.findAll('globalrolebinding');
    },
    model: function model() {
      var _this = this;

      var globalRoleBindings = this.globalStore.all('globalrolebinding').filterBy('groupPrincipalId');
      var promises = [];
      var uniqGroupPrincipalIds = globalRoleBindings.mapBy('groupPrincipalId').uniq();
      uniqGroupPrincipalIds.forEach(function (grb) {
        promises.push(_this.globalStore.find('principal', grb));
      });
      return Ember.RSVP.allSettled(promises).then(function (resp) {
        var groupPrincipals = resp.filterBy('state', 'fulfilled').mapBy('value');
        return {
          globalRoleBindings: globalRoleBindings,
          groupPrincipals: groupPrincipals
        };
      });
    },
    afterModel: function afterModel() {
      var _this2 = this;

      return this.globalStore.rawRequest({
        url: "users?limit=0",
        method: 'GET'
      }).then(function (users) {
        if (Ember.get(users, 'body.actions.refreshauthprovideraccess')) {
          Ember.set(_this2, 'hasRefreshProviderAccess', true);
        }

        return;
      });
    },
    setupController: function setupController(controller, model) {
      if (Ember.get(this, 'hasRefreshProviderAccess')) {
        controller.set('hasRefreshProviderAccess', true);
      }

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});