define("shared/mixins/sticky-table-header", ["exports", "jquery", "shared/mixins/throttled-resize"], function (_exports, _jquery, _throttledResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var tableProps = {
    actionsHeight: 60,
    fixedHeaderHeight: 40
  };

  var _default = Ember.Mixin.create(_throttledResize.default, {
    stickyHeader: true,
    subRow: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.buildTableWidths();

      if (this.get('showHeader')) {
        (0, _jquery.default)(this.element).find('> table > thead > .fixed-header-actions, > table > thead > .fixed-header').css('width', (0, _jquery.default)(this.element).find('> table').outerWidth());
      }

      if (!this.get('stickyHeader')) {
        return;
      }

      this.set('_boundScroll', this._scroll.bind(this));
      (0, _jquery.default)(window).on('scroll', this.get('_boundScroll'));
    },
    _boundScroll: null,
    _scroll: function _scroll() {
      var _this = this;

      Ember.run.throttle(function () {
        _this.updateHeaders();
      }, 30);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!this.get('stickyHeader')) {
        return;
      }

      (0, _jquery.default)(window).off('scroll', this.get('_boundScroll'));
    },
    onResize: function onResize() {
      this._super.apply(this, arguments);

      this.buildTableWidths();
    },
    buildTableWidths: function buildTableWidths() {
      if ((0, _jquery.default)(this.element).is(':visible')) {
        var ths = (0, _jquery.default)(this.element).find('> table > thead > tr.fixed-header > th');
        var $fixHdr = (0, _jquery.default)(this.element).find('> table > thead > .fixed-header-actions, > table > thead > .fixed-header');
        (0, _jquery.default)(this.element).find('> table > thead > tr.fixed-header-placeholder > th').each(function (idx, th) {
          (0, _jquery.default)(ths[idx]).attr('width', (0, _jquery.default)(th).outerWidth());
        });

        if (this.get('showHeader')) {
          $fixHdr.css({
            'width': (0, _jquery.default)(this.element).find('> table').width()
          });

          if ($fixHdr.is(':visible')) {
            (0, _jquery.default)(this.element).find('.search-group').show(1, 'linear');
          }
        }
      }
    },
    tearDownTableWidths: function tearDownTableWidths() {
      if (!this.get('stickyHeader')) {
        return;
      }

      (0, _jquery.default)(this.element).find('> table > thead > tr.fixed-header > th').each(function (idx, td) {
        (0, _jquery.default)(td).removeAttr('width');
      });
    },
    positionHeaders: function positionHeaders() {
      if (!this.get('stickyHeader')) {
        return;
      }

      var elem = (0, _jquery.default)(this.element);
      var $table = elem.find('> table');
      var $actionRow = $table.find('> thead > .fixed-header-actions');
      var $fixedHeader = $table.find('> thead > tr.fixed-header');
      var showHeader = this.get('showHeader');
      var fudge = this.get('subRow') ? 100 : 0;

      if (showHeader) {
        $actionRow.css({
          'position': 'fixed',
          'top': fudge,
          'height': "".concat(tableProps.actionsHeight, "px")
        });
      }

      $fixedHeader.css({
        'position': 'fixed',
        'top': "".concat(showHeader && this.get('bulkActions') ? fudge + tableProps.actionsHeight : 0, "px"),
        'height': "".concat(tableProps.fixedHeaderHeight, "px")
      });
      elem.css({
        'padding-top': "".concat(tableProps.actionsHeight + tableProps.fixedHeaderHeight, "px")
      });
    },
    removePositions: function removePositions() {
      if (!this.get('stickyHeader')) {
        return;
      }

      var elem = (0, _jquery.default)(this.element);
      var $table = elem.find('> table');
      var $actionRow = $table.find('> thead > .fixed-header-actions');
      var $fixedHeader = $table.find('> thead > tr.fixed-header');

      if (this.get('showHeader')) {
        $actionRow.css({
          'position': 'relative',
          'top': ''
        });
      }

      $fixedHeader.css({
        'position': '',
        'top': ''
      });
      elem.css({
        'padding-top': ''
      });
      this.buildTableWidths();
    },
    updateHeaders: function updateHeaders() {
      if (!this.get('stickyHeader')) {
        return;
      }

      var elem = (0, _jquery.default)(this.element);
      var $table = elem.find('> table');
      var $floatingHeader = $table.find('> thead > tr.fixed-header');
      var $scrollTop = (0, _jquery.default)(window).scrollTop();
      var containerBottom = $table.height() + $table.offset().top;
      var offset = elem.find('> table > thead > tr').offset().top - parseInt(elem.css('padding-top'), 10);

      if ($scrollTop < containerBottom && $scrollTop > offset) {
        if ($floatingHeader.css('position') !== 'fixed') {
          this.buildTableWidths();
          this.positionHeaders();
        }
      } else {
        if ($floatingHeader.css('position') === 'fixed') {
          this.tearDownTableWidths();
          this.removePositions();
        }
      }
    }
  });

  _exports.default = _default;
});