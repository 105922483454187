define("shared/components/settings/host-registration/component", ["exports", "ui/utils/constants", "shared/components/settings/host-registration/template", "ui/utils/util"], function (_exports, _constants, _template, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    endpoint: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    customRadio: null,
    customValue: '',
    thisPage: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var initial = this.get('initialValue');
      var endpoint = this.get('endpoint.origin');
      var thisPage = window.location.origin;
      var value, radio;

      if (initial) {
        if (initial === thisPage) {
          value = initial === endpoint ? '' : endpoint;
          radio = 'no';
        } else {
          value = initial;
          radio = 'yes';
        }
      } else {
        if (endpoint === thisPage) {
          value = '';
          radio = 'no';
        } else {
          value = endpoint;
          radio = 'yes';
        }
      }

      this.setProperties({
        thisPage: thisPage,
        customValue: value,
        customRadio: radio
      });
    },
    actions: {
      save: function save(btnCb) {
        var _this = this;

        var value = this.get('activeValue');

        if (!value) {
          this.set('errors', ['Please provide a URL']);
          btnCb();
          return;
        }

        this.set('errors', null);
        this.get('settings').set(_constants.default.SETTING.API_HOST, value);
        this.get('settings').one('settingsPromisesResolved', function () {
          btnCb(true);

          if (_this.saved) {
            _this.saved();
          }
        });
      }
    },
    customValueDidChange: Ember.observer('customValue', function () {
      var val = (this.get('customValue') || '').trim();
      var idx = val.indexOf('/', 8); // 8 is enough for "https://"

      if (idx !== -1) {
        // Trim paths off of the URL
        this.set('customValue', val.substr(0, idx));
        return; // We'll be back...
      }

      if (val) {
        this.set('customRadio', 'yes');
      }
    }),
    looksPrivate: Ember.computed('activeValue', function () {
      return (0, _util.isPrivate)(this.get('activeValue'));
    }),
    badTld: Ember.computed('activeValue', function () {
      return (0, _util.isBadTld)(this.get('activeValue'));
    }),
    activeValue: Ember.computed('customRadio', 'customValue', 'thisPage', function () {
      if (this.get('customRadio') === 'yes') {
        return this.get('customValue').trim();
      } else {
        return this.get('thisPage');
      }
    })
  });

  _exports.default = _default;
});