define("global-admin/security/accounts/edit/controller", ["exports", "shared/mixins/new-or-edit"], function (_exports, _newOrEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_newOrEdit.default, {
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    password: '',
    confirm: '',
    globalRoleSave: null,
    canUserLogIn: null,
    primaryResource: Ember.computed.alias('model.user'),
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('security.accounts.users');
      },
      setGlobalRoleSave: function setGlobalRoleSave(fn) {
        Ember.set(this, 'globalRoleSave', fn);
      },
      setValidateGlobalRoles: function setValidateGlobalRoles(fn) {
        Ember.set(this, 'canUserLogIn', fn);
      }
    },
    isLocalUser: Ember.computed('primaryResource.username', function () {
      return Ember.get(this, 'primaryResource.username') ? true : false;
    }),
    passwordsMatch: Ember.computed('password', 'confirm', function () {
      return Ember.get(this, 'password') === Ember.get(this, 'confirm');
    }),
    passwordsMismatch: Ember.computed('passwordsMatch', function () {
      return !Ember.get(this, 'passwordsMatch');
    }),
    willSave: function willSave() {
      Ember.set(this, 'errors', null);
      var errors = [];
      var ok = this.validate();

      if (this.canUserLogIn && !this.canUserLogIn()) {
        errors.push(this.intl.t('formGlobalRoles.loginError', {
          type: 'user'
        }));
      }

      if (!ok) {
        return false;
      }

      if (Ember.get(this, 'password.length') > 0 && (Ember.get(this, 'password') || '').trim().length === 0) {
        errors.push(Ember.get(this, 'intl').t('accountsPage.new.errors.pwReq'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      } else {
        Ember.set(this, 'errors', null);
      }

      return true;
    },
    didSave: function didSave() {
      var _this = this;

      this._super.apply(this, arguments);

      var promise = Ember.RSVP.resolve();

      if (Ember.get(this, 'password.length') > 0) {
        promise = Ember.get(this, 'primaryResource').doAction('setpassword', {
          newPassword: Ember.get(this, 'password').trim()
        });
      }

      return promise.then(function () {
        return _this.globalRoleSave();
      });
    },
    doneSaving: function doneSaving() {
      this.transitionToRoute('security.accounts.users');
    }
  });

  _exports.default = _default;
});