define("global-admin/mixins/ldap-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NONE = 'none';
  var TLS = 'tls';
  var STARTTLS = 'starttls';

  var _default = Ember.Mixin.create({
    encryption: Ember.computed('authConfig.{tls,starttls}', {
      get: function get() {
        var authConfig = this.authConfig;

        if (Ember.isEmpty(authConfig)) {
          return NONE;
        } else {
          if (authConfig.tls && !authConfig.starttls) {
            return TLS;
          } else if (!authConfig.tls && authConfig.starttls) {
            return STARTTLS;
          } else {
            return NONE;
          }
        }
      },
      set: function set(key, value) {
        switch (value) {
          case TLS:
            Ember.setProperties(this.authConfig, {
              tls: true,
              starttls: false
            });
            break;

          case STARTTLS:
            Ember.setProperties(this.authConfig, {
              tls: false,
              starttls: true
            });
            break;

          default:
            Ember.setProperties(this.authConfig, {
              tls: false,
              starttls: false
            });
            break;
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});