define("shared/components/schema/input-config-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FK72QcWq",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"classNames\",\"content\",\"prompt\",\"localizedPrompt\",\"value\"],[\"form-control\",[35,1],\"schema.inputConfigMap.prompt\",true,[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"filtered\",\"new-select\"]}",
    "meta": {
      "moduleName": "shared/components/schema/input-config-map/template.hbs"
    }
  });

  _exports.default = _default;
});