define("global-admin/mixins/saml-auth", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    saml: Ember.inject.service(),
    errors: null,
    redirectUrl: null,
    saving: false,
    saved: false,
    testing: false,
    disableAuth: true,
    urlError: null,
    urlWarning: false,
    urlInvalid: false,
    protocol: 'https://',
    authConfig: Ember.computed.alias('model.authConfig'),
    providerNamePath: null,
    providerName: null,
    init: function init() {
      this._super.apply(this, arguments);

      var serverUrl = this.settings.get(_constants.default.SETTING.SERVER_URL);

      if (this.authConfig && Ember.isEmpty(this.authConfig.rancherApiHost)) {
        Ember.set(this, 'authConfig.rancherApiHost', serverUrl);
      }
    },
    didChange: Ember.observer('authConfig.rancherApiHost', function () {
      if (!Ember.get(this, 'authConfig.rancherApiHost')) {
        Ember.set(this, 'authConfig.rancherApiHost', Ember.get(this, 'model.serverUrl.value'));
      }
    }),
    actions: {
      authTest: function authTest(cb) {
        var _this = this;

        this.send('clearError');
        var errors = [];
        var model = Ember.get(this, 'authConfig');
        var am = Ember.get(model, 'accessMode') || 'unrestricted';
        Ember.setProperties(model, {
          accessMode: am
        });

        if (model.id === 'shibboleth' && !Ember.isEmpty(model.openLdapConfig)) {
          model = this.removeOpenLdapConfigIfDefault(model);
        }

        if (model.rancherApiHost && model.rancherApiHost === 'https://' || model.rancherApiHost === 'http://') {
          errors.push(this.intl.t('validation.required', {
            key: 'Rancher API Host'
          }));
        }

        errors = [].concat(_toConsumableArray(errors), _toConsumableArray(model.validationErrors()));

        if (errors.get('length')) {
          Ember.setProperties(this, {
            errors: errors,
            testing: false
          });
          Ember.set(model, 'enabled', false);
          cb(false);
        } else {
          Ember.set(this, 'testing', true);
          var allowedPrincipals = [];

          if (Ember.get(this, 'editing')) {
            allowedPrincipals = Ember.get(model, 'allowedPrincipalIds');
          }

          Ember.setProperties(model, {
            'enabled': false,
            // It should already be, but just in case..
            'allowedPrincipalIds': allowedPrincipals
          });
          model.save().then(function () {
            model.doAction('testAndEnable', {
              finalRedirectUrl: "".concat(window.location.origin, "/verify-auth?config=").concat(Ember.get(_this, 'providerName'))
            }).then(function (resp) {
              Ember.get(_this, 'saml').test(resp, function (popupResults) {
                if (popupResults.type === 'error') {
                  _this.set('errors', [popupResults.message]);

                  cb(false);
                } else {
                  _this.send('waitAndRefresh');
                }
              });
            }).catch(function (err) {
              _this.set('errors', [err]);

              cb(false);
            });
          }).catch(function (err) {
            _this.set('errors', [err]);

            cb(false);
          });
        }
      }
    },
    apiHost: Ember.computed('authConfig.rancherApiHost', {
      get: function get() {
        var host = Ember.get(this, 'authConfig.rancherApiHost');

        if (host.length > 0) {
          host = host.slice(8, host.length);
        }

        return host;
      },
      set: function set(key, value) {
        var host = '';

        if (host.indexOf(Ember.get(this, 'protocol'))) {
          host = "".concat(Ember.get(this, 'protocol')).concat(value);
        } else {
          host = value;
        }

        Ember.set(this, 'authConfig.rancherApiHost', host);
        return value;
      }
    }),
    canEditConfig: Ember.computed('isEnabled', 'editing', function () {
      var _this$isEnabled = this.isEnabled,
          isEnabled = _this$isEnabled === void 0 ? false : _this$isEnabled,
          _this$editing = this.editing,
          editing = _this$editing === void 0 ? false : _this$editing;

      if (!isEnabled || editing) {
        return true;
      }

      return false;
    }),
    numUsers: Ember.computed('authConfig.allowedIdentities.@each.externalIdType', 'providerName', 'wasRestricted', function () {
      var type = "PROJECT.TYPE_".concat(Ember.get(this, 'providerName').capitalize(), "_USER");
      type = Ember.get(_constants.default, type);
      return (this.get('authConfig.allowedIdentities') || []).filterBy('externalIdType', type).get('length');
    }),
    numOrgs: Ember.computed('authConfig.allowedIdentities.@each.externalIdType', 'providerName', 'wasRestricted', function () {
      var type = "PROJECT.TYPE_".concat(Ember.get(this, 'providerName').capitalize(), "_GROUP");
      type = Ember.get(_constants.default, type);
      return (this.get('authConfig.allowedIdentities') || []).filterBy('externalIdType', type).get('length');
    }),
    removeOpenLdapConfigIfDefault: function removeOpenLdapConfigIfDefault(authConfig) {
      var openLdapConfig = authConfig.openLdapConfig;
      var defaultLdapFields = JSON.stringify(this.globalStore.createRecord({
        type: 'ldapFields'
      }));
      var stringedAuthConfig = JSON.stringify(openLdapConfig);

      if (defaultLdapFields === stringedAuthConfig) {
        delete authConfig.openLdapConfig;
      }

      return authConfig;
    }
  });

  _exports.default = _default;
});