define("global-admin/components/form-google-auth-read/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZ4NLFgy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inline-form\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label pb-5\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"authPage.googleoauth.notAuthenticated.form.adminEmail.labelText\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[35,1,[\"adminEmail\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inline-form\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label pb-5\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"authPage.googleoauth.notAuthenticated.form.hostname.labelText\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[35,1,[\"hostname\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inline-form\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label pb-5\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"authPage.googleoauth.notAuthenticated.form.nestedGroupMembershipEnabled.labelText\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"nestedGroupMembershipEnabled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"generic.enabled\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"generic.disabled\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"authConfig\",\"if\"]}",
    "meta": {
      "moduleName": "global-admin/components/form-google-auth-read/template.hbs"
    }
  });

  _exports.default = _default;
});