define("shared/user-theme/service", ["exports", "jquery", "shared/utils/constants"], function (_exports, _jquery, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    prefs: Ember.inject.service(),
    session: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    currentTheme: null,
    updateTimer: null,
    app: Ember.inject.service(),
    setupTheme: function setupTheme() {
      var defaultTheme = this.get('session').get(_constants.default.PREFS.THEME);
      var userTheme = this.get("prefs.".concat(_constants.default.PREFS.THEME));

      if (userTheme) {
        this.setTheme(userTheme, false);
      } else {
        // no user pref'd theme
        this.setTheme(defaultTheme, true);
      }
    },
    setTheme: function setTheme(newTheme) {
      var save = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (save) {
        this.set("prefs.".concat(_constants.default.PREFS.THEME), newTheme);
      }

      if (this.get('updateTimer')) {
        Ember.run.cancel(this.get('updateTimer'));
      }

      if (newTheme === 'ui-auto') {
        this.setAutoUpdate();
      } else {
        this.set('currentTheme', newTheme);
        this.writeStyleNode();
      }
    },
    getTheme: function getTheme() {
      return this.get("prefs.".concat(_constants.default.PREFS.THEME));
    },
    setAutoUpdate: function setAutoUpdate() {
      var _this = this;

      var self = this;
      var watchDark = window.matchMedia('(prefers-color-scheme: dark)');
      var watchLight = window.matchMedia('(prefers-color-scheme: light)');
      var watchNone = window.matchMedia('(prefers-color-scheme: no-preference)');

      var nextHalfHour = _constants.default.THEME.AUTO_UPDATE_TIMER - Math.round(new Date().getTime()) % _constants.default.THEME.AUTO_UPDATE_TIMER;

      if (!this.updateTimer) {
        if (watchDark.matches) {
          changed('ui-dark');
        } else if (watchLight.matches) {
          changed('ui-light');
        } else {
          changed(fromClock());
        }

        watchDark.addListener(function (e) {
          if (e.matches) {
            changed('ui-dark');
          }
        });
        watchLight.addListener(function (e) {
          if (e.matches) {
            changed('ui-light');
          }
        });
        watchNone.addListener(function (e) {
          if (e.matches) {
            changed(fromClock());
          }
        });
      }

      this.set('updateTimer', Ember.run.later(function () {
        return _this.setAutoUpdate();
      }, nextHalfHour));

      function fromClock() {
        var hour = new Date().getHours();

        if (hour < _constants.default.THEME.START_HOUR || hour >= _constants.default.THEME.END_HOUR) {
          return 'ui-dark';
        }

        return 'ui-light';
      }

      function changed(newTheme) {
        console.log('Theme change', newTheme);
        self.set('currentTheme', newTheme);
        self.writeStyleNode();
      }
    },
    writeStyleNode: function writeStyleNode() {
      var application = this.get('app');
      var $body = (0, _jquery.default)('BODY');
      var theme = this.get('currentTheme');
      var lang = this.get("session.".concat(_constants.default.SESSION.LANGUAGE));
      var direction = '';
      var assets = application.get('baseAssets');
      var version = application.get('version');

      if (!theme || !lang) {
        return;
      }

      $body.attr('class').split(/\s+/).forEach(function (cls) {
        if (cls.indexOf('theme-') === 0) {
          $body.removeClass(cls);
        }
      });
      $body.addClass("theme-".concat(theme));

      if (this.get('language').isRtl(lang)) {
        direction = '.rtl';
      }

      updateHref('#theme', "".concat(assets, "assets/").concat(theme).concat(direction, ".css?").concat(version));
      updateHref('#vendor', "".concat(assets, "assets/vendor").concat(direction, ".css?").concat(version));

      function updateHref(node, neu) {
        var elem = (0, _jquery.default)(node);
        var cur = elem.attr('href');

        if (cur !== neu) {
          elem.attr('href', neu);
        }
      }
    }
  });

  _exports.default = _default;
});