define("global-admin/security/roles/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HSTwZjSj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"new-edit-role\",[],[[\"@model\",\"@readOnly\",\"@mode\",\"@roleType\"],[[34,0],true,\"view\",[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"type\"]}",
    "meta": {
      "moduleName": "global-admin/security/roles/detail/template.hbs"
    }
  });

  _exports.default = _default;
});