define("ember-model-validator/messages/ar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    presenceMessage: 'الحقل فارغ',
    absenceMessage: 'يجب أن يكون فارغ',
    inclusionMessage: 'غير مدرج في القائمة',
    exclusionMessage: 'محجوز',
    // Numericality
    numericalityMessage: 'ليس رقماً',
    numericalityOnlyIntegerMessage: 'يجب أن يكون رقماً صحيحاً',
    numericalityGreaterThanMessage: 'يجب أن يكون أكثر من {count}',
    numericalityGreaterThanOrEqualToMessage: 'يجب أن يكون أكثر من أو يساوي {count}',
    numericalityEqualToMessage: 'يجب أن يساوي {count}',
    numericalityLessThanMessage: 'يجب أن يكون أقل من {count}',
    numericalityLessThanOrEqualToMessage: 'يجب أن يكون أقل أو يساوي {count}',
    numericalityOddMessage: 'يجب أن يكون رقم من الأحاد',
    numericalityEvenMessage: 'يجب أن يكون رقم زوجي',
    mailMessage: 'يجب أن يكون بريد الالكتروني',
    formatMessage: 'غير صحيح',
    colorMessage: 'يجب أن يكون تكويد لوني صحيح',
    subdomainMessage: 'يجب أن يكون نطاق فرعي صحيح',
    acceptanceMessage: 'يجب أن يقبل',
    zipCodeMessage: 'رمز بريدي غير صحيح',
    URLMessage: 'يجب أن يكون رابط صحيح',
    wrongLengthMessage: 'طول النص غير صحيح (يجب أن يكون {count} حرف)',
    tooShortMessage: 'نص قصير (الحد الأدنى {count} حرف)',
    tooLongMessage: 'نص طويل (الحد الأعلى {count} حرف)',
    mustContainNumberMessage: 'يجب أن يحتوي على رقم',
    mustContainSpecialMessage: 'يجب أن يحتوي على أحد هذي الحروف: {characters}',
    mustContainLowerMessage: 'يجب أن يحتوى على حرف صغير',
    mustContainCapitalMessage: 'يجب أن يحتوى على حرف كبير',
    customValidationMessage: 'غير صحيح',
    matchMessage: 'يجب أن يطابق {match}',
    dateMessage: 'التاريخ غير صحيح',
    dateBeforeMessage: 'يجب أن يكون قبل {date}',
    dateAfterMessage: 'يجب أن يكون بعد {date}'
  };
  _exports.default = _default;
});