define("shared/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vOc+o1PQ",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"inlineBlock\"],[\"tooltip-basic\",[35,1],\"tooltip-static\",\"tooltip-base\",false]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"progress\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"progress-bar \",[34,0]]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"colorClass\",\"tooltipContent\",\"tooltip-element\"]}",
    "meta": {
      "moduleName": "shared/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});