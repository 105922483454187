define("shared/components/form-share-member/component", ["exports", "shared/components/form-share-member/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MEMBERS_HEADERS = [{
    translationKey: 'formShareMember.table.headers.name',
    name: 'name',
    sort: ['userPrincipalId', 'groupPrincipalId']
  }, {
    translationKey: 'formShareMember.table.headers.accessType',
    name: 'accessType',
    sort: ['accessType']
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    membersHeaders: MEMBERS_HEADERS,
    sortBy: '',
    descending: false,
    resource: null,
    gotError: null,
    users: null,
    errors: null,
    editing: false,
    addPublicMember: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, 'setupPublicMember');
    },
    actions: {
      addMember: function addMember() {
        this.addAuthorizedPrincipal({
          type: 'member',
          accessType: 'read-only'
        });
      },
      sharePublic: function sharePublic() {
        this.addAuthorizedPrincipal({
          type: 'member',
          accessType: 'read-only',
          groupPrincipalId: '*'
        });
      },
      checkboxToggled: function checkboxToggled() {
        var _this$resource$member = this.resource.members,
            members = _this$resource$member === void 0 ? [] : _this$resource$member;

        if (this.addPublicMember && members.findBy('groupPrincipalId', '*')) {
          this.removeMember(members.findBy('groupPrincipalId', '*'));
        } else {
          this.send('sharePublic');
        }
      }
    },
    membersRows: Ember.computed('resource.members.[]', function () {
      var _this$resource$member2 = this.resource.members,
          members = _this$resource$member2 === void 0 ? [] : _this$resource$member2;
      return (members || []).filter(function (member) {
        return Ember.get(member, 'groupPrincipalId') !== '*';
      }).sortBy('displayName');
    }),
    setupPublicMember: function setupPublicMember() {
      var _this$resource$member3 = this.resource.members,
          members = _this$resource$member3 === void 0 ? [] : _this$resource$member3;

      if (!this.addPublicMember && (members || []).findBy('groupPrincipalId', '*')) {
        Ember.set(this, 'addPublicMember', true);
      }
    },
    addAuthorizedPrincipal: function addAuthorizedPrincipal() {
      throw new Error('add principal handler must be provided!!');
    },
    removeMember: function removeMember() {
      throw new Error('removeMember is a required action!');
    }
  });

  _exports.default = _default;
});